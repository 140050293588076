import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';


const TermsandCon = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
          <Helmet>
        <title>  Terms & Conditions </title>
        <meta name="description" content=" By accessing and using our website, you agree to comply with and be
bound by the following terms and conditions of use, which, together with our privacy policy,
govern our relationship with you regarding this website. If you disagree with any part of these
terms, please do not use our website.
" />
      </Helmet>
            <section className='pt-4 pb-4'>
                <Container>
                <div>
                    <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Terms and Conditions</span>
                </div>
                <div>
                    <h1>Terms & Conditions</h1>
                    <span style={{fontWeight:600}}>Welcome to Our Website</span>
                    <p>Thank you for visiting ricepuritytest.club. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern our relationship with you regarding this website. If you disagree with any part of these terms, please do not use our website.</p>
                    <h2>1. Legal Capacity</h2>
                    <p>You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.</p>
                    <h2>2. User Responsibilities</h2>
                    <p>Age Restriction: You must be at least 13 years old to use our website. If you are under 13, your use must be supervised by a parent or legal guardian.</p>
                    <p><strong>Lawful Use:</strong> You agree to use our site for lawful purposes only and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.</p>
                    <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>
                    <h2>3. Intellectual Property Rights</h2>
                    <p>The content, layout, design, data, databases, and graphics on this website are protected by intellectual property laws. Unless otherwise indicated, we solely own the content. You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use any part of our website in a way that is not permitted by these terms without our prior written consent.</p>
                    <h2>4. User Contributions</h2>
                    <p>Any material you send or post to this site shall be considered non-proprietary and not confidential. Unless you advise us to the contrary, we will be free to copy, disclose, distribute, incorporate, and otherwise use such material for any purpose.</p>
                    <h2>5. Site Uptime</h2>
                    <p>We take all reasonable steps to ensure that this website is available 24 hours every day, 365 days per year. However, websites do sometimes encounter downtime due to server and other technical issues. We will not be liable if this website is unavailable at any time.</p>
                    <h2>6. Links to and from Other Websites</h2>
                    <p>Any links to third-party websites located on this website are provided for your convenience only. We have not reviewed each third-party website and have no responsibility for such third-party websites or their content.</p>
                    <h2>7. Exclusion of Liability</h2>
                    <p>We do not accept liability for any loss or damage that you suffer as a result of using this website. Your use of this website is without warranty of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
                    <h2>8. Law and Jurisdiction</h2>
                    <p>These terms and conditions are governed by law. Any dispute arising in connection with them shall be subject to the exclusive jurisdiction of the courts.</p>
                    <h2>9. Amendments</h2>
                    <p>We reserve the right to amend these terms and conditions from time to time. Any changes will be posted on this page, and this version of our Terms and Conditions will be superseded. We will take reasonable steps to draw your attention to any changes in our terms. However, we suggest that you read this document each time you use the website to ensure that it still meets with your approval.</p>
                    <h2>Contact Us</h2>
                    <p>For any questions or concerns regarding these terms, please contact us at [<a href='mailto:info@ricepuritytest.club'>info@ricepuritytest.club</a>].</p>
                </div>
                </Container>
            </section>
            <Footer/>
        </>
    )
}

export default TermsandCon
