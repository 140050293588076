import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../MainFile/Home'
import TermsandCon from '../Footer/TermsandCon';
import PrivacyPolicy from '../Footer/PrivacyPolicy';
import CookiesPolicy from '../Footer/CookiesPolicy';
import Disclaimer from '../Footer/Disclaimer';
import FairUsePolicy from '../Footer/FairUsePolicy';
import DataCollectionPolicy from '../Footer/DataCollectionPolicy';
import Italian from '../PurityMain/Italian';
const Routing = () => {
    return (
        <>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/terms-conditions' element={<TermsandCon/>}></Route>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
                <Route path='/cookies-policy' element={<CookiesPolicy/>}></Route>
                <Route path='/disclaimer' element={<Disclaimer/>}></Route>
                <Route path='/fair-use-policy' element={<FairUsePolicy/>}></Route>
                <Route path='/data-collection-policy' element={<DataCollectionPolicy/>}></Route>
                <Route path='/italian' element={<Italian/>}></Route>
            </Routes>
        </BrowserRouter>
        </>
    )
}

export default Routing
