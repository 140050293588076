import React, { useState, useRef, useEffect } from "react";
import "./Purity.css";
import { Container, ProgressBar } from "react-bootstrap";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';


type ContentArray = string[];

//Json

const englishArray: ContentArray = [
  "The Rice Purity Test is an anonymous online survey that has been taken by millions of people around the world. It was originally developed by Rice University in 1924 as a way to measure the moral character of incoming freshman students. The test consists of 100 questions that span a variety of topics, such as sexual activity, drug use, and other social activities.",
  "The purpose of the Rice Purity Test is to provide a way for people to self-assess their moral character. It is also used as a way to compare one's moral character to others. The test is typically taken by college students, and the results are often shared on social media.",
  "When taking the Rice Purity Test, you are asked to answer each of the 100 questions honestly. The questions cover a variety of topics and range from simple inquires about drinking and smoking to more complicated questions about sexual activity. The answers you give will be added up to give you a score out of 100.",
  "The higher the score, the higher the level of morality you possess. A score of 100 indicates a high level of moral purity and a score of 0 indicates a lack of morality. Your score is not only used to compare yourself to others, but also to measure your overall level.",
];
const frenchArray: ContentArray = [
  "Le test de pureté du riz est une enquête en ligne anonyme à laquelle ont participé des millions de personnes à travers le monde. Il a été initialement développé par l'Université Rice en 1924 comme moyen de mesurer le caractère moral des nouveaux étudiants de première année. Le test comprend 100 questions qui couvrent une variété de sujets, tels que l'activité sexuelle, la consommation de drogues et d'autres activités sociales.",
  "Le but du test de pureté du riz est de fournir aux gens un moyen d'auto-évaluer leur caractère moral. Il est également utilisé pour comparer son caractère moral à celui des autres. Le test est généralement passé par des étudiants et les résultats sont souvent partagés sur les réseaux sociaux.",
  "Lorsque vous passez le test de pureté du riz, il vous est demandé de répondre honnêtement à chacune des 100 questions. Les questions couvrent une variété de sujets et vont de simples questions sur la consommation d'alcool et de tabac à des questions plus complexes sur l'activité sexuelle. Les réponses que vous donnerez seront additionnées pour vous donner une note sur 100.",
  "Plus la note est élevée, plus le niveau de moralité que vous possédez est élevé. Un score de 100 indique un niveau élevé de pureté morale et un score de 0 indique un manque de moralité. Votre score ne sert pas seulement à vous comparer aux autres, mais également à mesurer votre niveau global.",
];
const germanArray: ContentArray = [
  "Der Rice Purity Test ist eine anonyme Online-Umfrage, an der Millionen Menschen auf der ganzen Welt teilgenommen haben. Es wurde ursprünglich 1924 von der Rice University entwickelt, um den moralischen Charakter ankommender Studienanfänger zu messen. Der Test besteht aus 100 Fragen zu verschiedenen Themen wie sexuelle Aktivität, Drogenkonsum und andere soziale Aktivitäten.",
  "Der Zweck des Reisreinheitstests besteht darin, den Menschen eine Möglichkeit zu bieten, ihren moralischen Charakter selbst einzuschätzen. Es wird auch verwendet, um den moralischen Charakter eines Menschen mit dem anderer zu vergleichen. Der Test wird in der Regel von College-Studenten abgelegt und die Ergebnisse werden häufig in sozialen Medien geteilt.",
  "Beim Reisreinheitstest werden Sie gebeten, jede der 100 Fragen ehrlich zu beantworten. Die Fragen decken eine Vielzahl von Themen ab und reichen von einfachen Fragen zu Alkohol und Rauchen bis hin zu komplizierteren Fragen zu sexueller Aktivität. Die Antworten, die Sie geben, werden addiert, um eine Punktzahl von 100 zu erhalten.",
  "Je höher die Punktzahl, desto höher ist Ihr moralisches Niveau. Ein Wert von 100 weist auf ein hohes Maß an moralischer Reinheit hin, ein Wert von 0 auf einen Mangel an Moral. Ihre Punktzahl dient nicht nur dazu, sich mit anderen zu vergleichen, sondern auch zur Messung Ihres Gesamtniveaus.",
];

const italianArray: ContentArray = [
  "Il Rice Purity Test è un sondaggio online anonimo a cui hanno partecipato milioni di persone in tutto il mondo. È stato originariamente sviluppato dalla Rice University nel 1924 come un modo per misurare il carattere morale degli studenti matricole in arrivo. Il test è composto da 100 domande che abbracciano una varietà di argomenti, come l'attività sessuale, l'uso di droghe e altre attività sociali.",
  "Lo scopo del test sulla purezza del riso è fornire alle persone un modo per autovalutare il proprio carattere morale. È anche usato come un modo per confrontare il proprio carattere morale con quello degli altri. Il test viene generalmente sostenuto da studenti universitari e i risultati sono spesso condivisi sui social media.",
  "Quando fai il test sulla purezza del riso, ti viene chiesto di rispondere onestamente a ciascuna delle 100 domande. Le domande coprono una varietà di argomenti e vanno da semplici domande sul bere e sul fumare a domande più complicate sull'attività sessuale. Le risposte che fornirai verranno sommate per darti un punteggio su 100.",
  "Maggiore è il punteggio, maggiore è il livello di moralità che possiedi. Un punteggio pari a 100 indica un elevato livello di purezza morale mentre un punteggio pari a 0 indica una mancanza di moralità. Il tuo punteggio non viene utilizzato solo per confrontarti con gli altri, ma anche per misurare il tuo livello generale.",
];

const polishArray: ContentArray = [
  "Test czystości ryżu to anonimowa ankieta internetowa, w której wzięło udział miliony ludzi na całym świecie. Został pierwotnie opracowany przez Uniwersytet Rice w 1924 roku jako metoda pomiaru charakteru moralnego przyjeżdżających studentów pierwszego roku. Test składa się ze 100 pytań dotyczących różnych tematów, takich jak aktywność seksualna, zażywanie narkotyków i inne aktywności społeczne.",
  "Celem testu czystości ryżu jest umożliwienie ludziom samooceny swojego charakteru moralnego. Jest również używany jako sposób na porównanie własnego charakteru moralnego z innymi. Do testu przystępują zazwyczaj studenci, a jego wyniki często udostępnia się w mediach społecznościowych.",
  "Przystępując do testu czystości ryżu, zostaniesz poproszony o szczerą odpowiedź na każde ze 100 pytań. Pytania obejmują różnorodne tematy i wahają się od prostych pytań dotyczących picia i palenia po bardziej skomplikowane pytania dotyczące aktywności seksualnej. Odpowiedzi, których udzielisz, zostaną zsumowane i otrzymasz wynik w skali do 100.",
  "Im wyższy wynik, tym wyższy poziom posiadanej moralności. Wynik 100 oznacza wysoki poziom czystości moralnej, a wynik 0 oznacza brak moralności. Twój wynik służy nie tylko do porównywania Cię z innymi, ale także do pomiaru Twojego ogólnego poziomu.",
];

const koreanArray: ContentArray = [
  "쌀 순도 테스트는 전 세계 수백만 명의 사람들이 실시한 익명의 온라인 설문조사입니다. 원래 1924년 라이스대학교에서 신입생들의 도덕적 성격을 측정하기 위해 개발했습니다. 이 테스트는 성행위, 약물 사용, 기타 사회 활동 등 다양한 주제를 포괄하는 100개의 질문으로 구성됩니다.",
  "쌀 순도 테스트의 목적은 사람들이 자신의 도덕적 품성을 스스로 평가할 수 있는 방법을 제공하는 것입니다. 또한 자신의 도덕적 성격을 다른 사람과 비교하는 방법으로도 사용됩니다. 이 시험은 일반적으로 대학생이 치르며 결과는 종종 소셜 미디어에 공유됩니다.",
  "쌀 순도 테스트에 응시할 때는 100개의 질문에 솔직하게 답해야 합니다. 질문은 음주와 흡연에 관한 간단한 질문부터 성행위에 관한 보다 복잡한 질문까지 다양한 주제를 다루고 있습니다. 귀하가 제공하는 답변은 합산되어 100점 만점으로 평가됩니다.",
  "점수가 높을수록 귀하의 도덕성 수준이 높아집니다. 100점은 도덕적 순수성이 높은 수준을 나타내고, 0점은 도덕성이 결여된 상태를 나타냅니다. 귀하의 점수는 자신을 다른 사람과 비교하는 데 사용될 뿐만 아니라 귀하의 전반적인 수준을 측정하는 데에도 사용됩니다.",
];

const portugueseArray: ContentArray = [
  "O Teste de Pureza do Arroz é uma pesquisa on-line anônima realizada por milhões de pessoas em todo o mundo. Foi originalmente desenvolvido pela Rice University em 1924 como uma forma de medir o caráter moral dos novos alunos. O teste consiste em 100 questões que abrangem diversos tópicos, como atividade sexual, uso de drogas e outras atividades sociais.",
  "O objetivo do Teste de Pureza do Arroz é fornecer uma maneira para as pessoas avaliarem seu caráter moral. Também é usado como uma forma de comparar o caráter moral de alguém com o de outros. O teste normalmente é realizado por estudantes universitários e os resultados costumam ser compartilhados nas redes sociais.",
  "Ao fazer o Teste de Pureza do Arroz, você deverá responder honestamente a cada uma das 100 perguntas. As perguntas cobrem uma variedade de tópicos e vão desde perguntas simples sobre beber e fumar até perguntas mais complicadas sobre atividade sexual. As respostas que você der serão somadas para lhe dar uma pontuação de 100.",
  "Quanto maior a pontuação, maior o nível de moralidade que você possui. Uma pontuação de 100 indica um alto nível de pureza moral e uma pontuação de 0 indica falta de moralidade. Sua pontuação não é usada apenas para comparar você com os outros, mas também para medir seu nível geral.",
];

const turkishArray: ContentArray = [
  "Pirinç Saflık Testi, dünya çapında milyonlarca insanın katıldığı anonim bir çevrimiçi ankettir. İlk olarak 1924 yılında Rice Üniversitesi tarafından birinci sınıf öğrencilerinin ahlaki karakterini ölçmenin bir yolu olarak geliştirildi. Test cinsel aktivite, uyuşturucu kullanımı ve diğer sosyal aktiviteler gibi çeşitli konuları kapsayan 100 sorudan oluşuyor.",
  "Pirinç Saflık Testinin amacı, insanlara ahlaki karakterlerini öz değerlendirmeleri için bir yol sağlamaktır. Aynı zamanda kişinin ahlaki karakterini başkalarıyla karşılaştırmanın bir yolu olarak da kullanılır. Test genellikle üniversite öğrencileri tarafından yapılır ve sonuçlar genellikle sosyal medyada paylaşılır.",
  "Pirinç Saflık Testi'ne girerken sizden 100 sorunun her birine dürüstçe cevap vermeniz isteniyor. Sorular çok çeşitli konuları kapsamaktadır ve içki ve sigara içmeyle ilgili basit sorulardan cinsel aktiviteyle ilgili daha karmaşık sorulara kadar çeşitlilik göstermektedir. Vereceğiniz cevaplar toplanarak size 100 üzerinden bir puan verilecektir.",
  "Puan ne kadar yüksek olursa, sahip olduğunuz ahlak düzeyi de o kadar yüksek olur. 100 puan yüksek düzeyde ahlaki saflığı, 0 puan ise ahlak eksikliğini gösterir. Puanınız yalnızca kendinizi başkalarıyla karşılaştırmak için değil, aynı zamanda genel seviyenizi ölçmek için de kullanılır.",
];

const filipinoArray: ContentArray = [
  "Ang Rice Purity Test ay isang hindi kilalang online na survey na kinuha ng milyun-milyong tao sa buong mundo. Ito ay orihinal na binuo ng Rice University noong 1924 bilang isang paraan upang masukat ang moral na katangian ng mga papasok na estudyante ng freshman. Ang pagsusulit ay binubuo ng 100 tanong na sumasaklaw sa iba't ibang paksa, tulad ng sekswal na aktibidad, paggamit ng droga, at iba pang mga aktibidad sa lipunan.",
  "Ang layunin ng Rice Purity Test ay magbigay ng paraan para masuri ng mga tao ang kanilang moral na katangian. Ginagamit din ito bilang paraan upang maihambing ang moral na katangian ng isang tao sa iba. Ang pagsusulit ay karaniwang kinukuha ng mga mag-aaral sa kolehiyo, at ang mga resulta ay madalas na ibinabahagi sa social media.",
  "Kapag kumukuha ng Rice Purity Test, hihilingin sa iyo na sagutin nang tapat ang bawat isa sa 100 tanong. Ang mga tanong ay sumasaklaw sa iba't ibang paksa at mula sa simpleng pagtatanong tungkol sa pag-inom at paninigarilyo hanggang sa mas kumplikadong mga tanong tungkol sa sekswal na aktibidad. Ang mga sagot na ibibigay mo ay idadagdag para mabigyan ka ng marka mula sa 100.",
  "Kung mas mataas ang marka, mas mataas ang antas ng moralidad na taglay mo. Ang iskor na 100 ay nagpapahiwatig ng isang mataas na antas ng moral na kadalisayan at ang isang marka ng 0 ay nagpapahiwatig ng isang kakulangan ng moralidad. Ang iyong marka ay hindi lamang ginagamit upang ihambing ang iyong sarili sa iba, ngunit pati na rin upang masukat ang iyong pangkalahatang antas.",
];

const chineseArray: ContentArray = [
  "大米纯度测试是一项匿名在线调查，全世界有数百万人参与了该调查。它最初由莱斯大学于 1924 年开发，作为衡量新生道德品质的一种方法。该测试由 100 个问题组成，涵盖各种主题，例如性活动、吸毒和其他社交活动。",
  "大米纯度测试的目的是为人们提供一种自我评估道德品质的方法。它也被用作将一个人的道德品质与他人进行比较的一种方式。该测试通常由大学生参加，结果通常在社交媒体上分享。",
  "参加大米纯度测试时，您需要诚实回答 100 个问题中的每一个问题。这些问题涵盖各种主题，从有关饮酒和吸烟的简单询问到有关性活动的更复杂的问题。你给出的答案将被累加起来，给你一个满分100分的分数。",
  "分数越高，你的道德水平就越高。100分表示道德纯洁度高，0分表示道德缺乏。您的分数不仅用于将自己与他人进行比较，还可以衡量您的整体水平。",
];

//List

const englishListArray: ContentArray = [
  "Held hands romantically?",
  "Been on a date?",
  "Been in a relationship?",
  "Danced without leaving room for Jesus?",
  "Kissed a non-family member?",
  "French kissed?",
  "Kissed on the neck?",
  "Kissed horizontally?",
  "Given or received a hickey?",
  "Kissed or been kissed on the breast?",
  "Kissed someone below the belt?",
  "Kissed for more than two hours consecutively?",
  "Played a game involving stripping?",
  "Seen or been seen by another person in a sensual context?",
  "Masturbated?",
  "Masturbated to a picture or video?",
  "Masturbated while someone else was in the room?",
  "Been caught masturbating?",
  "Masturbated with an inanimate object?",
  "Seen or read pornographic material?",
  "Massaged or been massaged sensually?",
  "Gone through the motions of intercourse while fully dressed?",
  "Undressed or been undressed by a MPS (member of the preferred sex)?",
  "Fondled or had your butt cheeks fondled?",
  "Fondled or had your breasts fondled?",
  "Fondled or had your genitals fondled?",
  "Had or given “blue balls”?",
  "Had an orgasm due to someone else’s manipulation?",
  "Sent a sexually explicit text or instant message?",
  "Engaged in sexually explicit activity over video chat?",
  "Cheated on a significant other during a relationship?",
  "Purchased contraceptives?",
  "Gave oral sex?",
  "Received oral sex?",
  "Ingested someone else’s genital secretion?",
  "Used a sex toy with a partner?",
  "Spent the night with a MPS?",
  "Been walked in on while engaging in a sexual act?",
  "Kicked a roommate out to commit a sexual act?",
  "Ingested alcohol in a non-religious context?",
  "Played a drinking game?",
  "Been drunk?",
  "Faked sobriety to parents or teachers?",
  "Had serious memory loss due to alcohol?",
  "Used tobacco?",
  "Used marijuana?",
  "Used a drug stronger than marijuana?",
  "Used methamphetamine, crack cocaine, PCP, horse tranquilizers or heroin?",
  "Been sent to the office of a principal, dean or judicial affairs representative for a disciplinary infraction?",
  "Been put on disciplinary probation or suspended?",
  "Urinated in public?",
  "Gone skinny-dipping?",
  "Gone streaking?",
  "Seen a stripper?",
  "Had the police called on you?",
  "Had the police handcuff you?",
  "Been arrested?",
  "Been convicted of a crime?",
  "Been convicted of a felony?",
  "Committed an act of vandalism?",
  "Had sexual intercourse?",
  "Had sexual intercourse three or more times in  one night?",
  "Had sexual intercourse in public?",
  "Had sexual intercourse 10 or more times?",
  "Had sexual intercourse in four or more positions?",
  "Had sexual intercourse with a stranger or person you met within 24 hours?",
  "Had sexual intercourse in a motor vehicle?",
  "Had sexual intercourse outdoors?",
  "Had 69?",
  "Had sexual intercourse in a swimming pool or hot tub?",
  "Had sexual intercourse in a bed not belonging to you or your partner?",
  "Had sexual intercourse while you or your partner's parents were in the same home?",
  "Joined the mile high club?",
  "Participated in a “booty call” with a partner whom you were not in a relationship with?",
  "Traveled 100 or more miles for the primary purpose of sexual intercourse?",
  "Had sexual intercourse with a partner with a 3 or more year age difference?",
  "Had sexual intercourse with a virgin?",
  "Had sexual intercourse without a condom?",
  "Had a STI test due to reasonable suspicion?",
  "Had a STI?",
  "Had a threesome?",
  "Attended an orgy?",
  "Had two or more distinct acts of sexual intercourse with two or more people within 24 hours?",
  "Had sexual intercourse with five or more partners?",
  "Been photographed or filmed during sexual intercourse by yourself or others?",
  "Had period sex?",
  "Had anal sex?",
  "Had a pregnancy scare?",
  "Impregnated someone or been impregnated?",
  "Paid or been paid for a sexual act?",
  "Committed an act of voyeurism?",
  "Committed an act of incest?",
  "Engaged in bestiality?",
  "Kissed a non-family member on the lips?",
  "French kissed in public?",
  "Showered with a MPS?",
  "Sent or received sexually explicit photographs?",
  "Run from the police?",
  "Had the police question you?",
  "Had sexual intercourse with non-participating third party in the same room?",
];

const frenchListArray = [
  "Vous vous êtes tenu la main de façon romantique ?",
  "Vous avez eu un rendez-vous ?",
  "Avez-vous été en couple ?",
  "Dansé sans laisser de place à Jésus ?",
  "Vous avez embrassé un non-membre de la famille ?",
  "Un baiser français ?",
  "Embrassé dans le cou ?",
  "Embrassé horizontalement ?",
  "Donné ou reçu un suçon ?",
  "Embrassé ou été embrassé sur la poitrine ?",
  "Vous avez embrassé quelqu'un en dessous de la ceinture ?",
  "Embrassé pendant plus de deux heures consécutives ?",
  "Vous avez joué à un jeu de strip-tease ?",
  "Vu ou été vu par une autre personne dans un contexte sensuel ?",
  "Masturbé ?",
  "Masturbé devant une photo ou une vidéo ?",
  "Vous vous êtes masturbé pendant que quelqu'un d'autre était dans la pièce ?",
  "Vous avez été surpris en train de vous masturber ?",
  "Masturbé avec un objet inanimé ?",
  "Avez-vous vu ou lu du matériel pornographique ?",
  "Massé ou été massé sensuellement ?",
  "Vous avez vécu les mouvements sexuels en étant entièrement habillé ?",
  "Déshabillé ou été déshabillé par un MPS (membre du sexe préféré) ?",
  "Caressé ou vos fesses ont-elles été caressées ?",
  "Caressé ou caressé vos seins ?",
  "Caressé ou caressé vos parties génitales ?",
  "Avez-vous reçu ou donné des 'boules bleues' ?",
  "Vous avez eu un orgasme à cause de la manipulation de quelqu'un d'autre ?",
  "Vous avez envoyé un SMS ou un message instantané à caractère sexuel ?",
  "Vous êtes engagé dans une activité sexuellement explicite par chat vidéo ?",
  "Trompé un proche au cours d'une relation ?",
  "Des contraceptifs achetés ?",
  "Vous avez fait du sexe oral ?",
  "Avez-vous eu du sexe oral ?",
  "Vous avez ingéré les sécrétions génitales de quelqu’un d’autre ?",
  "Vous avez utilisé un jouet sexuel avec un partenaire ?",
  "Vous avez passé la nuit avec un MPS ?",
  "Avez-vous été surpris alors que vous vous livriez à un acte sexuel ?",
  "Expulser un colocataire pour qu'il commette un acte sexuel ?",
  "Ingestion d'alcool dans un contexte non religieux ?",
  "Vous avez joué à un jeu à boire ?",
  "Vous avez été ivre ?",
  "Une sobriété simulée auprès des parents ou des enseignants ?",
  "Vous avez eu de graves pertes de mémoire à cause de l'alcool ?",
  "Du tabac consommé ?",
  "Vous avez consommé de la marijuana ?",
  "Vous avez consommé une drogue plus forte que la marijuana ?",
  "Vous avez consommé de la méthamphétamine, du crack, du PCP, des tranquillisants pour chevaux ou de l'héroïne ?",
  "Avez-vous été envoyé au bureau d'un directeur, d'un doyen ou d'un représentant aux affaires judiciaires pour une infraction disciplinaire ?",
  "Avez-vous été mis en probation disciplinaire ou suspendu ?",
  "Vous avez uriné en public ?",
  "Vous êtes allé vous baigner ?",
  "Vous êtes parti en stries ?",
  "Vous avez vu une strip-teaseuse ?",
  "La police vous a-t-elle appelé ?",
  "La police vous a-t-elle menottée ?",
  "Avez-vous été arrêté ?",
  "Avez-vous été reconnu coupable d'un crime ?",
  "Avez-vous été reconnu coupable d'un crime ?",
  "Vous avez commis un acte de vandalisme ?",
  "Avez-vous eu des rapports sexuels ?",
  "Avez-vous eu des rapports sexuels trois fois ou plus dans la même nuit ?",
  "Avez-vous eu des rapports sexuels en public ?",
  "Avez-vous eu des rapports sexuels 10 fois ou plus ?",
  "Avez-vous eu des rapports sexuels dans quatre positions ou plus ?",
  "Avez-vous eu des rapports sexuels avec un inconnu ou une personne rencontrée dans les 24 heures ?",
  "Avez-vous eu des rapports sexuels dans un véhicule à moteur ?",
  "Avez-vous eu des rapports sexuels à l'extérieur ?",
  "Vous en aviez 69 ?",
  "Avez-vous eu des rapports sexuels dans une piscine ou un bain à remous ?",
  "Avez-vous eu des rapports sexuels dans un lit n'appartenant pas à vous ou à votre partenaire ?",
  "Avez-vous eu des rapports sexuels alors que vous ou les parents de votre partenaire résidiez dans le même foyer ?",
  "Vous avez rejoint le club Mile High ?",
  "Vous avez participé à un « booty call » avec un partenaire avec qui vous n’étiez pas en couple ?",
  "Vous avez parcouru 100 miles ou plus dans le but principal d'avoir des rapports sexuels ?",
  "Avez-vous eu des rapports sexuels avec un partenaire ayant une différence d'âge de 3 ans ou plus ?",
  "Avez-vous eu des rapports sexuels avec une vierge ?",
  "Avez-vous eu des rapports sexuels sans préservatif ?",
  "Vous avez subi un test d'IST en raison de soupçons raisonnables ?",
  "Vous avez eu une IST ?",
  "Vous avez fait un plan à trois ?",
  "Vous avez assisté à une orgie ?",
  "Avez-vous eu deux rapports sexuels distincts ou plus avec deux personnes ou plus dans les 24 heures ?",
  "Avez-vous eu des rapports sexuels avec cinq partenaires ou plus ?",
  "Avez-vous été photographié ou filmé lors d’un rapport sexuel par vous-même ou par d’autres ?",
  "Vous avez eu des relations sexuelles pendant vos règles ?",
  "Vous avez eu des relations sexuelles anales ?",
  "Vous avez eu une peur de grossesse ?",
  "Avez-vous imprégné quelqu'un ou été imprégné ?",
  "Payé ou été payé pour un acte sexuel ?",
  "Vous avez commis un acte de voyeurisme ?",
  "Vous avez commis un acte d'inceste ?",
  "Engagé dans la bestialité ?",
  "Vous avez embrassé un étranger sur les lèvres ?",
  "Des français embrassés en public ?",
  "Douché avec un MPS ?",
  "Envoyé ou reçu des photographies sexuellement explicites ?",
  "Fuir la police ?",
  "La police vous a-t-elle interrogé ?",
  "Avez-vous eu des rapports sexuels avec un tiers non participant dans la même pièce ?",
];

const germanListArray: ContentArray = [
  "Romantisch Händchen gehalten?",
  "Hatten Sie ein Date?",
  "Waren Sie in einer Beziehung?",
  "Getanzt, ohne Raum für Jesus zu lassen?",
  "Ein Nicht-Familienmitglied geküsst?",
  "Französisch geküsst?",
  "Auf den Hals geküsst?",
  "Horizontal geküsst?",
  "Einen Knutschfleck gegeben oder erhalten?",
  "Auf die Brust geküsst oder geküsst worden?",
  "Jemanden unter der Gürtellinie geküsst?",
  "Mehr als zwei Stunden hintereinander geküsst?",
  "Ein Spiel mit Strippen gespielt?",
  "Von einer anderen Person in einem sinnlichen Kontext gesehen oder gesehen?",
  "Masturbiert?",
  "Zu einem Bild oder Video masturbiert?",
  "Masturbiert, während jemand anderes im Raum war?",
  "Wurden Sie beim Masturbieren erwischt?",
  "Mit einem unbelebten Objekt masturbiert?",
  "Pornografisches Material gesehen oder gelesen?",
  "Massiert oder sinnlich massiert worden?",
  "Vollständig bekleidet den Geschlechtsverkehr vollzogen?",
  "Ausgezogen oder von einem MPS (Angehörigen des bevorzugten Geschlechts) ausgezogen worden?",
  "Gestreichelt oder haben Sie Ihre Pobacken streicheln lassen?",
  "Deine Brüste gestreichelt oder gestreichelt haben?",
  "Gestreichelt oder wurden Ihre Genitalien gestreichelt?",
  '"Blaue Bälle" gehabt oder gegeben?',
  "Hatten Sie einen Orgasmus aufgrund der Manipulation einer anderen Person?",
  "Eine sexuell eindeutige Text- oder Sofortnachricht gesendet?",
  "An sexuell expliziten Aktivitäten per Video-Chat beteiligt?",
  "Einen Lebensgefährten während einer Beziehung betrogen?",
  "Gekaufte Verhütungsmittel?",
  "Oralsex gegeben?",
  "Oralsex erhalten?",
  "Das Genitalsekret einer anderen Person aufgenommen haben?",
  "Ein Sexspielzeug mit einem Partner benutzt?",
  "Übernachtet bei einem MPS?",
  "Wurden Sie bei einer sexuellen Handlung überfallen?",
  "Einen Mitbewohner rausgeschmissen haben, um eine sexuelle Handlung zu begehen?",
  "Alkoholkonsum in einem nicht-religiösen Kontext?",
  "Ein Trinkspiel gespielt?",
  "Warst du betrunken?",
  "Vorgetäuschte Nüchternheit gegenüber Eltern oder Lehrern?",
  "Hatten Sie durch Alkohol einen schweren Gedächtnisverlust?",
  "Gebrauchter Tabak?",
  "Marihuana verwendet?",
  "Eine stärkere Droge als Marihuana konsumiert?",
  "Methamphetamin, Crack, PCP, Beruhigungsmittel für Pferde oder Heroin konsumiert?",
  "Wurden Sie wegen eines Disziplinarverstoßes zum Büro eines Schulleiters, Dekans oder Vertreters für Justizangelegenheiten geschickt?",
  "Wurde eine disziplinarische Bewährungsstrafe verhängt oder suspendiert?",
  "In der Öffentlichkeit uriniert?",
  "Nacktbaden gegangen?",
  "Sind die Streifen verschwunden?",
  "Eine Stripperin gesehen?",
  "Hat die Polizei Sie gerufen?",
  "Hat Ihnen die Polizei Handschellen angelegt?",
  "Wurde verhaftet?",
  "Wurden Sie wegen einer Straftat verurteilt?",
  "Wurden Sie wegen einer Straftat verurteilt?",
  "Vandalismus begangen?",
  "Geschlechtsverkehr gehabt?",
  "Dreimal oder öfter in einer Nacht Geschlechtsverkehr gehabt?",
  "Hatten Sie Geschlechtsverkehr in der Öffentlichkeit?",
  "10 oder öfter Geschlechtsverkehr gehabt?",
  "Hatten Sie Geschlechtsverkehr in vier oder mehr Stellungen?",
  "Hatten Sie innerhalb von 24 Stunden Geschlechtsverkehr mit einem Fremden oder einer Person, die Sie kennengelernt haben?",
  "Hatten Sie Geschlechtsverkehr in einem Kraftfahrzeug?",
  "Geschlechtsverkehr im Freien gehabt?",
  "Hatte 69?",
  "Hatten Sie Geschlechtsverkehr im Schwimmbad oder Whirlpool?",
  "Hatten Sie Geschlechtsverkehr in einem Bett, das Ihnen oder Ihrem Partner nicht gehörte?",
  "Hatten Sie Geschlechtsverkehr, während Sie oder die Eltern Ihres Partners im selben Haushalt waren?",
  "Dem Mile High Club beigetreten?",
  "Haben Sie an einem „Booty Call“ mit einem Partner teilgenommen, mit dem Sie nicht in einer Beziehung standen?",
  "Sind Sie 100 oder mehr Meilen hauptsächlich zum Geschlechtsverkehr gereist?",
  "Hatten Sie Geschlechtsverkehr mit einem Partner mit einem Altersunterschied von 3 oder mehr Jahren?",
  "Hatten Sie Geschlechtsverkehr mit einer Jungfrau?",
  "Geschlechtsverkehr ohne Kondom gehabt?",
  "Wurde aufgrund eines begründeten Verdachts ein STI-Test durchgeführt?",
  "Hatten Sie eine STI?",
  "Einen Dreier gehabt?",
  "An einer Orgie teilgenommen?",
  "Hatten Sie innerhalb von 24 Stunden zwei oder mehr unterschiedliche Geschlechtsakte mit zwei oder mehr Personen?",
  "Hatten Sie Geschlechtsverkehr mit fünf oder mehr Partnern?",
  "Wurden Sie selbst oder andere beim Geschlechtsverkehr fotografiert oder gefilmt?",
  "Hatten Sie Periodensex?",
  "Analsex gehabt?",
  "Hatten Sie Angst vor einer Schwangerschaft?",
  "Jemanden geschwängert oder geschwängert worden?",
  "Für eine sexuelle Handlung bezahlt oder bezahlt worden?",
  "Einen Akt des Voyeurismus begangen?",
  "Einen Inzestakt begangen haben?",
  "In Bestialität verwickelt?",
  "Ein Nicht-Familienmitglied auf die Lippen geküsst?",
  "Zungenküsse in der Öffentlichkeit?",
  "Mit einem MPS geduscht?",
  "Sexuell eindeutige Fotos gesendet oder erhalten?",
  "Vor der Polizei fliehen?",
  "Wurden Sie von der Polizei befragt?",
  "Hatten Sie Geschlechtsverkehr mit nicht teilnehmenden Dritten im selben Raum?",
];

const italianListArray: ContentArray = [
  "Tenersi per mano romanticamente?",
  "Hai avuto un appuntamento?",
  "Hai avuto una relazione?",
  "Ballato senza lasciare spazio a Gesù?",
  "Hai baciato un non membro della famiglia?",
  "Bacio alla francese?",
  "Baciato sul collo?",
  "Baciato in orizzontale?",
  "Hai dato o ricevuto un succhiotto?",
  "Baciato o stato baciato sul seno?",
  "Hai baciato qualcuno sotto la cintura?",
  "Mehr als zwei Stunden hintereinander geküsst?",
  "Ein Spiel mit Strippen gespielt?",
  "Visto o stato visto da un'altra persona in un contesto sensuale?",
  "Masturbato?",
  "Masturbarsi davanti ad una foto o ad un video?",
  "Masturbarsi mentre qualcun altro era nella stanza?",
  "Sei stato sorpreso a masturbarti?",
  "Masturbarsi con un oggetto inanimato?",
  "Hai visto o letto materiale pornografico?",
  "Massaggiato o stato massaggiato sensualmente?",
  "Hai eseguito i movimenti del rapporto mentre ero completamente vestito?",
  "Spogliato o fatto spogliare da un MPS (membro del sesso preferito)?",
  "Accarezzato o ti sono state accarezzate le natiche?",
  "Accarezzato o ti è stato accarezzato il seno?",
  "Accarezzato o ti sono stati accarezzati i genitali?",
  "Aveva o ha dato 'palle blu'?",
  "Hai avuto un orgasmo a causa della manipolazione di qualcun altro?",
  "Hai inviato un SMS o un messaggio istantaneo sessualmente esplicito?",
  "Coinvolto in attività sessualmente esplicite tramite chat video?",
  "Tradito un altro significativo durante una relazione?",
  "Contraccettivi acquistati?",
  "Hai fatto sesso orale?",
  "Hai ricevuto sesso orale?",
  "Hai ingerito la secrezione genitale di qualcun altro?",
  "Hai usato un sex toy con un partner?",
  "Übernachtet bei einem MPS?",
  "Wurden Sie bei einer sexuellen Handlung überfallen?",
  "Einen Mitbewohner rausgeschmissen haben, um eine sexuelle Handlung zu begehen?",
  "Hai ingerito alcol in un contesto non religioso?",
  "Hai giocato a bere?",
  "Sei stato ubriaco?",
  "Sobrietà finta per genitori o insegnanti?",
  "Hai avuto una grave perdita di memoria a causa dell'alcol?",
  "Tabacco usato?",
  "Hai usato marijuana?",
  "Eine stärkere Droge als Marihuana konsumiert?",
  "Methamphetamin, Crack, PCP, Beruhigungsmittel für Pferde oder Heroin konsumiert?",
  "Wurden Sie wegen eines Disziplinarverstoßes zum Büro eines Schulleiters, Dekans oder Vertreters für Justizangelegenheiten geschickt?",
  "Wurde eine disziplinarische Bewährungsstrafe verhängt oder suspendiert?",
  "Urinato in pubblico?",
  "Ti sei tuffato nudo?",
  "Sono scomparse le strisce?",
  "Hai visto una spogliarellista?",
  "La polizia ti ha chiamato?",
  "La polizia ti ha ammanettato?",
  "Sei stato arrestato?",
  "Sei stato condannato per un crimine?",
  "Sei stato condannato per un crimine?",
  "Vandalismus begangen?",
  "Ha avuto rapporti sessuali?",
  "Hai avuto rapporti sessuali tre o più volte in una notte?",
  "Hai avuto rapporti sessuali in pubblico?",
  "Hai avuto rapporti sessuali 10 o più volte?",
  "Hai avuto rapporti sessuali in quattro o più posizioni?",
  "Hai avuto rapporti sessuali con uno sconosciuto o una persona che hai incontrato entro 24 ore?",
  "Hai avuto rapporti sessuali in un veicolo a motore?",
  "Hai avuto rapporti sessuali all'aperto?",
  "Ne aveva 69?",
  "Hai avuto rapporti sessuali in piscina o in una vasca idromassaggio?",
  "Hai avuto rapporti sessuali in un letto non tuo o del tuo partner?",
  "Hai avuto rapporti sessuali mentre tu o i genitori del tuo partner eravate nella stessa casa?",
  "Dem Mile High Club beigetreten?",
  "Hai partecipato a una 'boot call' con un partner con cui non avevi una relazione?",
  "Sind Sie 100 oder mehr Meilen hauptsächlich zum Geschlechtsverkehr gereist?",
  "Hai avuto rapporti sessuali con un partner con 3 o più anni di differenza d'età?",
  "Hai avuto rapporti sessuali con una vergine?",
  "Hai avuto rapporti sessuali senza preservativo?",
  "Hai effettuato un test per le malattie sessualmente trasmissibili a causa di un ragionevole sospetto?",
  "Hai avuto una STI?",
  "Einen Dreier gehabt?",
  "An einer Orgie teilgenommen?",
  "Hai avuto due o più atti sessuali distinti con due o più persone nell'arco di 24 ore?",
  "Hai avuto rapporti sessuali con cinque o più partner?",
  "Sei stato fotografato o filmato durante un rapporto sessuale da solo o da altri?",
  "Hai fatto sesso durante il ciclo?",
  "Hai fatto sesso anale?",
  "Hai avuto una paura della gravidanza?",
  "Jemanden geschwängert oder geschwängert worden?",
  "Per una prestazione sessuale pagata o ricevuta?",
  "Hai commesso un atto di voyeurismo?",
  "Ha commesso un atto di incesto?",
  "Impegnato nella bestialità?",
  "Hai baciato sulle labbra un non membro della famiglia?",
  "Bacio alla francese in pubblico?",
  "Hai fatto la doccia con un MPS?",
  "Hai inviato o ricevuto fotografie sessualmente esplicite?",
  "Scappare dalla polizia?",
  "La polizia ti ha interrogato?",
  "Hai avuto rapporti sessuali con una terza persona non partecipante nella stessa stanza?",
];

const polishListArray: ContentArray = [
  "Trzymałeś romantycznie za ręce?",
  "Byłeś na randce?",
  "Byłeś w związku?",
  "Tańczyłeś bez miejsca dla Jezusa?",
  "Całowałeś osobę spoza rodziny?",
  "Całowałeś się z języczkiem?",
  "Zostałeś całowany/a w szyję?",
  "Pocałowałeś się leżąc?",
  "Dostałeś lub zrobiłeś siniaka (tzw. pocałunku)?",
  "Całowałeś lub zostałeś całowany/a w klatkę piersiową?",
  "Całowałeś kogoś poniżej pasa?",
  "Całowałeś się przez ponad dwie godziny z rzędu?",
  "Brałeś udział w grze z rozbieranymi kartami?",
  "Byłeś widziany/a lub widziałeś kogoś w intymnej sytuacji przez inną osobę?",
  "Masturbowałeś się?",
  "Masturbowałeś się do zdjęcia lub filmu?",
  "Masturbowałeś się, gdy ktoś inny był w pokoju?",
  "Zostałeś przyłapany/a na masturbacji?",
  "Masturbowałeś się przy użyciu przedmiotu nieożywionego?",
  "Oglądałeś lub czytałeś materiały pornograficzne?",
  "Masowałeś lub był masowany w sposób erotyczny?",
  "Wykonywałeś czynności seksualne, będąc w pełni ubranym?",
  "Zdejmowałeś lub byłeś rozbierany przez osobę preferowanego płciowo partnera?",
  "Byłeś dotykany/a lub dotykałeś pośladków?",
  "Byłeś dotykany/a lub dotykałeś piersi?",
  "Byłeś dotykany/a lub dotykałeś genitalia?",
  "Doświadczałeś niebieskich jajek lub je spowodowałeś?",
  "Miałeś orgazm z powodu manipulacji kogoś innego?",
  "Wysłałeś seksualnie eksplicitny tekst lub wiadomość?",
  "Brałeś udział w seksualnie eksplicitnej aktywności przez wideoczat?",
  "Zdradziłeś swojego partnera w trakcie związku?",
  "Kupiłeś środek antykoncepcyjny?",
  "Wykonałeś seks oralny?",
  "Otrzymałeś seks oralny?",
  "Wziąłeś do ust wydzielinę płciową kogoś innego?",
  "Używałeś zabawki erotycznej z partnerem?",
  "Spędziłeś noc z osobą preferowanego płciowo?",
  "Zostałeś przyłapany/a na seksualnej aktywności?",
  "Wyrzuciłeś współlokatora, by zrealizować akt seksualny?",
  "Piłeś alkohol w kontekście niezwiązanym z religią?",
  "Brałeś udział w grze alkoholowej?",
  "Byłeś pijany/a?",
  "Podawałeś przed rodzicami lub nauczycielami?",
  "Miałeś poważne utraty pamięci spowodowane alkoholem?",
  "Używałeś tytoniu?",
  "Używałeś marihuany?",
  "Używałeś silniejszego narkotyku niż marihuana?",
  "Używałeś metamfetaminy, kokainy, PCP, końskich środków uspokajających lub heroiny?",
  "Byłeś wysłany/a do biura dyrektora, dziekana lub przedstawiciela sprawiedliwości w związku z wykroczeniem dyscyplinarnym?",
  "Byłeś zawieszony/a na karę dyscyplinarną?",
  "Sięgnąłeś publicznie?",
  "Pływałeś nago?",
  "Biegałeś nago?",
  "Widziałeś striptizerkę?",
  "Policja była wezwana w twojej sprawie?",
  "Byłeś zakuty w kajdanki przez policję?",
  "Byłeś aresztowany/a?",
  "Byłeś skazany/a za przestępstwo?",
  "Byłeś skazany/a za przestępstwo z użyciem przemocy?",
  "Popełniłeś akt w",
  "Popełniłeś akt wandalizmu?",
  "Uprawiałeś seks?",
  "Uprawiałeś seks trzy lub więcej razy w ciągu jednej nocy?",
  "Uprawiałeś seks publicznie?",
  "Uprawiałeś seks dziesięć lub więcej razy?",
  "Uprawiałeś seks w czterech lub więcej pozycjach?",
  "Uprawiałeś seks w ciągu 24 godzin od poznania kogoś?",
  "Uprawiałeś seks w pojeździe?",
  "Uprawiałeś seks na zewnątrz?",
  "Uprawiałeś 69?",
  "Uprawiałeś seks w basenie lub jacuzzi?",
  "Uprawiałeś seks w łóżku, które nie należy do ciebie lub twojego partnera?",
  "Uprawiałeś seks, gdy jeden lub oboje partnerów było pod wpływem substancji?",
  "Uprawiałeś seks z osobą mającą umówione partnerstwo seksualne?",
  "Wchodziłeś w analne otwarcie osoby?",
  "Doświadczyłeś strachu przed ciążą?",
  "Zapłodniłeś kogoś lub zostałeś zapłodniony?",
  "Zapłaciłeś lub dostałeś pieniądze za akt seksualny?",
  "Popełniłeś akt woyeurystyczny?",
  "Popełniłeś akt kazirodczy?",
  "Uprawiałeś seks z zwierzęciem?",
  "Całowałeś kogoś spoza rodziny na ustach?",
  "Pocałowałeś się z języczkiem publicznie?",
  "Brałeś prysznic z partnerem preferowanego płciowo?",
  "Wysłałeś lub otrzymałeś seksualnie eksplicitne zdjęcia?",
  "Uciekałeś przed policją?",
  "Byłeś przesłuchiwany/a przez policję?",
  "Uprawiałeś seks z osobą trzecią, która nie uczestniczyła aktywnie?",
  "Trzymałeś się romantycznie za ręce?",
  "Byłeś na randce?",
  "Byłeś w związku?",
  "Tańczyłeś bez zostawiania miejsca dla Jezusa?",
  "Całowałeś osobę spoza rodziny?",
  "Całowałeś się z języczkiem?",
  "Zostałeś całowany/a w szyję?",
  "Pocałowałeś się leżąc?",
  "Dostałeś lub zrobiłeś siniaka?",
  "Całowałeś lub zostałeś całowany/a w klatkę piersiową?",
  "Całowałeś kogoś poniżej pasa?",
  "Całowałeś się przez ponad dwie godziny z rzędu?",
];

const turkishListArray: ContentArray = [
  "Romantik olarak el ele tuttunuz mu?",
  "Bir randevuda bulundunuz mu?",
  "Bir ilişkide miydiniz?",
  "İsa'nın odasını terk etmeden dans ettiniz mi?",
  "Aile üyesi olmayan birini öptünüz mü?",
  "Fransız öpücüğü mü?",
  "Boynunuza öpücük konduruldunuz mu?",
  "Yatay olarak öpüştünüz mü?",
  "Bir hickey verildi veya alındı mı?",
  "Göğüslerinizi öptünüz veya öpüldünüz mü?",
  "Kemer altı öptünüz mü?",
  "İki saatten fazla aralıksız öpüştünüz mü?",
  "Strip içeren bir oyun oynadınız mı?",
  "Bir başka kişi tarafından görüldünüz mü veya başka bir kişiyi duygusal bir bağlamda gördünüz mü?",
  "Mastürbasyon yaptınız mı?",
  "Bir resim veya videoya mastürbasyon yaptınız mı?",
  "Bir başkası odadayken mastürbasyon yaptınız mı?",
  "Mastürbasyon yaparken yakalandınız mı?",
  "Cansız bir nesneyle mastürbasyon yaptınız mı?",
  "Pornografik materyal gördünüz veya okudunuz mu?",
  "Masaj yapıldı veya cinsel bir masaj aldınız mı?",
  "Tamamen giyinikken cinsel ilişkiye girdiniz mi?",
  "Bir MPS (Tercih Edilen Cinsel Partner) tarafından soyunmuş veya soyunmuş mu?",
  "Poponuz okşandı veya poponuz okşandı mı?",
  "Göğüsleriniz okşandı veya göğüsleriniz okşandı mı?",
  "Cinsel organlarınız okşandı mı veya cinsel organlarınız okşandı mı?",
  "'Blue balls' yaşadınız veya yaşattınız mı?",
  "Bir başkasının manipülasyonuyla orgazm oldunuz mu?",
  "Cinsel içerikli bir metin veya anlık mesaj gönderdiniz mi?",
  "Video sohbet üzerinden cinsel içerikli etkinliklere katıldınız mı?",
  "Bir ilişki sırasında önemli bir başkasını aldattınız mı?",
  "Korunma yöntemi satın aldınız mı?",
  "Oral seks yaptınız mı?",
  "Oral seks aldınız mı?",
  "Bir başkasının genital salgısını yuttunuz mu?",
  "Bir partnerle bir seks oyuncağı kullandınız mı?",
  "Bir MPS ile gece geçirdiniz mi?",
  "Cinsel bir eylem sırasında yürütüldünüz mü?",
  "Bir seks eylemi gerçekleştirmek için bir ev arkadaşınızı dışarı attınız mı?",
  "Dini olmayan bir bağlamda alkol aldınız mı?",
  "Bir içki oyunu oynadınız mı?",
  "Sarhoş oldunuz mu?",
  "Ebeveynlere veya öğretmenlere ayık olduğunuzu mu taklit ettiniz?",
  "Alkol nedeniyle ciddi hafıza kaybı yaşadınız mı?",
  "Tütün kullandınız mı?",
  "Marijuana kullandınız mı?",
  "Marijuana'dan daha güçlü bir ilaç kullandınız mı?",
  "Metamfetamin, crack kokain, PCP, at sakinleştiricileri veya eroin kullandınız mı?",
  "Bir disiplin ihlali nedeniyle bir müdür, dekan veya yargı temsilcisine gönderildiniz mi?",
  "Disiplin cezasına çarptırıldınız mı veya askıya alındınız mı?",
  "Halka açık yerde idrarınızı yaptınız mı?",
  "Çıplak yüzdünüz mü?",
  "Çıplak koştunuz mu?",
  "Bir striptizci gördünüz mü?",
  "Polisi aradınız mı?",
  "Polis sizi kelepçeledi mi?",
  "Tutuklandınız mı?",
  "Bir suçtan mahkum oldunuz mu?",
  "Bir suçtan mahkum oldunuz mu?",
  "Bir vandalizm eylemi gerçekleştirdiniz mi?",
  "Cinsel ilişkiye girdiniz mi?",
  "Bir gecede üç veya daha fazla kez cinsel ilişkiye girdiniz mi?",
  "Halka açık bir yerde cinsel ilişkiye girdiniz mi?",
  "On veya daha fazla kez cinsel ilişkiye girdiniz mi?",
  "Dört veya daha fazla pozisyonda cinsel ilişkiye girdiniz mi?",
  "Bir yabancı veya 24 saat içinde tanıştığınız biriyle cinsel ilişkiye girdiniz mi?",
  "Motorlu bir araçta cinsel ilişkiye girdiniz mi?",
  "Açık havada cinsel ilişkiye girdiniz mi?",
  "69 pozisyonunda cinsel ilişkiye girdiniz mi?",
  "Bir yüzme havuzunda veya jakuzide cinsel ilişkiye girdiniz mi?",
  "Size veya partnerinize ait olmayan bir yatakta cinsel ilişkiye girdiniz mi?",
  "Siz veya partnerinizin ebeveynleri aynı evdeyken cinsel ilişkiye girdiniz mi?",
  "Havayolu mil yükseklik kulübüne katıldınız mı?",
  "Bir ilişkiniz olmayan bir partnerle 'booty call' etkinliğine katıldınız mı?",
  "Ana amacı cinsel ilişki olan 100 mil veya daha fazla yolculuk yaptınız mı?",
  "3 veya daha fazla yaş farkı olan bir partnerle cinsel ilişkiye girdiniz mi?",
  "Bir bakireyle cinsel ilişkiye girdiniz mi?",
  "Kondomsuz cinsel ilişkiye girdiniz mi?",
  "Makul şüpheden dolayı bir cinsel yolla bulaşan hastalık (STI) testi yaptırdınız mı?",
  "Bir cinsel yolla bulaşan hastalığınız (STI) oldu mu?",
  "Bir üçlü ilişkiye girdiniz mi?",
  "Bir orgiyaya katıldınız mı?",
  "Bir gün içinde iki veya daha fazla farklı kişiyle iki veya daha fazla ayrı cinsel ilişki yaşadınız mı?",
  "Beş veya daha fazla partnerle cinsel ilişkiye girdiniz mi?",
  "Cinsel ilişki sırasında kendiniz veya başkaları tarafından fotoğraflandınız veya videoya alındınız mı?",
  "Adet döneminizde cinsel ilişkiye girdiniz mi?",
  "Anal seks yaptınız mı?",
  "Bir gebelik korkusu yaşadınız mı?",
  "Birini hamile bıraktınız mı veya hamile kaldınız mı?",
  "Bir cinsel eylem için ödeme yapıldı veya alındı mı?",
  "Bir gözetleme eylemi gerçekleştirdiniz mi?",
  "Bir akraba ile cinsel ilişkiye girdiniz mi?",
  "Hayvanlarla cinsel ilişkiye girdiniz mi?",
  "Aile üyesi olmayan birini dudaklarından öptünüz mü?",
  "Halka açık bir yerde Fransız öpücüğü mü yaptınız?",
  "Bir MPS ile duş aldınız mı?",
  "Cinsel içerikli fotoğraf veya videolar gönderdiniz mi veya aldınız mı?",
  "Polisten kaçtınız mı?",
  "Polis size soru sordu mu?",
  "Aynı odada cinsel ilişkiye girmeyen bir üçüncü şahısla cinsel ilişkiye girdiniz mi?",
];

const koreanListArray: ContentArray = [
  "로맨틱하게 손을 잡았나요?",
  " 데이트를 해본 적이 있나요?",
  " 연애를 해본 적이 있나요?",
  " 예수를 빼먹지 않고 춤을 췄나요?",
  "가족이 아닌 사람을 키스한 적이 있나요?",
  "프렌치 키스를 했나요?",
  "목에 키스를 했나요?",
  "가로로 키스를 했나요?",
  "흉터를 내거나 받은 적이 있나요?",
  "가슴에 키스를 하거나 받은 적이 있나요?",
  "누군가를 아랫배 아래로 키스했나요",
  "두 시간 이상 연속으로 키스했나요?",
  " 옷을 벗는 것을 포함한 게임을 했나요?",
  "감각적인 상황에서 다른 사람에게 보였거나 보였나요?",
  "자위했나요?",
  "사진이나 비디오로 자위했나요?",
  "다른 사람이 방에 있을 때 자위했나요?",
  "자위하는 것을 발각당했나요?",
  "무생물을 사용하여 자위했나요?",
  "음란물을 보거나 읽었나요?",
  "순정하게 마사지 받았거나 섹슈얼 마사지를 받았나요?",
  "옷을 입은 채로 성행위의 움직임을 따라하셨나요?",
  "마음에 드는 성별의 사람에게 혹은 마음에 드는 성별의 사람에게서 옷을 벗겨 주거나 벗겨 주셨나요?",
  "엉덩이를 만지거나 엉덩이를 만져 줬나요?",
  "가슴을 만지거나 가슴을 만져 주셨나요?",
  "성기를 만지거나 성기를 만져 줬나요?",
  "‘파란 공’을 가졌거나 줬나요?",
  "다른 사람의 조작으로 오르가즘을 경험했나요?",
  "성적으로 의도적인 문자나 즉석 메시지를 보냈거나 받았나요?",
  "비디오 채팅에서 성적으로 의도적인 활동에 참여했나요?",
  "연애 중인 상대방을 속여본 적이 있나요?",
  "피임약을 구입했나요?",
  "구강 섹스를 했나요?",
  "구강 섹스를 받았나요?",
  "다른 사람의 생식기 분비물을 섭취했나요?",
  "파트너와 성인용품을 사용했나요?",
  "MPS와 함께 밤을 보냈나요?",
  "성적 행위 중에 방에 들어온 적이 있나요?",
  "성적 행위를 하기 위해 룸메이트를 쫓아냈나요?",
  "종교적인 맥락이 아닌 상황에서 술을 마셨나요?",
  "술 게임을 했나요?",
  "술에 취한 적이 있나요?",
  "부모님이나 선생님께 술에 취했다고 거짓말한 적이 있나요?",
  "술로 인해 심각한 기억상실이 있었나요?",
  "담배를 피웠나요?",
  "마리화나를 사용했나요?",
  "마리화나보다 강한 약물을 사용했나요?",
  "메스암페타민, 크랙 코카인, PCP, 말 진정제 또는 헤로인을 사용했나요?",
  "학장실, 학장 또는 교정부 대표에게 징계 위반으로 보내졌나요?",
  "징계 집행 중이거나 정지된 적이 있나요?",
  "공공장소에서 소변을 보았나요?",
  "벗은 채로 수영했나요?",
  "벗은 채로 달렸나요?",
  "스트립퍼를 본 적이 있나요?",
  "경찰이 여러분을 부르기도 했나요?",
  "경찰이 손을 채했나요?",
  "체포된 적이 있나요?",
  "범죄로 유죄 판결을 받은 적이 있나요?",
  "중죄로 유죄 판결을 받은 적이 있나요?",
  "방화죄를 저질렀나요?",
  "성관계를 가졌나요?",
  "하룻밤에 성관계를 세 번 이상 가졌나요?",
  "공공장소에서 성관계를 했나요?",
  "10회 이상 성관계를 가졌나요?",
  "4가지 이상의 자세로 성관계를 했나요?",
  "24시간 이내에 만난 낯선 사람이나 파트너와 성관계를 가졌나요?",
  "차 안에서 성관계를 했나요?",
  "야외에서 성관계를 했나요?",
  "69를 했나요?",
  "수영장이나 온수 욕조에서 성관계를 했나요?",
  "파트너가 아닌 다른 침대에서 성관계를 했나요?",
  "당신이나 파트너의 부모가 같은 집에 있을 때 성관계를 했나요?",
  "마일 하이 클럽에 가입했나요?",
  "관계가 없는 파트너와 ‘부디 콜’에 참여했나요?",
  "성관계를 목적으로 100마일 이상을 여행했나요?",
  "파트너와 3년 이상의 연령 차이로 성관계를 했나요?",
  "처녀와 성관계를 했나요?",
  "콘돔을 사용하지 않고 성관계를 했나요?",
  "합리적인 의심으로 성병 검사를 받아 보셨나요?",
  "성병에 걸렸나요?",
  "삼인조를 경험해 보셨나요?",
  "오르기에 참여해 보셨나요?",
  "24시간 이내에 두 명 이상의 사람과 두 번 이상의 성관계를 경험해 보셨나요?",
  "다섯 명 이상의 파트너와 성관계를 했나요?",
  "성관계 도중 자신이나 타인에 의해 사진이나 동영상으로 촬영된 적이 있나요?",
  "생리 중에 섹스를 했나요?",
  "애널 섹스를 했나요?",
  "임신 공포를 경험한 적이 있나요?",
  "누군가를 임신시켰거나 임신당한 적이 있나요?",
  "성 행위로 돈을 주거나 받은 적이 있나요?",
  "명찰주의 행위를 저질러본 적이 있나요?",
  "가부장제 가족 간 성관계를 경험한 적이 있나요?",
  "야수와 성관계를 경험한 적이 있나요?",
  "가족이 아닌 사람과 입맞춤을 했나요?",
  "공공장소에서 뽀뽀를 했나요?",
  "선호하는 성 대상과 함께 샤워를 했나요?",
  "성적으로 음란한 사진을 보내거나 받은 적이 있나요?",
  "경찰로부터 도망쳤나요?",
  "경찰에게 심문을 받은 적이 있나요?",
  "같은 방에서 참여하지 않는 제삼자와 성관계를 가졌나요?",
];

const chinesListArray: ContentArray = [
  "浪漫地牵手？",
  "约会了吗？",
  "谈过恋爱吗？",
  "跳舞而没有给耶稣留出空间？",
  "亲吻了非家庭成员？",
  "法国人接吻了？",
  "亲吻了脖子？",
  "水平亲吻？",
  "给还是收到了打嗝?",
  "亲吻还是被亲吻乳房?",
  "亲吻了腰带下面的人？",
  "连续接吻两个多小时?",
  "玩过一个涉及脱衣的游戏？",
  "在感性的背景下被另一个人看到或被看到？",
  "手淫？",
  "对着图片或视频自慰?",
  "当别人在房间里时手淫？",
  "被抓到手淫了吗？",
  "用无生命的物体自慰?",
  "看过或读过色情材料吗?",
  "按摩还是被性感按摩?",
  "穿着整齐的衣服完成了的动作吗?",
  "被MPS（首选性别成员）脱衣或脱衣？",
  "抚摸还是抚摸你的屁股脸颊？",
  "抚摸还是抚摸过你的乳房？",
  "抚摸还是抚摸过你的生殖器?",
  "有或给了”蓝球“？",
  "因为别人的操纵而达到性高潮吗？",
  "发送了色情短信或即时消息？",
  "通过视频聊天进行露骨的性活动？",
  "在恋爱期间欺骗了另一半？",
  "购买了避孕药具？",
  "了嗎？",
  "接受过？",
  "摄入了别人的生殖器分泌物？",
  "和伴侣一起使用性玩具？",
  "和公安部一起过夜？",
  "在进行性行为时被走进来？",
  "把室友赶出去进行性行为？",
  "在非宗教背景下饮酒？",
  "玩喝酒游戏？",
  "喝醉了吗？",
  "假装对父母或老师保持清醒？",
  "因酒精而严重记忆力减退？",
  "用过的烟草？",
  "用过大麻吗？",
  "使用了比大麻更强的药物？",
  "使用过甲基苯丙胺、快克可卡因、PCP、马镇静剂或海洛因？",
  "因违纪行为被送到校长、院长或司法事务代表的办公室？",
  "被处以留校察看或停职处罚？",
  "在公共场合小便？",
  "瘦身蘸了吗？",
  "裸奔了吗？",
  "看到脱衣舞娘了吗？",
  "警察传唤你了吗？",
  "警察给你戴上手铐了吗？",
  "被逮捕了？",
  "被判有罪了吗？",
  "被判犯有重罪？",
  "实施了故意破坏行为？",
  "有过吗？",
  "一晚有三次或三次以上的？",
  "在公共场合发生过性关系？",
  "有过 10 次或更多次？",
  "以四个或更多姿势进行过？",
  "在24小时内与陌生人或遇到的人发生过性关系？",
  "在机动车内发生过性关系？",
  "在户外发生过性关系吗？",
  "有 69 个吗？",
  "在游泳池或热水浴缸里发生过性关系？",
  "在不属于你或你的伴侣的床上发生过性关系？",
  "在你或你伴侣的父母在同一所房子里发生性关系？",
  "加入英里高俱乐部？",
  "与与你没有关系的伴侣参加了”战利品电话“？",
  "以为主要目的旅行了 100 英里或更长时间？",
  "与年龄相差 3 岁或以上的伴侣发生过性关系? ",
  "与处女发生性关系？",
  "没有避孕套进行？",
  "由于合理怀疑而进行了性传播感染测试？",
  "有性传播感染吗？",
  "有三人行吗？",
  "参加过狂欢吗？",
  "在 24 小时内与两个或两个以上的人有过两次或两次以上不同的行为？",
  "与五个或更多伴侣发生过性关系？",
  "在过程中被自己或他人拍照或拍摄？",
  "有过经期性行为吗？",
  "有过吗？",
  "有怀孕恐慌吗？",
  "某人怀孕或被怀孕？",
  "为性行为付费或被付费？",
  "犯了癖？",
  "犯了行为？",
  "从事兽交？",
  "亲吻了非家庭成员的嘴唇？",
  "法国人在公共场合接吻？",
  "用MPS洗澡了吗？",
  "发送或接收色情照片？",
  "逃避警察？",
  "警察审问过你吗？",
  "在同一个房间里与未参与的第三方发生过性关系",
];

const englishWarning =
  "The test should not be taken too seriously. It is about personal values and experiences and to provide a way for people to connect with each other over shared experiences and perspectives.";
const frenchWarning =
  "Le test ne doit pas être pris trop au sérieux. Il s'agit d'une manière amusante et légère d'entamer des conversations sur des valeurs et des expériences personnelles et de fournir aux gens un moyen de se connecter les uns aux autres autour d'expériences et de perspectives partagées.";
const germanWarning =
  "Der Test sollte nicht zu ernst genommen werden. Es soll eine unterhaltsame und unbeschwerte Möglichkeit sein, Gespräche über persönliche Werte und Erfahrungen zu beginnen und den Menschen die Möglichkeit zu bieten, über gemeinsame Erfahrungen und Perspektiven miteinander in Kontakt zu treten.";
const italinWarning =
  "Il test non dovrebbe essere preso troppo sul serio. Vuole essere un modo divertente e spensierato per avviare conversazioni su valori ed esperienze personali e per fornire alle persone un modo per connettersi tra loro attraverso esperienze e prospettive condivise.";
const polishWarning =
  "Testu nie należy traktować zbyt poważnie. Ma to być zabawny i beztroski sposób na rozpoczęcie rozmów na temat osobistych wartości i doświadczeń oraz zapewnić ludziom możliwość łączenia się ze sobą w oparciu o wspólne doświadczenia i perspektywy.";
const koreanWarning =
  "이 테스트를 너무 심각하게 받아들여서는 안 됩니다. 이는 개인적인 가치와 경험에 대한 대화를 시작하고 공유된 경험과 관점을 통해 사람들이 서로 연결할 수 있는 방법을 제공하는 재미있고 가벼운 방법을 의미합니다.";
const portuguseWarning =
  "O teste não deve ser levado muito a sério. O objetivo é ser uma maneira divertida e alegre de iniciar conversas sobre valores e experiências pessoais e fornecer uma maneira para as pessoas se conectarem umas com as outras através de experiências e perspectivas compartilhadas.";
const turkeshWarning =
  "Test çok ciddiye alınmamalıdır. Kişisel değerler ve deneyimler hakkında sohbet başlatmanın eğlenceli ve neşeli bir yolu olması ve insanların ortak deneyimler ve bakış açıları üzerinden birbirleriyle bağlantı kurması için bir yol sağlaması amaçlanıyor.";
const filipinoWarning =
  "Ang pagsusulit ay hindi dapat masyadong seryosohin. Ito ay sinadya upang maging isang masaya at magaan na paraan upang simulan ang mga pag-uusap tungkol sa mga personal na halaga at karanasan at upang magbigay ng isang paraan para sa mga tao na kumonekta sa isa't isa sa mga nakabahaging karanasan at pananaw.";
const chiniesWarning =
  "不应该太认真地对待测试。它旨在以一种有趣且轻松的方式开始有关个人价值观和经历的对话，并为人们提供一种通过共享经历和观点相互联系的方式。";

const englishHolder = "Enter Your Name";
const frenchHolder = "Entrez votre nom";
const germanHolder = "Gib deinen Namen ein";
const italinHolder = "Inserisci il tuo nome";
const polishHolder = "Wpisz swoje imię";
const koreanHolder = "당신의 이름을 입력";
const portuguseHolder = "Digite seu nome";
const turkishHolder = "Adınızı giriniz";
const filipinoHolder = "Ilagay ang Iyong Pangalan";
const chiniesHolder = "输入你的名字";

const englishCal = "Calculate My Score !";
const frenchCal = "Calculez mon score !";
const germanCal = "Berechnen Sie meine Punktzahl !";
const italianCal = "Calcola il mio punteggio!";
const polishCal = "Oblicz mój wynik !";
const koreanCal = "내 점수를 계산해보세요 !";
const portuguseCal = "Calcule minha pontuação !";
const turkishCal = "Puanımı Hesapla !";
const filipinoCal = "Kalkulahin ang Aking Marka !";
const chiniesCal = "计算我的分数 !";

const englishbtn: ContentArray = ["Yes", "No", "Back"];
const frenchbtn: ContentArray = ["Oui", "Non", "Dos"];
const germanbtn: ContentArray = ["Ja", "NEIN", "Zurück"];
const italianbtn: ContentArray = ["SÌ", "No", "Indietro"];
const polishbtn: ContentArray = ["Tak", "NIE", "Z powrotem"];
const koreanbtn: ContentArray = ["예", "아니요", "뒤쪽에"];
const portugusebtn: ContentArray = ["sim", "Não", "Voltar"];
const turkishbtn: ContentArray = ["Evet", "HAYIR", "Geri"];
const filipinobtn: ContentArray = ["Oo", "Hindi", "Bumalik"];
const chiniesbtn: ContentArray = ["是的", "不", "后退"];

const englishRestart = "Restart Test";
const frenchRestart = "Redémarrer le test";
const germanRestart = "Test neu starten";
const italianRestart = "Riavviare la prova";
const polishRestart = "Uruchom ponownie test";
const koreanRestart = "테스트 다시 시작";
const portuguseRestart = "Reiniciar teste";
const turkishRestart = "Testi Yeniden Başlat";
const filipinoRestart = "I-restart ang Pagsusulit";
const chiniesRestart = "重启测试";

const englishYourscore = "Your Score is";
const frenchYourscore = "Ton score est";
const germanYourscore = "Dein Ergebnis ist";
const italianYourscore = "Il tuo punteggio è";
const polishYourscore = "Twój wynik to";
const koreanYourscore = "당신의 점수는";
const portuguseYourscore = "Sua pontuação é";
const turkishYourscore = "Skorunuz";
const filipinoYourscore = "Ang iyong Score ay";
const chiniesYourscore = "你的分数是";

const englishShare = "Share with your friends on";
const frenchShare = "Partager avec vos amis sur";
const germanShare = "Teilen Sie mit Ihren Freunden auf";
const italianShare = "Condividi con i tuoi amici su";
const polishShare = "Udostępnij swoim znajomym na";
const koreanShare = "친구들에게 공유하기";
const portugueseShare = "Compartilhe com seus amigos em";
const turkishShare = "Arkadaşlarınla paylaş";
const filipinoShare = "Ibahagi sa iyong mga kaibigan sa";
const chineseShare = "与您的朋友分享";

const english100 =
  "A score of 100 on the Rice Purity Test is considered rare, as it means that the person has never engaged in any of the behaviors listed on the questionnaire. While a score of 100 is not necessarily something to aspire to, it can serve as a reminder that it is possible to make choices that prioritize one's values and well-being.";
const french100 =
  "Un score de 100 au test de pureté du riz est considéré comme rare, car cela signifie que la personne n'a jamais adopté aucun des comportements répertoriés dans le questionnaire. Même si un score de 100 n'est pas nécessairement quelque chose auquel aspirer, cela peut rappeler qu'il est possible de faire des choix qui donnent la priorité à ses valeurs et à son bien-être.";
const german100 =
  "Eine Punktzahl von 100 beim Rice Purity Test gilt als selten, da dies bedeutet, dass die Person noch nie eines der im Fragebogen aufgeführten Verhaltensweisen gezeigt hat. Auch wenn ein Wert von 100 nicht unbedingt anzustreben ist, kann er als Erinnerung daran dienen, dass es möglich ist, Entscheidungen zu treffen, bei denen die eigenen Werte und das Wohlbefinden im Vordergrund stehen.";
const italian100 =
  "Un punteggio pari a 100 al Rice Purity Test è considerato raro, poiché significa che la persona non ha mai messo in atto nessuno dei comportamenti elencati nel questionario. Sebbene un punteggio di 100 non sia necessariamente qualcosa a cui aspirare, può servire a ricordare che è possibile fare scelte che diano priorità ai propri valori e al proprio benessere.";
const polish100 =
  "Wynik 100 w teście czystości ryżu uważa się za rzadki, ponieważ oznacza, że ​​dana osoba nigdy nie podjęła żadnego z zachowań wymienionych w kwestionariuszu. Chociaż wynik 100 niekoniecznie jest czymś, do czego należy dążyć, może służyć jako przypomnienie, że możliwe jest dokonywanie wyborów, dla których priorytetem będą wartości i dobre samopoczucie danej osoby.";
const korean100 =
  "쌀 순도 테스트에서 100점은 설문지에 나열된 어떤 행동에도 참여한 적이 없다는 의미이므로 드문 것으로 간주됩니다. 100점은 반드시 열망할 만한 것은 아니지만, 자신의 가치와 행복을 우선시하는 선택을 하는 것이 가능하다는 점을 상기시켜 주는 역할을 할 수 있습니다.";
const portuguse100 =
  "Uma pontuação de 100 no Teste de Pureza do Arroz é considerada rara, pois significa que a pessoa nunca se envolveu em nenhum dos comportamentos listados no questionário. Embora uma pontuação de 100 não seja necessariamente algo a que se aspirar, pode servir como um lembrete de que é possível fazer escolhas que priorizem os próprios valores e bem-estar.";
const turkish100 =
  "Pirinç Saflık Testinden 100 puan nadir olarak kabul edilir, çünkü bu, kişinin ankette listelenen davranışların hiçbirini hiçbir zaman yapmadığı anlamına gelir. 100 puan mutlaka arzu edilecek bir şey olmasa da, kişinin değerlerine ve refahına öncelik veren seçimler yapmasının mümkün olduğunu hatırlatabilir.";
const filipino100 =
  "Ang iskor na 100 sa Rice Purity Test ay itinuturing na bihira, dahil nangangahulugan ito na ang tao ay hindi kailanman nakikibahagi sa alinman sa mga pag-uugali na nakalista sa questionnaire. Bagama't ang iskor na 100 ay hindi kinakailangang isang bagay na hangarin, maaari itong magsilbi bilang isang paalala na posibleng gumawa ng mga pagpipilian na inuuna ang mga halaga at kapakanan ng isang tao.";
const chinies100 =
  "大米纯度测试得分为 100 分被认为是罕见的，因为这意味着该人从未从事过问卷中列出的任何行为。虽然 100 分不一定是值得追求的事情，但它可以提醒我们，可以做出优先考虑个人价值观和福祉的选择。";

const english90 =
  "For most students, scores in the 90-99 range are more common. These scores suggest that the person has had some minor experiences in areas such as relationships, but has generally made choices that reflect a higher level of purity. Students with these scores may still feel a sense of pride in their accomplishments, while recognizing that there is always room for improvement.";
const french90 =
  "Pour la plupart des étudiants, les scores compris entre 90 et 99 sont plus courants. Ces scores suggèrent que la personne a eu quelques expériences mineures dans des domaines tels que les relations, mais qu'elle a généralement fait des choix qui reflètent un niveau de pureté plus élevé. Les élèves obtenant ces scores peuvent néanmoins ressentir un sentiment de fierté à l’égard de leurs réalisations, tout en reconnaissant qu’il y a toujours place à l’amélioration.";
const german90 =
  "Für die meisten Schüler sind Werte im Bereich von 90 bis 99 üblicher. Diese Ergebnisse deuten darauf hin, dass die Person einige geringfügige Erfahrungen in Bereichen wie Beziehungen gemacht hat, aber im Allgemeinen Entscheidungen getroffen hat, die ein höheres Maß an Reinheit widerspiegeln. Schüler mit diesen Ergebnissen sind möglicherweise immer noch stolz auf ihre Leistungen, erkennen aber gleichzeitig, dass es immer Raum für Verbesserungen gibt.";
const italian90 =
  "Per la maggior parte degli studenti, i punteggi compresi tra 90 e 99 sono più comuni. Questi punteggi suggeriscono che la persona ha avuto alcune esperienze minori in aree come le relazioni, ma generalmente ha fatto scelte che riflettono un livello di purezza più elevato. Gli studenti con questi punteggi possono comunque provare un senso di orgoglio per i loro risultati, pur riconoscendo che c'è sempre spazio per migliorare.";
const polish90 =
  "W przypadku większości uczniów częściej spotykane są wyniki w przedziale 90–99. Wyniki te sugerują, że dana osoba miała pewne drobne doświadczenia w obszarach takich jak relacje, ale generalnie dokonywała wyborów, które odzwierciedlają wyższy poziom czystości. Uczniowie z takimi wynikami mogą nadal odczuwać dumę ze swoich osiągnięć, mając jednocześnie świadomość, że zawsze jest miejsce na poprawę.";
const korean90 =
  "대부분의 학생들의 경우 90~99점 범위의 점수가 더 일반적입니다. 이 점수는 그 사람이 관계와 같은 영역에서 약간의 경험을 했지만 일반적으로 더 높은 수준의 순수성을 반영하는 선택을 했음을 나타냅니다. 이 점수를 받은 학생들은 항상 개선의 여지가 있다는 것을 인식하면서 자신의 성취에 대해 여전히 자부심을 느낄 수 있습니다.";
const portugues90 =
  "Para a maioria dos estudantes, pontuações na faixa de 90 a 99 são mais comuns. Estas pontuações sugerem que a pessoa teve algumas experiências menores em áreas como relacionamentos, mas geralmente fez escolhas que refletem um nível mais elevado de pureza. Os alunos com essas pontuações ainda podem sentir orgulho de suas realizações, embora reconheçam que sempre há espaço para melhorias.";
const turkish90 =
  "Çoğu öğrenci için 90-99 aralığındaki puanlar daha yaygındır. Bu puanlar, kişinin ilişkiler gibi alanlarda bazı küçük deneyimler yaşadığını, ancak genellikle daha yüksek düzeyde saflığı yansıtan seçimler yaptığını göstermektedir. Bu puanlara sahip öğrenciler, her zaman iyileştirmeye yer olduğunu kabul ederken, başarılarından hâlâ gurur duyabilirler.";
const filipino90 =
  "Para sa karamihan ng mga mag-aaral, ang mga marka sa hanay na 90-99 ay mas karaniwan. Iminumungkahi ng mga markang ito na ang tao ay nagkaroon ng ilang maliliit na karanasan sa mga lugar tulad ng mga relasyon, ngunit sa pangkalahatan ay nakagawa ng mga pagpipilian na nagpapakita ng mas mataas na antas ng kadalisayan. Ang mga mag-aaral na may ganitong mga marka ay maaari pa ring makaramdam ng pagmamalaki sa kanilang mga nagawa, habang kinikilala na palaging may puwang para sa pagpapabuti.";
const chinies90 =
  "对于大多数学生来说，90-99 范围内的分数更为常见。这些分数表明，该人在人际关系等领域有过一些次要的经历，但通常会做出反映较高纯度的选择。获得这些分数的学生可能仍然会对自己的成就感到自豪，同时认识到总有改进的空间。";

const english80 =
  "Scores in the 80-89 range suggest that the person has had slightly more experience in areas of relationships. While these experiences may have been limited to experimentation or a brief period of time, the person may still feel a sense of regret or shame about their past choices.";
const french80 =
  "Des scores compris entre 80 et 89 suggèrent que la personne a eu un peu plus d'expérience dans les domaines relationnels. Même si ces expériences peuvent se limiter à des expérimentations ou à une brève période de temps, la personne peut néanmoins ressentir un sentiment de regret ou de honte face à ses choix passés.";
const germain80 =
  "Werte im Bereich von 80–89 deuten darauf hin, dass die Person etwas mehr Erfahrung in Beziehungsbereichen hat. Auch wenn diese Erfahrungen möglicherweise auf Experimente oder einen kurzen Zeitraum beschränkt waren, verspürt die Person möglicherweise immer noch ein Gefühl des Bedauerns oder der Scham über ihre früheren Entscheidungen.";
const italian80 =
  "I punteggi compresi tra 80 e 89 suggeriscono che la persona ha avuto un po’ più di esperienza nelle aree relazionali. Sebbene queste esperienze possano essere limitate alla sperimentazione o a un breve periodo di tempo, la persona può comunque provare un senso di rimpianto o vergogna per le proprie scelte passate.";
const polish80 =
  "Wyniki w przedziale 80-89 sugerują, że dana osoba ma nieco większe doświadczenie w obszarach relacji. Chociaż te doświadczenia mogły ograniczać się do eksperymentów lub krótkiego okresu, dana osoba może nadal odczuwać żal lub wstyd z powodu swoich przeszłych wyborów.";
const korean80 =
  "80-89 범위의 점수는 그 사람이 관계 분야에서 약간 더 많은 경험을 가지고 있음을 나타냅니다. 이러한 경험은 실험이나 짧은 기간으로 제한되었을 수 있지만, 그 사람은 여전히 ​​자신의 과거 선택에 대해 후회나 수치심을 느낄 수 있습니다.";
const portuguse80 =
  "Pontuações na faixa de 80 a 89 sugerem que a pessoa teve um pouco mais de experiência em áreas de relacionamento. Embora essas experiências possam ter sido limitadas à experimentação ou a um breve período de tempo, a pessoa ainda pode sentir arrependimento ou vergonha por suas escolhas anteriores.";
const turkish80 =
  "80-89 aralığındaki puanlar, kişinin ilişki alanlarında biraz daha fazla deneyime sahip olduğunu göstermektedir. Bu deneyimler deneme veya kısa bir süre ile sınırlı olsa da kişi yine de geçmişteki seçimlerinden pişmanlık veya utanç duyabilir.";
const filipino80 =
  "Ang mga marka sa hanay na 80-89 ay nagmumungkahi na ang tao ay may bahagyang mas maraming karanasan sa mga lugar ng mga relasyon. Bagama't ang mga karanasang ito ay maaaring limitado sa pag-eeksperimento o sa maikling panahon, ang tao ay maaaring makaramdam pa rin ng panghihinayang o kahihiyan tungkol sa kanilang mga nakaraang pagpili.";
const chinies80 =
  "80-89 范围内的分数表明此人在人际关系方面拥有稍微丰富的经验。虽然这些经历可能仅限于实验或短暂的一段时间，但人们仍然可能对自己过去的选择感到遗憾或羞愧。";

const english70 =
  "Scores in the 70-79 range indicate that the person may have engaged in more risky or dangerous behaviors. Students with these scores may have had some negative consequences as a result of their choices, and may be looking for ways to make healthier choices in the future.";
const french70 =
  "Des scores compris entre 70 et 79 indiquent que la personne peut avoir adopté des comportements plus risqués ou dangereux. Les élèves ayant de tels scores peuvent avoir subi des conséquences négatives en raison de leurs choix et pourraient chercher des moyens de faire des choix plus sains à l’avenir.";
const german70 =
  "Werte im Bereich von 70–79 deuten darauf hin, dass die Person möglicherweise riskantere oder gefährlichere Verhaltensweisen an den Tag gelegt hat. Schüler mit diesen Werten haben aufgrund ihrer Entscheidungen möglicherweise einige negative Konsequenzen gehabt und suchen möglicherweise nach Möglichkeiten, in Zukunft gesündere Entscheidungen zu treffen.";
const italian70 =
  "I punteggi compresi tra 70 e 79 indicano che la persona potrebbe aver adottato comportamenti più rischiosi o pericolosi. Gli studenti con questi punteggi potrebbero aver avuto alcune conseguenze negative come risultato delle loro scelte e potrebbero essere alla ricerca di modi per fare scelte più sane in futuro.";
const polish70 =
  "Wyniki w przedziale 70–79 wskazują, że dana osoba mogła angażować się w bardziej ryzykowne lub niebezpieczne zachowania. Uczniowie z takimi wynikami mogli ponieść pewne negatywne konsekwencje swoich wyborów i mogą szukać sposobów na dokonywanie zdrowszych wyborów w przyszłości.";
const korean70 =
  "70-79 범위의 점수는 그 사람이 더 위험하거나 위험한 행동에 가담했을 수도 있음을 나타냅니다. 이 점수를 받은 학생들은 자신의 선택으로 인해 부정적인 결과를 겪었을 수 있으며 앞으로 더 건강한 선택을 할 수 있는 방법을 찾고 있을 수도 있습니다.";
const portuguse70 =
  "Pontuações na faixa de 70 a 79 indicam que a pessoa pode ter se envolvido em comportamentos mais arriscados ou perigosos. Os alunos com estas pontuações podem ter tido algumas consequências negativas como resultado das suas escolhas e podem estar à procura de formas de fazer escolhas mais saudáveis ​​no futuro.";
const turkish70 =
  "70-79 aralığındaki puanlar kişinin daha riskli veya tehlikeli davranışlarda bulunmuş olabileceğini gösterir. Bu puanlara sahip öğrenciler, yaptıkları seçimler sonucunda bazı olumsuz sonuçlarla karşılaşmış olabilir ve gelecekte daha sağlıklı seçimler yapmanın yollarını arıyor olabilirler.";
const filipino70 =
  "Ang mga marka sa hanay na 70-79 ay nagpapahiwatig na ang tao ay maaaring gumawa ng mas mapanganib o mapanganib na mga pag-uugali. Ang mga mag-aaral na may ganitong mga marka ay maaaring nagkaroon ng ilang negatibong kahihinatnan bilang resulta ng kanilang mga pagpili, at maaaring naghahanap ng mga paraan upang makagawa ng mas malusog na mga pagpipilian sa hinaharap.";
const chinies70 =
  "70-79 范围内的分数表明此人可能从事过风险更大或危险的行为。具有这些分数的学生可能会因其选择而产生一些负面后果，并且可能正在寻找方法在未来做出更健康的选择。";

const english60 =
  "Scores in the 69-51 range suggest that the person has had substantial experience in several areas, and may have engaged in more serious or risky behaviors. These scores indicate that the person may have had a significant impact on their life as a result of their choices, and may be looking for ways to make positive changes.";
const french60 =
  "Des scores compris entre 69 et 51 suggèrent que la personne a une expérience substantielle dans plusieurs domaines et qu'elle peut avoir adopté des comportements plus graves ou plus risqués. Ces scores indiquent que la personne peut avoir eu un impact significatif sur sa vie en raison de ses choix et qu'elle cherche peut-être des moyens d'apporter des changements positifs.";
const german60 =
  "Werte im Bereich von 69 bis 51 deuten darauf hin, dass die Person über umfangreiche Erfahrung in mehreren Bereichen verfügt und möglicherweise schwerwiegendere oder riskantere Verhaltensweisen an den Tag gelegt hat. Diese Ergebnisse deuten darauf hin, dass die Person aufgrund ihrer Entscheidungen möglicherweise einen erheblichen Einfluss auf ihr Leben hatte und möglicherweise nach Möglichkeiten sucht, positive Veränderungen herbeizuführen.";
const italian60 =
  "I punteggi compresi tra 69 e 51 suggeriscono che la persona ha avuto una notevole esperienza in diverse aree e potrebbe aver adottato comportamenti più gravi o rischiosi. Questi punteggi indicano che la persona potrebbe aver avuto un impatto significativo sulla propria vita come risultato delle proprie scelte e potrebbe essere alla ricerca di modi per apportare cambiamenti positivi.";
const polish60 =
  "Wyniki w przedziale 69–51 sugerują, że dana osoba miała duże doświadczenie w kilku obszarach i mogła angażować się w poważniejsze lub bardziej ryzykowne zachowania. Wyniki te wskazują, że dana osoba mogła mieć znaczący wpływ na swoje życie w wyniku swoich wyborów i może szukać sposobów na wprowadzenie pozytywnych zmian.";
const korean60 =
  "69~51점 범위의 점수는 해당 개인이 여러 분야에서 상당한 경험을 갖고 있으며 더 심각하거나 위험한 행동에 가담했을 수 있음을 나타냅니다. 이 점수는 그 사람이 자신의 선택의 결과로 자신의 삶에 중대한 영향을 미쳤을 수 있으며 긍정적인 변화를 만들 수 있는 방법을 찾고 있을 수 있음을 나타냅니다.";
const portugues60 =
  "Pontuações na faixa de 69 a 51 sugerem que a pessoa teve experiência substancial em diversas áreas e pode ter se envolvido em comportamentos mais sérios ou de risco. Estas pontuações indicam que a pessoa pode ter tido um impacto significativo na sua vida como resultado das suas escolhas e pode estar à procura de formas de fazer mudanças positivas.";
const turkish60 =
  "69-51 aralığındaki puanlar, kişinin çeşitli alanlarda önemli deneyime sahip olduğunu ve daha ciddi veya riskli davranışlarda bulunabileceğini göstermektedir. Bu puanlar, kişinin seçimleri sonucunda hayatında önemli bir etki yaratmış olabileceğini ve olumlu değişiklikler yapmanın yollarını arıyor olabileceğini gösteriyor.";
const filipino60 =
  "Ang mga marka sa hanay na 69-51 ay nagmumungkahi na ang tao ay nagkaroon ng malaking karanasan sa ilang mga lugar, at maaaring nasangkot sa mas seryoso o peligrosong pag-uugali. Ang mga markang ito ay nagpapahiwatig na ang tao ay maaaring nagkaroon ng malaking epekto sa kanilang buhay bilang resulta ng kanilang mga pagpili, at maaaring naghahanap ng mga paraan upang gumawa ng mga positibong pagbabago.";
const chinies60 =
  "69-51 范围内的分数表明此人在多个领域拥有丰富的经验，并且可能从事过更严重或危险的行为。这些分数表明，该人的选择可能对他们的生活产生了重大影响，并且可能正在寻找做出积极改变的方法。";

const english50 =
  "Scores in the 50 range suggest that the person has had extensive experience in many areas, and may have engaged in behaviors that are considered more extreme or dangerous. Students with these scores may have had a significant impact on their life as a result of their choices, and may be looking for ways to make significant changes.";
const french50 =
  "Des scores de l’ordre de 50 suggèrent que la personne a une vaste expérience dans de nombreux domaines et qu’elle peut avoir adopté des comportements considérés comme plus extrêmes ou plus dangereux. Les élèves ayant ces scores peuvent avoir eu un impact significatif sur leur vie en raison de leurs choix et chercher des moyens d’apporter des changements significatifs.";
const german50 =
  "Werte im Bereich von 50 deuten darauf hin, dass die Person in vielen Bereichen über umfassende Erfahrung verfügt und möglicherweise Verhaltensweisen an den Tag gelegt hat, die als extremer oder gefährlicher gelten. Schüler mit diesen Werten haben möglicherweise aufgrund ihrer Entscheidungen einen erheblichen Einfluss auf ihr Leben gehabt und suchen möglicherweise nach Möglichkeiten, wesentliche Änderungen herbeizuführen.";
const italian50 =
  "I punteggi compresi tra 50 e 50 suggeriscono che la persona ha avuto una vasta esperienza in molte aree e potrebbe aver adottato comportamenti considerati più estremi o pericolosi. Gli studenti con questi punteggi potrebbero aver avuto un impatto significativo sulla loro vita come risultato delle loro scelte e potrebbero essere alla ricerca di modi per apportare cambiamenti significativi.";
const polish50 =
  "Wyniki w przedziale 50 sugerują, że dana osoba ma duże doświadczenie w wielu obszarach i mogła angażować się w zachowania uważane za bardziej ekstremalne lub niebezpieczne. Uczniowie z takimi wynikami mogli mieć znaczący wpływ na swoje życie w wyniku swoich wyborów i mogą szukać sposobów na wprowadzenie znaczących zmian.";
const korean50 =
  "50점 범위의 점수는 그 사람이 여러 분야에서 광범위한 경험을 갖고 있으며 더 극단적이거나 위험한 것으로 간주되는 행동에 참여했을 수 있음을 나타냅니다. 이 점수를 받은 학생들은 자신의 선택이 자신의 삶에 큰 영향을 미쳤을 수 있으며, 중요한 변화를 만들 수 있는 방법을 찾고 있을 수도 있습니다.";
const portugues50 =
  "Pontuações na faixa de 50 sugerem que a pessoa tem vasta experiência em muitas áreas e pode ter se envolvido em comportamentos considerados mais extremos ou perigosos. Os alunos com estas pontuações podem ter tido um impacto significativo nas suas vidas como resultado das suas escolhas e podem estar à procura de formas de fazer mudanças significativas.";
const turkish50 =
  "50 aralığındaki puanlar, kişinin birçok alanda kapsamlı deneyime sahip olduğunu ve daha aşırı veya tehlikeli kabul edilen davranışlarda bulunabileceğini göstermektedir. Bu puanlara sahip öğrenciler, yaptıkları seçimler sonucunda hayatlarında önemli bir etki yaratmış olabilir ve önemli değişiklikler yapmanın yollarını arıyor olabilirler.";
const filipino50 =
  "Iminumungkahi ng mga marka sa hanay na 50 na ang tao ay nagkaroon ng malawak na karanasan sa maraming lugar, at maaaring nasangkot sa mga pag-uugali na itinuturing na mas matindi o mapanganib. Ang mga mag-aaral na may ganitong mga marka ay maaaring nagkaroon ng malaking epekto sa kanilang buhay bilang resulta ng kanilang mga pagpili, at maaaring naghahanap ng mga paraan upang makagawa ng mga makabuluhang pagbabago.";
const chinies50 =
  "50 分范围内的分数表明此人在许多领域拥有丰富的经验，并且可能从事过被认为更极端或危险的行为。具有这些分数的学生可能会因其选择而对他们的生活产生重大影响，并且可能正在寻找做出重大改变的方法。";

const english40 =
  "Scores in the 0-49 range indicate that the person has had a high level of experience in almost all areas, and may have engaged in behaviors that are considered very risky or dangerous. Students with these scores may have experienced serious negative consequences as a result of their choices, and may be looking for ways to make significant changes in their life.";
const french40 =
  "Des scores compris entre 0 et 49 indiquent que la personne a un niveau élevé d'expérience dans presque tous les domaines et qu'elle peut avoir adopté des comportements considérés comme très risqués ou dangereux. Les élèves ayant ces scores peuvent avoir subi de graves conséquences négatives en raison de leurs choix et chercher des moyens d’apporter des changements significatifs dans leur vie.";
const german40 =
  "Werte im Bereich von 0 bis 49 weisen darauf hin, dass die Person in fast allen Bereichen über ein hohes Maß an Erfahrung verfügt und möglicherweise Verhaltensweisen an den Tag gelegt hat, die als sehr riskant oder gefährlich gelten. Schüler mit diesen Werten haben aufgrund ihrer Entscheidungen möglicherweise schwerwiegende negative Konsequenzen erlitten und suchen möglicherweise nach Möglichkeiten, ihr Leben erheblich zu verändern.";
const italian40 =
  "I punteggi compresi tra 0 e 49 indicano che la persona ha avuto un elevato livello di esperienza in quasi tutte le aree e potrebbe aver adottato comportamenti considerati molto rischiosi o pericolosi. Gli studenti con questi punteggi potrebbero aver subito gravi conseguenze negative a seguito delle loro scelte e potrebbero essere alla ricerca di modi per apportare cambiamenti significativi nella loro vita.";
const polish40 =
  "Wyniki w zakresie 0-49 wskazują, że dana osoba ma wysoki poziom doświadczenia w prawie wszystkich obszarach i mogła angażować się w zachowania uważane za bardzo ryzykowne lub niebezpieczne. Uczniowie z takimi wynikami mogli doświadczyć poważnych negatywnych konsekwencji swoich wyborów i mogą szukać sposobów na dokonanie znaczących zmian w swoim życiu.";
const korean40 =
  "0-49 범위의 점수는 그 사람이 거의 모든 영역에서 높은 수준의 경험을 갖고 있으며 매우 위험하거나 위험한 것으로 간주되는 행동에 참여했을 수 있음을 나타냅니다. 이 점수를 받은 학생들은 자신의 선택으로 인해 심각하고 부정적인 결과를 경험했을 수 있으며 자신의 삶에 중대한 변화를 가져올 수 있는 방법을 찾고 있을 수 있습니다.";
const portugues40 =
  "Pontuações na faixa de 0 a 49 indicam que a pessoa teve um alto nível de experiência em quase todas as áreas e pode ter se envolvido em comportamentos considerados muito arriscados ou perigosos. Os alunos com estas pontuações podem ter sofrido graves consequências negativas como resultado das suas escolhas e podem estar à procura de formas de fazer mudanças significativas nas suas vidas.";
const turkish40 =
  "0-49 aralığındaki puanlar, kişinin hemen hemen tüm alanlarda yüksek düzeyde deneyime sahip olduğunu, çok riskli veya tehlikeli kabul edilen davranışlarda bulunabileceğini gösterir. Bu puanlara sahip öğrenciler, yaptıkları seçimler sonucunda ciddi olumsuz sonuçlarla karşılaşıyor olabilir ve hayatlarında önemli değişiklikler yapmanın yollarını arıyor olabilirler.";
const filipino40 =
  "Ang mga marka sa hanay na 0-49 ay nagpapahiwatig na ang tao ay may mataas na antas ng karanasan sa halos lahat ng mga lugar, at maaaring nasangkot sa mga pag-uugali na itinuturing na lubhang mapanganib o mapanganib. Ang mga mag-aaral na may ganitong mga marka ay maaaring nakaranas ng malubhang negatibong kahihinatnan bilang resulta ng kanilang mga pagpili, at maaaring naghahanap ng mga paraan upang gumawa ng mga makabuluhang pagbabago sa kanilang buhay.";
const chinies40 =
  "0-49 范围内的分数表明此人几乎在所有领域都具有高水平的经验，并且可能从事过被认为非常危险或危险的行为。具有这些分数的学生可能因其选择而经历了严重的负面后果，并且可能正在寻找对他们的生活做出重大改变的方法。";

const EnglishLevels: ContentArray = [
  "You're maybe the most innocent person on this planet.",
  "You are so pure. It seems that you're quite new to the world.",
  "You are pure. Just like most people who take this test.",
  "You have probably checked off some moderately crazy things.",
  "You are a bad girl/bad boy. You enjoy all the adult stuff.",
  "You are not pure and below the average. You are addicted to the adult stuff.",
  "You are evil and far behind the average. You may already have committed a crime.",
  "You are so evil that nobody around you could score lower than you.",
  "Nobody on this planet could be more corrupted than you are.",
];

const FrenchLevels: ContentArray = [
  "Tu es peut-être la personne la plus innocente sur cette planète.",
  "Tu es si pur(e). On dirait que tu es tout nouveau/nouvelle dans ce monde.",
  "Tu es pur(e). Tout comme la plupart des gens qui passent ce test.",
  "Tu as probablement coché quelques choses modérément folles.",
  "Tu es un mauvais garçon/une mauvaise fille. Tu aimes toutes les choses pour adultes.",
  "Tu n'es pas pur(e) et en dessous de la moyenne. Tu es accro aux choses pour adultes.",
  "Tu es mauvais(e) et loin de la moyenne. Tu as peut-être déjà commis un crime.",
  "Tu es si mauvais(e) que personne autour de toi ne pourrait obtenir un score plus bas que le tien.",
  "Personne sur cette planète ne pourrait être plus corrompu(e) que toi.",
];

const GermanLevels: ContentArray = [
  "Vielleicht bist du die unschuldigste Person auf diesem Planeten.",
  "Du bist so rein. Es scheint, als wärst du ganz neu auf der Welt.",
  "Du bist rein. Genau wie die meisten Leute, die diesen Test machen.",
  "Du hast wahrscheinlich einige moderat verrückte Dinge angekreuzt.",
  "Du bist ein böses Mädchen / ein böser Junge. Du genießt all die erwachsenen Sachen.",
  "Du bist nicht rein und unter dem Durchschnitt. Du bist süchtig nach den erwachsenen Sachen.",
  "Du bist böse und weit unter dem Durchschnitt. Du hast vielleicht schon ein Verbrechen begangen.",
  "Du bist so böse, dass niemand um dich herum einen niedrigeren Score bekommen könnte als du.",
  "Niemand auf diesem Planeten könnte korrupter sein als du.",
];

const ItalianLevels: ContentArray = [
  "Forse sei la persona più innocente su questo pianeta.",
  "Sei così puro. Sembra che tu sia appena arrivato nel mondo.",
  "Sei puro. Proprio come la maggior parte delle persone che fanno questo test.",
  "Probabilmente hai spuntato alcune cose moderatamente folli.",
  "Sei un ragazzo cattivo / una ragazza cattiva. Ti piacciono tutte le cose per adulti.",
  "Non sei puro e sei al di sotto della media. Sei dipendente dalle cose per adulti.",
  "Sei cattivo e molto al di sotto della media. Potresti aver già commesso un crimine.",
  "Sei così cattivo che nessuno intorno a te potrebbe ottenere un punteggio più basso del tuo.",
  "Nessuno su questo pianeta potrebbe essere più corrotto di te.",
];

const PolishLevels: ContentArray = [
  "Być może jesteś najbardziej niewinną osobą na tej planecie.",
  "Jesteś tak czysty. Wydaje się, że jesteś zupełnie nowy na świecie.",
  "Jesteś czysty. Dokładnie tak jak większość osób, które robią ten test.",
  "Prawdopodobnie zaznaczyłeś kilka umiarkowanie szalonych rzeczy.",
  "Jesteś złym chłopcem / złą dziewczyną. Lubisz wszystkie dorosłe rzeczy.",
  "Nie jesteś czysty i poniżej przeciętnej. Jesteś uzależniony od dorosłych rzeczy.",
  "Jesteś zły i daleko poniżej przeciętnej. Być może już popełniłeś przestępstwo.",
  "Jesteś tak zły, że nikt wokół ciebie nie mógłby uzyskać niższego wyniku niż ty.",
  "Nikt na tej planecie nie mógłby być bardziej skorumpowany niż ty.",
];

const KoreanLevels: ContentArray = [
  "아마도 당신은 이 행성에서 가장 순수한 사람일지도 모릅니다.",
  "당신은 정말 순수합니다. 마치 당신이 이 세상에 처음 왔다는 듯합니다.",
  "당신은 순수합니다. 이 테스트를 하는 대부분의 사람들처럼요.",
  "아마도 당신은 약간 미친 것들을 몇 개 선택했을 것입니다.",
  "당신은 나쁜 소년 / 나쁜 소녀입니다. 성인용 물건을 모두 즐깁니다.",
  "당신은 순수하지 않고 평균 이하입니다. 당신은 성인용 물건에 중독되었습니다.",
  "당신은 악마이며 평균 이하입니다. 아마도 범죄를 저질렀을지도 모릅니다.",
  "당신은 주변 사람들보다 더 낮은 점수를 받을 수 없을 정도로 악마입니다.",
  "이 행성에 당신보다 더 타락한 사람은 없을 것입니다.",
];

const TurkishLevels: ContentArray = [
  "Belki de bu gezegendeki en masum kişi sensindir.",
  "Sen çok safsin. Dünyaya oldukça yeni gelmiş gibi görünüyorsun.",
  "Sen safsin. Bu testi yapan çoğu insan gibi.",
  "Belki de birkaç şeyi hafifçe çılgınca işaretledin.",
  "Sen kötü bir çocuksun / kötü bir kızsın. Tüm yetişkin şeylerden hoşlanıyorsun.",
  "Sen saf değilsin ve ortalamanın altındasın. Yetişkin şeylere bağımlısın.",
  "Sen kötüsün ve ortalamanın çok altındasın. Belki de zaten bir suç işledin.",
  "Sen o kadar kötüsün ki etrafındaki hiç kimse senden daha düşük bir puan alamaz.",
  "Bu gezegendeki kimse senin kadar yozlaşmış olamaz.",
];

const ChineseLevels: ContentArray = [
  "也许你是这个星球上最无辜的人。",
  "你是如此纯洁。似乎你是刚刚来到这个世界的。",
  "你是纯洁的。就像大多数参加这个测试的人一样。",
  "你可能已经勾选了一些轻微疯狂的事情。",
  "你是一个坏男孩/坏女孩。你喜欢所有成人的东西。",
  "你不纯洁且低于平均水平。你对成人的东西上瘾。",
  "你是邪恶的并且远低于平均水平。你可能已经犯了罪。",
  "你是如此邪恶，以至于你周围的人无法得到比你更低的分数。",
  "在这个星球上没有人比你更腐败了。",
];

const scorePoint: ContentArray = [
  "100",
  "99-95",
  "94-90",
  "89-70",
  "69-50",
  "49-20",
  "19-10",
  "9-1",
  "0",
];

const englishHead: ContentArray = ["Rice Purity Score", "Meaning"];
const frenchHead: ContentArray = ["Score de pureté du riz", "Signification"];
const germanHead: ContentArray = ["Reisreinheitspunktzahl", "Bedeutung"];
const italianHead: ContentArray = [
  "Punteggio di purezza del riso",
  "Significato",
];
const polishHead: ContentArray = ["Ocena czystości ryżu", "Znaczenie"];
const koreanHead: ContentArray = ["쌀 순도 점수", "의미"];
const turkishHead: ContentArray = ["Pirinç Saflık Puanı", "Anlam"];
const chineseHead: ContentArray = ["大米纯度评分", "含义"];

const bannerContenteng: ContentArray = [
  "The Rice Purity Test is a 100-question checklist that covers various experiences related to breaking the rules, sexual activities, and other mature themes. It was created by students at Rice University to measure a person's life experience. The lower your score (out of 100), the more experiences you've had. Curious to know what is your purity score. Let’s check: ",
  "So, What's the Rice Purity Test?",
  "Imagine a checklist of 100 questions that touch on all sorts of experiences—think rule-breaking antics, romantic escapades, and other grown-up adventures. Created by students at Rice University (hence the name), this test is like a personal inventory of 'Have I ever...?' moments.",
  "A Little Stroll Down Memory Lane",
  "The Rice Purity Test has been around for quite some time. It started as a fun way for Rice University students to measure how much life experience they had outside of academics. Picture a group of students in the '20s (the 1920s, to be precise) chuckling over their answers, and you've got the right idea. Over time, it became a quirky, unofficial tradition among students there and beyond.",
  "What's the Point?",
  "You might wonder, 'Why take this test?' Well, it's more than just ticking off boxes. For many, it's a light-hearted tool for self-reflection and bonding. It's like holding up a mirror to your experiences and seeing how they stack up in the grand tapestry of life. Plus, it's a conversation starter. Sharing results can break the ice and foster deeper connections among friends, all in good fun.",
  "Before You Click Start",
  "Your Emotional GPS",
  "Before diving in, take a moment to check in with yourself. The test covers many topics, some of which might trigger strong emotions or memories. Taking breaks, skipping questions, or deciding if this isn't for you is perfectly okay. Your well-being takes top priority.",
  "Cloak of Anonymity",
  "Remember, the test is designed to be taken anonymously. You can share your results if you want to, and even then, sharing is best kept within circles you trust. Online, ensure you use a secure and private connection to keep your answers between you and the screen.",
  "The Test Itself",
  "Ready to begin? Many websites enable users to perform this test, but we make the process more straightforward and interesting. Through our website, you can not only take the test but also share your results on social media with your friends and invite them to take their own test. Based on the answers you provide, you will receive a detailed report about your purity score.",
  "Decoding Your Score",
  "Once you've completed the test and have your score, take a moment to reflect. Remember, this number isn't an exhaustive measurement of your character or experiences. It's a fun, introspective look at where you've been and what you've done. Everyone's journey is unique; this is just one way to glimpse a part of yours.",
  "The Right Time for a Retake",
  "Should you revisit the test? There's no set timeline for a retake. Seeing how your score changes as you grow and have new experiences might be interesting. Some might take it annually as a quirky tradition, while others might wait longer. It's all about when you feel it might offer new insights or a fresh perspective on your journey.",
  "Taking the Rice Purity Test can be a light-hearted exploration of your life experiences. Approach it with curiosity, care for your emotional health, and respect your privacy. Whether your score surprises you or confirms what you already know, let it be a starting point for self-discovery, not a definitive judgment. And remember, life is infinitely more complex and beautiful than any checklist could capture.",
  "Taking the Test with Care",
  "Diving into the Rice Purity Test can be a hoot, but it's important to approach it with some mindfulness. Here's how to keep it responsible and respectful:",
  "Remember, it's just for fun",
  "This isn't about judgment or making anyone feel uncomfortable. It's a chance to reflect and laugh a little at the wild ride that is life.",
  "Context is key",
  "Some questions raise sensitive topics. It's okay to skip anything that feels too personal or triggers a not-so-great memory.",
  "Privacy is paramount",
  "Your score is your business. Share it, or don't—that's totally up to you. And if you do share, ensure it's in a setting where everyone feels okay about it.",
  "The Backstory: How It All Began",
  "Our story begins at Rice University, nestled in the heart of Houston, Texas. Picture it: the year is the early 1920s, a time of flappers and jazz. A group of students, perhaps looking for a break from the rigours of academic life or simply seeking to forge deeper connections, crafts a list of 100 questions. This wasn't just any list; it was a mix of innocent queries and more daring questions about the student's personal lives and experiences. It's said that the original intent was partly to help incoming freshmen gauge their level of 'innocence' against the more seasoned upperclassmen.",
  "Evolution Through the Ages",
  "As you can imagine, a lot has changed since those early days. The Rice Purity Test has morphed, been updated, and taken on new forms as it spread beyond the hallowed halls of Rice University. It's journeyed from paper copies passed hand to hand to its modern incarnation on the internet, accessible to curious minds around the globe. While its essence—a checklist of experiences—remains intact, the questions have evolved to reflect changing societal norms and attitudes.",
  "Decoding Your Scores",
  "So, you've taken the plunge and completed the test. Now, you're staring at a number between 0 and 100, wondering, 'What does this even mean?' Here's a quick guide:",
  "100-90:",
  "You're on the more cautious side of experiences. Maybe you've led a sheltered life or are just selective about your adventures",
  "89-70:",
  "You've dipped your toes into a few ponds, so to speak. There's a mix of innocence and experience here.",
  "69-50:",
  "The middle ground. You've had your share of adventures and probably have some interesting stories.",
  "49-30:",
  "You're no stranger to life's wilder side. Chances are, you've got a penchant for exploration and pushing boundaries.",
  "29-0:",
  "You've lived—a lot. Your score suggests many experiences and probably some tales that could raise eyebrows.",
  "Rice Purity Test & It’s Usage",
  "Ah, the Rice Purity Test—a curious little adventure through a checklist that's more than just a series of questions. It's a gateway to laughter, bonding, and, sometimes, a walk down memory lane. Let's explore how this intriguing test can weave magic into your life, shall we?",
  "Forging Connections",
  "Imagine unraveling stories and secrets among friends or even new people you meet through the Rice Purity Test. It's like cracking open a book of each other's life adventures. Some tales might draw you closer, sparking a sense of kinship, while others could surprise you, revealing layers you never knew existed. It's about finding those threads of common experiences or discovering the diversity in your journeys that knit the fabric of your relationships tighter.",
  "The Joy of Laughter",
  "At its heart, the Rice Purity Test is a vessel of sheer entertainment. Each question ticked invites laughter and light-hearted banter, turning an ordinary evening into a treasure trove of giggles. It's about those moments when you say, 'You did what?!' followed by uproarious laughter. It's a playful excursion into the myriad hues of life, painted with the brush of humour.",
  "A Nostalgic Voyage",
  "Ever find yourself longing for a taste of yesteryear? The Rice Purity Test is your time machine, each question a portal to memories long nestled in the corners of your mind. It's about re-living those firsts, the thrills, the escapades, and sometimes, the moments of innocent mischief. It's not just a test; it's a journey back to the days that have shaped you.",
  "A Compass for Guidance",
  "For those in guidance roles—parents, educators, counsellors—the Rice Purity Test offers a unique lens into the worldviews and experiences of those you guide. It's not about judgment but understanding. It's a starting point for conversations that matter, helping to navigate through the complexities of growth, choices, and challenges.",
  "A Repository of Insights",
  "In society's grand tapestry, the Rice Purity Test serves as a fascinating collection of data, offering glimpses into a generation's collective experiences. For researchers and analysts, it's a goldmine of trends and patterns, a snapshot of cultural zeitgeists that can inform studies, policies, and understandings of human behaviour over time.",
  "The Mirror of Self-Reflection",
  "Lastly, the Rice Purity Test is a moment of introspection. It's a chance to pause, reflect, and discover aspects of your journey you hadn't considered before. It's about understanding where you've been, where you stand, and pondering the paths yet to be explored. It's not just about tallying up scores but taking stock of life's mosaic, appreciating each piece that adds to the whole.",
  "So, the Rice Purity Test is more than just a checklist, whether for a laugh, a moment of connection, or a deep dive into the self. It's a celebration of experiences, a catalyst for conversation, and a tool for reflection. Dive in, and who knows what revelations and delights await?",
  "Rice Purity Test Score Statistical Report",
  "There are many statistics that have been covered by various Rice Purity Test or independent organizations. We have also surveyed the Rice Purity Test and here is the statistical report that we have found:",
  "Score Range",
  "Number of Respondents",
  "Percentage of Total",
  "A Word of Caution",
  "Remember that the Rice Purity Test is not a diagnostic tool. It's not meant to pass judgment, assign moral value, or categorize people into neat little boxes. Your score doesn't define you; it's simply a snapshot of the experiences you've had (or haven't had) up to this point in your life. The test doesn't account for context, emotions, or the complexity of individual journeys.",
  "The Rice Purity Test is a relic from a bygone era that has adapted and survived in the digital age. It offers a playful way to reflect on personal experiences. Whether you're looking back with a smile, a cringe, or a sense of curiosity, remember that life is about the journey, not just the milestones along the way.",
  "FAQs",
  "1. What is the Rice Purity Test?",
  "Answer:",
  "The Rice Purity Test is a self-graded survey that assesses a person's level of innocence in worldly matters. It primarily focuses on romantic experiences, rule-breaking behaviors, and various life experiences. The test consists of 100 questions, and you answer 'yes' or 'no' based on your actions.",
  "2. Why is it called the Rice Purity Test?",
  "The test originated at Rice University in Houston, Texas, as a way for students to measure their maturation during college life. It has since become popular online as a way for people to assess and compare their experiences.",
  "3. Who should take the Rice Purity Test?",
  "The test is generally taken by teenagers and young adults to gauge their experiences against peers. However, it's important to approach the test with a mindset of curiosity rather than judgment and understand it's just for fun and not a measure of one's character or worth.",
  "4. Is the Rice Purity Test accurate?",
  "The test is not a scientific measure and should not be taken as a definitive assessment of one's innocence or experience. It's more of a conversational tool or a way to reflect on your own life experiences.",
  "5. How is my score calculated?",
  "Your score is calculated based on the number of 'yes' and 'no' answers you provide. The score ranges from 0 to 100, with a higher score indicating fewer experiences related to the questions asked. It's a straightforward tally with no weighting on any question.",
  "6. Can I take the test more than once?",
  "Yes, you can take the test as many times as you like. Some people retake it over time to see how their score changes as they experience more of life.",
  "7. Is my Rice Purity Test score private?",
  " Yes, your score is private unless you choose to share it. The test doesn't ask for any personally identifiable information, and sharing your score is entirely up to you.",
  "8. What should I do with my score?",
  "The score is just a number for entertainment. It's an opportunity for self-reflection or discussion with friends but should not be taken too seriously. It's not a judgment of character or a benchmark for experiences.",
  "9. Are there different versions of the test?",
  "Yes, there are several versions of the test tailored to different audiences, with some focusing more on college experiences and others on general life experiences. Make sure to take the version that you feel is most appropriate for you.",
  "10. Where can I take the Rice Purity Test?",
  "The test is available on various websites and platforms. A quick internet search for the 'Rice Purity Test' should lead you to several versions of the test that you can take online.",
];

const bannerContentfr: ContentArray = [
  "Le test de pureté du riz est une liste de 100 questions qui couvre diverses expériences liées à la transgression des règles, aux activités sexuelles et à d'autres thèmes matures. Il a été créé par des étudiants de l'Université Rice pour mesurer l'expérience de vie d'une personne. Plus votre score (sur 100) est bas, plus vous avez eu d'expériences. Curieux de connaître votre score de pureté. Vérifions :",
  "Alors, en quoi consiste le test de pureté du riz ?",
  "Imaginez une liste de contrôle de 100 questions qui touchent à toutes sortes d'expériences - pensez aux facéties de transgression des règles, aux escapades romantiques et à d'autres aventures pour adultes. Créé par des étudiants de l'Université Rice (d'où le nom), ce test est comme un inventaire personnel de moments de 'Ai-je déjà... ?'",
  "Une petite promenade dans le passé",
  "Le test de pureté du riz existe depuis un certain temps. Il a commencé comme un moyen amusant pour les étudiants de l'Université Rice de mesurer combien d'expérience de vie ils avaient en dehors des études. Imaginez un groupe d'étudiants dans les années 20 (les années 1920, pour être précis) riant de leurs réponses, et vous avez la bonne idée. Au fil du temps, il est devenu une tradition non officielle et excentrique parmi les étudiants là-bas et au-delà.",
  "Quel est le but ?",
  "Vous pourriez vous demander, 'Pourquoi passer ce test ?' Eh bien, ce n'est pas seulement cocher des cases. Pour beaucoup, c'est un outil léger pour l'autoréflexion et le lien. C'est comme tenir un miroir devant vos expériences et voir comment elles s'accumulent dans la grande tapisserie de la vie. De plus, c'est un brise-glace. Partager les résultats peut briser la glace et favoriser des liens plus profonds entre amis, tout en s'amusant.",
  "Avant de cliquer sur Démarrer",
  "Votre GPS émotionnel",
  "Avant de plonger, prenez un moment pour vous vérifier. Le test couvre de nombreux sujets, certains pouvant déclencher des émotions ou des souvenirs forts. Prendre des pauses, sauter des questions ou décider que ce n'est pas pour vous est parfaitement acceptable. Votre bien-être est la priorité absolue.",
  "Manteau d'anonymat",
  "Rappelez-vous, le test est conçu pour être pris de manière anonyme. Vous pouvez partager vos résultats si vous le souhaitez, et même dans ce cas, le partage est préférable dans des cercles de confiance. En ligne, assurez-vous d'utiliser une connexion sécurisée et privée pour garder vos réponses entre vous et l'écran.",
  "Le test lui-même",
  "Prêt à commencer ? De nombreux sites Web permettent aux utilisateurs de passer ce test, mais nous rendons le processus plus simple et plus intéressant. Sur notre site Web, vous pouvez non seulement passer le test, mais aussi partager vos résultats sur les réseaux sociaux avec vos amis et les inviter à passer leur propre test. En fonction des réponses que vous fournissez, vous recevrez un rapport détaillé sur votre score de pureté.",
  "Décodage de votre score",
  "Une fois que vous avez terminé le test et que vous avez votre score, prenez un moment pour réfléchir. Rappelez-vous, ce nombre n'est pas une mesure exhaustive de votre caractère ou de vos expériences. C'est un regard introspectif amusant sur où vous avez été et ce que vous avez fait. Le voyage de chacun est unique ; c'est juste une façon de voir une partie du vôtre.",
  "Le bon moment pour une nouvelle passe",
  "Devriez-vous revisiter le test ? Il n'y a pas de calendrier défini pour une nouvelle prise. Voir comment votre score évolue au fur et à mesure que vous grandissez et avez de nouvelles expériences peut être intéressant. Certains peuvent le prendre annuellement comme une tradition excentrique, tandis que d'autres pourraient attendre plus longtemps. Tout dépend de quand vous pensez qu'il pourrait offrir de nouveaux éclairages ou une nouvelle perspective sur votre voyage.",
  "Passer le test de pureté du riz peut être une exploration légère de vos expériences de vie. Abordez-le avec curiosité, prenez soin de votre santé émotionnelle et respectez votre vie privée. Que votre score vous surprenne ou confirme ce que vous savez déjà, laissez-le être un point de départ pour la découverte de soi, pas un jugement définitif. Et n'oubliez pas, la vie est infiniment plus complexe et belle que toute liste de contrôle pourrait le capturer.",
  "Passer le test avec précaution",
  "Plonger dans le test de pureté du riz peut être amusant, mais il est important de l'aborder avec un peu de discernement. Voici comment le garder responsable et respectueux :",
  "Rappelez-vous, c'est juste pour s'amuser.",
  "Il ne s'agit pas de jugement ou de rendre quelqu'un mal à l'aise. C'est une occasion de réfléchir et de rire un peu du tour sauvage qu'est la vie.",
  "Le contexte est essentiel.",
  "Certaines questions soulèvent des sujets sensibles. Il est acceptable de sauter tout ce qui semble trop personnel ou déclenche un souvenir pas si génial.",
  "La confidentialité est primordiale.",
  "Votre score est votre affaire. Partagez-le, ou non - c'est totalement à vous. Et si vous partagez, assurez-vous que c'est dans un cadre où tout le monde se sent à l'aise.",
  "Les origines : comment tout a commencé",
  "Notre histoire commence à l'Université Rice, nichée au cœur de Houston, au Texas. Imaginez : nous sommes au début des années 1920, une époque de flappers et de jazz. Un groupe d'étudiants, peut-être à la recherche d'une pause par rapport à la rigueur de la vie académique ou simplement désireux de créer des liens plus profonds, élabore une liste de 100 questions. Ce n'était pas juste n'importe quelle liste ; c'était un mélange de questions innocentes et de questions plus audacieuses sur la vie personnelle et les expériences des étudiants. On dit que l'intention initiale était en partie d'aider les nouveaux étudiants à évaluer leur niveau 'd'innocence' par rapport aux étudiants de classe supérieure plus expérimentés.",
  "Évolution à travers les âges",
  "Comme vous pouvez l'imaginer, beaucoup de choses ont changé depuis ces premiers jours. Le test de pureté du riz a évolué, a été mis à jour et a pris de nouvelles formes alors qu'il se répandait au-delà des salles sacrées de l'Université Rice. Il est passé de copies papier passées de main en main à son incarnation moderne sur Internet, accessible aux esprits curieux du monde entier. Alors que son essence - une liste de contrôle des expériences - reste intacte, les questions ont évolué pour refléter les normes sociales et les attitudes changeantes.",
  "Décodage de vos scores",
  "Alors, vous avez plongé et terminé le test. Maintenant, vous regardez un nombre entre 0 et 100, vous demandant : 'Que cela signifie-t-il même ?' Voici un guide rapide :",
  "100-90 :",
  "Vous êtes du côté le plus prudent des expériences. Peut-être avez-vous mené une vie protégée ou êtes-vous simplement sélectif quant à vos aventures.",
  "89-70 :",
  "Vous avez trempé vos pieds dans quelques étangs, pour ainsi dire. Il y a un mélange d'innocence et d'expérience ici.",
  "69-50 :",
  "Le juste milieu. Vous avez eu votre part d'aventures et avez probablement quelques histoires intéressantes.",
  "49-30 :",
  "Vous n'êtes pas étranger au côté plus sauvage de la vie. Il est probable que vous ayez un penchant pour l'exploration et repousser les limites.",
  "29-0 :",
  "Vous avez vécu - beaucoup. Votre score suggère de nombreuses expériences et probablement quelques récits qui pourraient faire lever des sourcils.",
  "Test de pureté du riz et son utilisation",
  "Ah, le test de pureté du riz - une petite aventure curieuse à travers une liste de contrôle qui est plus que juste une série de questions. C'est une porte vers le rire, le lien et, parfois, une promenade dans le passé. Explorons comment ce test intrigant peut tisser de la magie dans votre vie, voulez-vous ?",
  "Forger des connexions",
  "Imaginez dénouer des histoires et des secrets entre amis ou même de nouvelles personnes que vous rencontrez grâce au test de pureté du riz. C'est comme ouvrir un livre des aventures de vie les uns des autres. Certaines histoires pourraient vous rapprocher, suscitant un sentiment de parenté, tandis que d'autres pourraient vous surprendre, révélant des couches que vous ne soupçonniez pas. Il s'agit de trouver ces fils d'expériences communes ou de découvrir la diversité de vos voyages qui tissent plus serré le tissu de vos relations.",
  "La joie du rire",
  "Au fond, le test de pureté du riz est un vecteur de divertissement pur. Chaque question cochée invite au rire et à la banterie bon enfant, transformant une soirée ordinaire en un trésor de rires. C'est à propos de ces moments où vous dites, 'Vous avez fait quoi ? !' suivi d'éclats de rire. C'est une excursion ludique dans les nuances variées de la vie, peinte avec le pinceau de l'humour.",
  "Un voyage nostalgique",
  "Vous retrouvez-vous parfois à désirer un avant-goût d'autrefois ? Le test de pureté du riz est votre machine à remonter le temps, chaque question un portail vers des souvenirs depuis longtemps nichés dans les coins de votre esprit. Il s'agit de revivre ces premières fois, les frissons, les escapades et parfois les moments de malice innocente. Ce n'est pas juste un test ; c'est un voyage dans les jours qui vous ont façonné.",
  "Une boussole pour l'orientation",
  "Pour ceux qui occupent des rôles de guidance - parents, éducateurs, conseillers - le test de pureté du riz offre une perspective unique sur les visions du monde et les expériences de ceux que vous guidez. Il ne s'agit pas de jugement mais de compréhension. C'est un point de départ pour des conversations importantes, aidant à naviguer à travers les complexités de la croissance, des choix et des défis.",
  "Un entrepôt d'informations",
  "Dans la grande tapisserie de la société, le test de pureté du riz sert de collection fascinante de données, offrant des aperçus des expériences collectives d'une génération. Pour les chercheurs et les analystes, c'est une mine d'or de tendances et de modèles, un instantané des zéitgeists culturels qui peuvent informer les études, les politiques et la compréhension du comportement humain au fil du temps.",
  "Le miroir de l'autoréflexion",
  "Enfin, le test de pureté du riz est un moment d'introspection. C'est une chance de faire une pause, de réfléchir et de découvrir des aspects de votre parcours que vous n'aviez pas envisagés auparavant. Il s'agit de comprendre où vous avez été, où vous en êtes et de méditer sur les chemins encore à explorer. Il ne s'agit pas seulement de totaliser les scores mais de faire le point sur la mosaïque de la vie, en appréciant chaque pièce qui contribue à l'ensemble.",
  "Alors, le test de pureté du riz est plus qu'une simple liste de contrôle, que ce soit pour rire, un moment de connexion ou une plongée profonde en soi. C'est une célébration des expériences, un catalyseur de conversation et un outil de réflexion. Plongez, et qui sait quelles révélations et délices vous attendent ?",
  "Rapport statistique sur le score du test de pureté du riz",
  "De nombreuses statistiques ont été couvertes par divers tests de pureté du riz ou organisations indépendantes. Nous avons également enquêté sur le test de pureté du riz et voici le rapport statistique que nous avons trouvé :",
  "Plage de score",
  "Nombre de répondants",
  "Pourcentage du total",
  "Un mot de prudence",
  "Rappelez-vous que le test de pureté du riz n'est pas un outil de diagnostic. Il n'est pas censé porter un jugement, assigner une valeur morale ou catégoriser les gens dans de petites cases bien rangées. Votre score ne vous définit pas ; c'est simplement un instantané des expériences que vous avez eues (ou pas eues) jusqu'à présent dans votre vie. Le test ne tient pas compte du contexte, des émotions ou de la complexité des parcours individuels.",
  "Le test de pureté du riz est un vestige d'une époque révolue qui s'est adapté et a survécu à l'ère numérique. Il offre un moyen ludique de réfléchir aux expériences personnelles. Que vous regardiez en arrière avec un sourire, un rire nerveux ou un sentiment de curiosité, rappelez-vous que la vie est un voyage, pas seulement les étapes en cours de route.",
  "FAQ",
  "1. Qu'est-ce que le test de pureté du riz ?",
  "Réponse :",
  "Le test de pureté du riz est une enquête auto-évaluée qui évalue le niveau d'innocence d'une personne dans les affaires du monde. Il se concentre principalement sur les expériences romantiques, les comportements de transgression des règles et diverses expériences de vie. Le test se compose de 100 questions, et vous répondez 'oui' ou 'non' en fonction de vos actions.",
  "2. Pourquoi s'appelle-t-il le test de pureté du riz ?",
  "Le test a été créé à l'Université Rice à Houston, au Texas, comme un moyen pour les étudiants de mesurer leur maturation pendant la vie universitaire. Il est depuis devenu populaire en ligne comme moyen pour les gens d'évaluer et de comparer leurs expériences.",
  "3. Qui devrait passer le test de pureté du riz ?",
  "Le test est généralement passé par des adolescents et de jeunes adultes pour évaluer leurs expériences par rapport à leurs pairs. Cependant, il est important d'aborder le test avec une mentalité de curiosité plutôt que de jugement et de comprendre qu'il s'agit juste pour s'amuser et non pas d'une mesure du caractère ou de la valeur d'une personne.",
  "4. Le test de pureté du riz est-il précis ?",
  "Le test n'est pas une mesure scientifique et ne doit pas être pris comme une évaluation définitive de l'innocence ou de l'expérience de quelqu'un. C'est plus un outil de conversation ou une façon de réfléchir à ses propres expériences de vie.",
  "5. Comment mon score est-il calculé ?",
  "Votre score est calculé en fonction du nombre de réponses 'oui' et 'non' que vous fournissez. Le score varie de 0 à 100, un score plus élevé indiquant moins d'expériences liées aux questions posées. C'est un décompte simple sans pondération sur aucune question.",
  "6. Puis-je passer le test plusieurs fois ?",
  "Oui, vous pouvez passer le test autant de fois que vous le souhaitez. Certaines personnes le repassent avec le temps pour voir comment leur score change à mesure qu'elles vivent plus de choses.",
  "7. Mon score au test de pureté du riz est-il privé ?",
  "Oui, votre score est privé à moins que vous ne choisissiez de le partager. Le test ne demande aucune information personnellement identifiable, et partager votre score dépend entièrement de vous.",
  "8. Que dois-je faire avec mon score ?",
  "Le score n'est qu'un nombre pour le divertissement. C'est une occasion d'autoréflexion ou de discussion avec des amis mais ne doit pas être pris trop au sérieux. Ce n'est pas un jugement de caractère ou une référence pour les expériences.",
  "9. Y a-t-il différentes versions du test ?",
  "Oui, il existe plusieurs versions du test adaptées à différents publics, certaines se concentrant davantage sur les expériences universitaires et d'autres sur les expériences de vie générales. Assurez-vous de passer la version qui vous semble la plus appropriée.",
  "10. Où puis-je passer le test de pureté du riz ?",
  "Le test est disponible sur divers sites Web et plates-formes. Une recherche rapide sur Internet pour le 'test de pureté du riz' devrait vous conduire à plusieurs versions du test que vous pouvez passer en ligne.",
];

const bannerContentger: ContentArray = [
  "Der Reisepuritätstest ist eine 100-Fragen-Checkliste, die verschiedene Erfahrungen im Zusammenhang mit Regelverstößen, sexuellen Aktivitäten und anderen reifen Themen abdeckt. Er wurde von Studenten der Rice University erstellt, um die Lebenserfahrung einer Person zu messen. Je niedriger Ihre Punktzahl (von 100), desto mehr Erfahrungen haben Sie gemacht. Neugierig zu wissen, was Ihre Reinheitspunktzahl ist. Lassen Sie uns überprüfen: ",
  "Also, was ist der Reisepuritätstest?",
  "Stellen Sie sich eine Checkliste mit 100 Fragen vor, die alle möglichen Erfahrungen abdecken - denken Sie an Regelverstöße, romantische Eskapaden und andere erwachsene Abenteuer. Von Studenten an der Rice University (daher der Name) erstellt, ist dieser Test wie ein persönliches Inventar von 'Habe ich jemals...?'-Momenten.",
  "Ein kleiner Spaziergang durch die Vergangenheit",
  "Der Reisepuritätstest gibt es schon seit geraumer Zeit. Er begann als eine unterhaltsame Möglichkeit für Studenten der Rice University, zu messen, wie viel Lebenserfahrung sie außerhalb des Studiums hatten. Stellen Sie sich eine Gruppe von Studenten in den 20ern (den 1920ern, um genau zu sein) vor, die über ihre Antworten schmunzeln, und Sie haben die richtige Vorstellung. Im Laufe der Zeit wurde es zu einer skurrilen, inoffiziellen Tradition unter den Studenten dort und darüber hinaus.",
  "Was ist der Sinn dahinter?",
  "Sie könnten sich fragen: 'Warum diesen Test machen?' Nun, es geht nicht nur darum, Kästchen anzukreuzen. Für viele ist es ein lockerer Werkzeug zur Selbstreflexion und zum Aufbau von Bindungen. Es ist wie ein Spiegel Ihrer Erfahrungen zu halten und zu sehen, wie sie sich im großen Mosaik des Lebens stapeln. Außerdem ist es ein Eisbrecher. Das Teilen von Ergebnissen kann das Eis brechen und tiefere Verbindungen zwischen Freunden fördern, alles im Spaß.",
  "Bevor Sie Start klicken",
  "Ihr emotionales GPS",
  "Bevor Sie eintauchen, nehmen Sie sich einen Moment Zeit, um sich selbst zu überprüfen. Der Test behandelt viele Themen, von denen einige starke Emotionen oder Erinnerungen auslösen können. Es ist völlig in Ordnung, Pausen einzulegen, Fragen zu überspringen oder zu entscheiden, dass dies nichts für Sie ist. Ihr Wohlbefinden hat oberste Priorität.",
  "Anonymitätsschutz",
  "Denken Sie daran, der Test ist darauf ausgelegt, anonym durchgeführt zu werden. Sie können Ihre Ergebnisse teilen, wenn Sie möchten, und selbst dann sollte das Teilen am besten in Kreisen erfolgen, denen Sie vertrauen. Online sollten Sie sicherstellen, dass Sie eine sichere und private Verbindung verwenden, um Ihre Antworten zwischen Ihnen und dem Bildschirm zu halten.",
  "Der Test selbst",
  "Bereit zum Start? Viele Websites ermöglichen es Benutzern, diesen Test durchzuführen, aber wir machen den Prozess einfacher und interessanter. Über unsere Website können Sie nicht nur den Test durchführen, sondern auch Ihre Ergebnisse in sozialen Medien mit Ihren Freunden teilen und sie einladen, ihren eigenen Test durchzuführen. Basierend auf den von Ihnen gegebenen Antworten erhalten Sie einen detaillierten Bericht über Ihre Reinheitspunktzahl.",
  "Entschlüsselung Ihres Ergebnisses",
  "Sobald Sie den Test abgeschlossen und Ihre Punktzahl haben, nehmen Sie sich einen Moment Zeit zum Nachdenken. Denken Sie daran, diese Zahl ist keine erschöpfende Messung Ihres Charakters oder Ihrer Erfahrungen. Es ist ein lustiger, introspektiver Blick darauf, wo Sie waren und was Sie getan haben. Die Reise jedes Einzelnen ist einzigartig; dies ist nur eine Möglichkeit, einen Teil Ihrer Reise zu erahnen.",
  "Der richtige Zeitpunkt für einen erneuten Test",
  "Sollten Sie den Test erneut machen? Es gibt kein festes Zeitfenster für einen erneuten Test. Zu sehen, wie sich Ihre Punktzahl ändert, wenn Sie wachsen und neue Erfahrungen machen, könnte interessant sein. Einige machen ihn jährlich als skurrile Tradition, während andere länger warten könnten. Es geht darum, wann Sie glauben, dass er neue Einblicke oder eine frische Perspektive auf Ihre Reise bieten könnte.",
  "Der Reisepuritätstest kann eine unterhaltsame Erkundung Ihrer Lebenserfahrungen sein. Gehen Sie neugierig an die Sache heran, kümmern Sie sich um Ihre emotionale Gesundheit und respektieren Sie Ihre Privatsphäre. Ob Ihr Ergebnis Sie überrascht oder bestätigt, was Sie bereits wissen, lassen Sie es einen Ausgangspunkt für die Selbstentdeckung sein, nicht ein endgültiges Urteil. Und denken Sie daran, das Leben ist unendlich komplexer und schöner als jede Checkliste erfassen könnte.",
  "Den Test mit Sorgfalt machen",
  "Sich in den Reisepuritätstest zu vertiefen kann eine Gaudi sein, aber es ist wichtig, ihn mit Achtsamkeit anzugehen. Hier erfahren Sie, wie Sie es verantwortungsbewusst und respektvoll halten können:",
  "Denken Sie daran, es geht nur um Spaß.",
  "Es geht nicht um Bewertung oder darum, jemanden unbehaglich zu machen. Es geht darum, nachzudenken und ein wenig über die wilde Fahrt des Lebens zu lachen.",
  "Der Kontext ist entscheidend.",
  "Einige Fragen berühren sensitive Themen. Es ist in Ordnung, alles zu überspringen, was sich zu persönlich anfühlt oder eine nicht so großartige Erinnerung auslöst.",
  "Privatsphäre hat oberste Priorität.",
  "Ihre Punktzahl geht niemanden etwas an. Teilen Sie sie oder auch nicht - das liegt ganz bei Ihnen. Und wenn Sie teilen, stellen Sie sicher, dass es in einer Umgebung ist, in der sich alle wohl damit fühlen.",
  "Die Entstehungsgeschichte: Wie alles begann",
  "Unsere Geschichte beginnt an der Rice University, eingebettet im Herzen von Houston, Texas. Stellen Sie sich vor: Es ist die frühen 1920er Jahre, eine Zeit der Flapper und des Jazz. Eine Gruppe von Studenten, vielleicht auf der Suche nach einer Pause vom akademischen Leben oder einfach nur auf der Suche nach tieferen Verbindungen, erstellt eine Liste von 100 Fragen. Das war nicht nur irgendeine Liste; es war eine Mischung aus unschuldigen Fragen und mutigeren Fragen über das persönliche Leben und die Erfahrungen der Studenten. Es wird gesagt, dass die ursprüngliche Absicht teilweise darin bestand, den neuen Studenten dabei zu helfen, ihr 'Unschuldsniveau' mit den erfahreneren Oberklassekameraden zu vergleichen.",
  "Evolution im Laufe der Zeit",
  "Wie Sie sich vorstellen können, hat sich seit diesen frühen Tagen viel verändert. Der Reisepuritätstest hat sich verändert, wurde aktualisiert und hat neue Formen angenommen, als er über die heiligen Hallen der Rice University hinaus verbreitet wurde. Er hat sich von handgeschriebenen Kopien, die von Hand zu Hand weitergegeben wurden, zu seiner modernen Inkarnation im Internet entwickelt, zugänglich für neugierige Köpfe auf der ganzen Welt. Während seine Essenz - eine Checkliste von Erfahrungen - intakt geblieben ist, haben sich die Fragen verändert, um sich verändernden gesellschaftlichen Normen und Einstellungen gerecht zu werden.",
  "Entschlüsselung Ihrer Punktzahlen",
  "Also haben Sie den Sprung gewagt und den Test abgeschlossen. Nun starren Sie auf eine Zahl zwischen 0 und 100 und fragen sich: 'Was bedeutet das überhaupt?' Hier ist ein schneller Leitfaden:",
  "100-90:",
  "Sie befinden sich auf der vorsichtigeren Seite der Erfahrungen. Vielleicht haben Sie ein behütetes Leben geführt oder sind einfach selektiv bei Ihren Abenteuern.",
  "89-70:",
  "Sie haben ein paar Teiche betreten, sozusagen. Es gibt hier eine Mischung aus Unschuld und Erfahrung.",
  "69-50:",
  "Das Mittelfeld. Sie haben Ihre Abenteuer gehabt und haben wahrscheinlich einige interessante Geschichten.",
  "49-30:",
  "Sie sind kein Fremder auf der wilden Seite des Lebens. Wahrscheinlich haben Sie eine Vorliebe für Erkundungen und das Überschreiten von Grenzen.",
  "29-0:",
  "Sie haben viel gelebt. Ihr Score deutet auf viele Erfahrungen und wahrscheinlich einige Geschichten hin, die Augenbrauen hochziehen könnten.",
  "Reisepuritätstest & seine Anwendung",
  "Ah, der Reisepuritätstest - eine neugierige kleine Reise durch eine Checkliste, die mehr ist als nur eine Reihe von Fragen. Es ist ein Tor zum Lachen, zur Bindung und manchmal zu einem Spaziergang durch die Vergangenheit. Lassen Sie uns erkunden, wie dieser faszinierende Test Magie in Ihr Leben weben kann, sollen wir?",
  "Verbindungen schmieden",
  "Stellen Sie sich vor, Sie entwirren Geschichten und Geheimnisse unter Freunden oder sogar neuen Menschen, die Sie durch den Reisepuritätstest kennenlernen. Es ist wie das Aufbrechen eines Buches über die Abenteuer des Lebens des anderen. Einige Geschichten könnten Sie näher bringen und ein Gefühl der Verbundenheit auslösen, während andere Sie überraschen könnten, indem sie Schichten offenbaren, die Sie nie gekannt haben. Es geht darum, diese Fäden gemeinsamer Erfahrungen zu finden oder die Vielfalt Ihrer Reisen zu entdecken, die die Grundlage Ihrer Beziehungen enger zusammenstricken.",
  "Die Freude am Lachen",
  "Im Herzen ist der Reisepuritätstest ein Gefäß der reinen Unterhaltung. Jede angekreuzte Frage lädt zum Lachen und zum leichtfüßigen Geplauder ein und verwandelt einen gewöhnlichen Abend in eine Schatztruhe voller Kichern. Es geht um die Momente, in denen Sie sagen: 'Du hast was gemacht?!', gefolgt von einem ausgelassenen Gelächter. Es ist ein spielerischer Ausflug in die vielfältigen Farben des Lebens, gemalt mit dem Pinsel des Humors.",
  "Eine nostalgische Reise",
  "Haben Sie sich jemals nach einem Hauch von Gestern gesehnt? Der Reisepuritätstest ist Ihre Zeitmaschine, jede Frage ein Portal zu Erinnerungen, die lange in den Ecken Ihres Geistes genistet haben. Es geht darum, diese ersten Male wieder zu erleben, die Nervenkitzel, die Eskapaden und manchmal die Momente unschuldigen Unfugs. Es ist nicht nur ein Test; es ist eine Reise zurück in die Tage, die Sie geprägt haben.",
  "Ein Kompass zur Orientierung",
  "Für diejenigen in Beratungspositionen - Eltern, Pädagogen, Berater - bietet der Reisepuritätstest eine einzigartige Perspektive auf die Weltanschauungen und Erfahrungen derjenigen, die Sie begleiten. Es geht nicht um Bewertung, sondern um Verständnis. Es ist ein Ausgangspunkt für Gespräche, die wichtig sind, um durch die Komplexitäten von Wachstum, Entscheidungen und Herausforderungen zu navigieren.",
  "Ein Repository für Erkenntnisse",
  "Im großen Teppich der Gesellschaft dient der Reisepuritätstest als faszinierende Sammlung von Daten und bietet Einblicke in die kollektiven Erfahrungen einer Generation. Für Forscher und Analysten ist er eine Goldmine an Trends und Mustern, eine Momentaufnahme kultureller Zeitgeister, die Studien, Richtlinien und das Verständnis menschlichen Verhaltens im Laufe der Zeit informieren können.",
  "Der Spiegel der Selbstreflexion",
  "Schließlich ist der Reisepuritätstest ein Moment der Introspektion. Es ist eine Gelegenheit zum Innehalten, Nachdenken und Entdecken von Aspekten Ihrer Reise, an die Sie zuvor nicht gedacht haben. Es geht darum zu verstehen, wo Sie waren, wo Sie stehen, und darüber nachzudenken, welche Wege noch zu erkunden sind. Es geht nicht nur darum, Punkte zu zählen, sondern um eine Bestandsaufnahme des Lebensmosaiks, die Wertschätzung für jedes Stück, das zum Ganzen beiträgt.",
  "Also ist der Reisepuritätstest mehr als nur eine Checkliste, sei es zum Lachen, für einen Moment der Verbindung oder für eine tiefere Eintauchen in das Selbst. Er ist eine Feier der Erfahrungen, ein Katalysator für Gespräche und ein Werkzeug zur Reflexion. Tauchen Sie ein, und wer weiß, welche Offenbarungen und Freuden Sie erwarten?",
  "Statistischer Bericht zum Reisepuritätstest",
  "Es gibt viele Statistiken, die von verschiedenen Reisepuritätstesten oder unabhängigen Organisationen abgedeckt wurden. Wir haben auch den Reisepuritätstest umfragen lassen, und hier ist der statistische Bericht, den wir gefunden haben:",
  "Bereich der Punktzahl",
  "Anzahl der Befragten",
  "Prozentsatz des Gesamtwerts",
  "Ein Wort der Vorsicht",
  "Denken Sie daran, dass der Reisepuritätstest kein diagnostisches Werkzeug ist. Er soll kein Urteil fällen, keinen moralischen Wert zuweisen oder Menschen in ordentliche kleine Kisten stecken. Ihre Punktzahl definiert Sie nicht; sie ist einfach eine Momentaufnahme der Erfahrungen, die Sie bis zu diesem Zeitpunkt in Ihrem Leben gemacht haben (oder nicht gemacht haben). Der Test berücksichtigt keinen Kontext, keine Emotionen oder die Komplexität individueller Reisen.",
  "Der Reisepuritätstest ist ein Relikt aus vergangenen Zeiten, das sich im digitalen Zeitalter angepasst und überlebt hat. Er bietet eine spielerische Möglichkeit, über persönliche Erfahrungen nachzudenken. Ob Sie zurückblicken und lächeln, sich gruseln oder neugierig sind, denken Sie daran, dass das Leben eine Reise ist, nicht nur die Meilensteine entlang des Weges.",
  "FAQs",
  "1. Was ist der Reisepuritätstest?",
  "Antwort:",
  "Der Reisepuritätstest ist eine selbstbewertete Umfrage, die das Maß an Unschuld einer Person in weltlichen Angelegenheiten bewertet. Er konzentriert sich hauptsächlich auf romantische Erfahrungen, Regelverstoßverhalten und verschiedene Lebenserfahrungen. Der Test besteht aus 100 Fragen, und Sie antworten mit 'Ja' oder 'Nein' basierend auf Ihren Handlungen.",
  "2. Warum heißt es der Reisepuritätstest?",
  "Der Test entstand an der Rice University in Houston, Texas, als Möglichkeit für Studenten, ihre Reife während des College-Lebens zu messen. Er ist seitdem online populär geworden, um Menschen die Möglichkeit zu geben, ihre Erfahrungen zu bewerten und zu vergleichen.",
  "3. Wer sollte den Reisepuritätstest machen?",
  "Der Test wird im Allgemeinen von Teenagern und jungen Erwachsenen durchgeführt, um ihre Erfahrungen mit Gleichaltrigen zu vergleichen. Es ist jedoch wichtig, sich dem Test mit einer Neugierde statt einem Urteil zu nähern und zu verstehen, dass es nur zum Spaß ist und kein Maß für den Charakter oder den Wert einer Person ist.",
  "4. Ist der Reisepuritätstest genau?",
  "Der Test ist keine wissenschaftliche Messung und sollte nicht als definitive Bewertung von Unschuld oder Erfahrung einer Person angesehen werden. Es handelt sich eher um ein Gesprächswerkzeug oder eine Möglichkeit, über Ihre eigenen Lebenserfahrungen nachzudenken.",
  "5. Wie wird meine Punktzahl berechnet?",
  "Ihre Punktzahl wird basierend auf der Anzahl der 'Ja' und 'Nein'-Antworten berechnet, die Sie geben. Die Punktzahl reicht von 0 bis 100, wobei eine höhere Punktzahl auf weniger Erfahrungen im Zusammenhang mit den gestellten Fragen hinweist. Es ist eine einfache Addition ohne Gewichtung bei einer Frage.",
  "6. Kann ich den Test mehr als einmal machen?",
  "Ja, Sie können den Test so oft machen, wie Sie möchten. Einige Menschen machen ihn im Laufe der Zeit erneut, um zu sehen, wie sich ihre Punktzahl ändert, wenn sie mehr vom Leben erfahren.",
  "7. Ist meine Reisepuritätstest-Punktzahl privat?",
  "Ja, Ihre Punktzahl ist privat, es sei denn, Sie entscheiden sich, sie zu teilen. Der Test fragt nicht nach persönlich identifizierbaren Informationen, und ob Sie Ihre Punktzahl teilen möchten, liegt ganz bei Ihnen.",
  "8. Was soll ich mit meiner Punktzahl machen?",
  "Die Punktzahl ist nur eine Zahl zum Spaß. Es ist eine Gelegenheit zur Selbstreflexion oder zum Gespräch mit Freunden, sollte aber nicht zu ernst genommen werden. Es ist kein Urteil über den Charakter oder ein Maßstab für Erfahrungen.",
  "9. Gibt es verschiedene Versionen des Tests?",
  "Ja, es gibt mehrere Versionen des Tests, die auf verschiedene Zielgruppen zugeschnitten sind. Einige konzentrieren sich mehr auf College-Erfahrungen, andere auf allgemeine Lebenserfahrungen. Stellen Sie sicher, dass Sie die Version wählen, die für Sie am besten geeignet ist.",
  "10. Wo kann ich den Reisepuritätstest machen?",
  "Der Test ist auf verschiedenen Websites und Plattformen verfügbar. Eine schnelle Internetrecherche nach 'Reisepuritätstest' sollte Sie zu mehreren Versionen des Tests führen, die Sie online machen können.",
];
const bannerCOntentIta: ContentArray = [
  "Il Test di Purezza di Rice è un elenco di 100 domande che copre varie esperienze legate alla violazione delle regole, attività sessuali e altri temi maturi. È stato creato dagli studenti della Rice University per misurare l'esperienza di vita di una persona. Più basso è il tuo punteggio (su 100), più esperienze hai avuto. Sei curioso di sapere qual è il tuo punteggio di purezza? Controlliamo:",
  "Quindi, cos'è il Test di Purezza di Rice?",
  "Immagina un elenco di 100 domande che toccano tutti i tipi di esperienze—pensa a buffonate che infrangono le regole, avventure romantiche e altre avventure per adulti. Creato dagli studenti della Rice University (da qui il nome), questo test è come un inventario personale di 'Ho mai...?' momenti.",
  "Un Piccolo Viaggio nella Memoria",
  "Il Test di Purezza di Rice esiste da un bel po' di tempo. È iniziato come un modo divertente per gli studenti della Rice University di misurare quanta esperienza di vita avevano avuto al di fuori degli studi accademici. Immagina un gruppo di studenti degli anni '20 (i 1920, per essere precisi) che ridono delle loro risposte, e hai l'idea giusta. Nel tempo, è diventata una tradizione stravagante e non ufficiale tra gli studenti e oltre.",
  "Qual è il Punto?",
  "Potresti chiederti, 'Perché fare questo test?' Beh, non si tratta solo di spuntare delle caselle. Per molti, è uno strumento leggero per l'auto-riflessione e il legame. È come tenere uno specchio alle tue esperienze e vedere come si confrontano nel grande arazzo della vita. Inoltre, è un rompighiaccio per conversazioni. Condividere i risultati può rompere il ghiaccio e favorire connessioni più profonde tra amici, tutto in modo divertente.",
  "Prima di iniziare",
  "Il Tuo GPS Emozionale",
  "Prima di iniziare, prenditi un momento per controllare con te stesso. Il test copre molti argomenti, alcuni dei quali potrebbero suscitare forti emozioni o ricordi. Prendere pause, saltare domande o decidere che questo test non fa per te è perfettamente normale. Il tuo benessere ha la massima priorità.",
  "Il Mantello dell'Anonimato",
  "Ricorda, il test è progettato per essere svolto in anonimato. Puoi condividere i tuoi risultati se lo desideri, e anche allora, è meglio condividerli con cerchie di persone di cui ti fidi. Online, assicurati di usare una connessione sicura e privata per mantenere le tue risposte tra te e lo schermo.",
  "Il Test Stesso",
  "Pronto a iniziare? Molti siti web permettono agli utenti di fare questo test, ma noi rendiamo il processo più semplice e interessante. Attraverso il nostro sito web, puoi non solo fare il test ma anche condividere i tuoi risultati sui social media con i tuoi amici e invitarli a fare il loro test. In base alle risposte che fornisci, riceverai un rapporto dettagliato sul tuo punteggio di purezza.",
  "Decodifica del Tuo Punteggio",
  "Una volta completato il test e ottenuto il tuo punteggio, prenditi un momento per riflettere. Ricorda, questo numero non è una misura esaustiva del tuo carattere o delle tue esperienze. È uno sguardo divertente e introspettivo su dove sei stato e cosa hai fatto. Il viaggio di ognuno è unico; questo è solo un modo per dare uno sguardo a una parte del tuo.",
  "Il Momento Giusto per un Nuovo Test",
  "Dovresti rifare il test? Non c'è una scadenza fissa per ripetere il test. Vedere come cambia il tuo punteggio man mano che cresci e vivi nuove esperienze potrebbe essere interessante. Alcuni potrebbero rifarlo ogni anno come una tradizione stravagante, mentre altri potrebbero aspettare più a lungo. Tutto dipende da quando senti che potrebbe offrire nuove intuizioni o una prospettiva fresca sul tuo percorso.",
  "Affrontare il Test di Purezza di Rice può essere un'esplorazione leggera delle tue esperienze di vita. Avvicinati con curiosità, cura per la tua salute emotiva e rispetto per la tua privacy. Che il tuo punteggio ti sorprenda o confermi ciò che già sai, lascialo essere un punto di partenza per l'auto-scoperta, non un giudizio definitivo. E ricorda, la vita è infinitamente più complessa e bella di quanto qualsiasi elenco possa catturare.",
  "Prendere il Test con Cura",
  "Immersione nel Test di Purezza di Rice può essere divertente, ma è importante affrontarlo con consapevolezza. Ecco come mantenerlo responsabile e rispettoso:",
  "Ricorda, è solo per divertimento",
  "Non si tratta di giudicare o di far sentire qualcuno a disagio. È un'occasione per riflettere e ridere un po' del viaggio selvaggio che è la vita.",
  "Il contesto è fondamentale",
  "Alcune domande sollevano argomenti sensibili. Va bene saltare tutto ciò che sembra troppo personale o che evoca un ricordo non così piacevole.",
  "La privacy è fondamentale",
  "Il tuo punteggio è affare tuo. Condividilo o no—dipende completamente da te. E se lo condividi, assicurati che sia in un ambiente dove tutti si sentano a proprio agio.",
  "La Storia: Come È Iniziato Tutto",
  "La nostra storia inizia alla Rice University, situata nel cuore di Houston, Texas. Immagina: siamo nei primi anni '20, un periodo di flapper e jazz. Un gruppo di studenti, forse cercando una pausa dalle fatiche della vita accademica o semplicemente desiderosi di creare connessioni più profonde, redige un elenco di 100 domande. Non era solo un elenco qualsiasi; era un mix di domande innocenti e altre più audaci sulla vita personale e le esperienze degli studenti. Si dice che l'intento originale fosse in parte quello di aiutare le matricole a valutare il loro livello di 'innocenza' rispetto agli studenti più esperti.",
  "Evoluzione Attraverso i Secoli",
  "Come puoi immaginare, molto è cambiato da quei primi giorni. Il Test di Purezza di Rice si è trasformato, è stato aggiornato e ha assunto nuove forme mentre si diffondeva oltre le sacre aule della Rice University. Ha viaggiato da copie cartacee passate di mano in mano alla sua incarnazione moderna su internet, accessibile alle menti curiose di tutto il mondo. Anche se la sua essenza—una lista di esperienze—rimane intatta, le domande si sono evolute per riflettere i cambiamenti delle norme e degli atteggiamenti sociali.",
  "Decodifica dei Tuoi Punteggi",
  "Quindi, hai fatto il salto e completato il test. Ora, ti trovi di fronte a un numero tra 0 e 100, chiedendoti, 'Cosa significa tutto questo?' Ecco una breve guida:",
  "100-90:",
  "Sei sul lato più cauto delle esperienze. Forse hai condotto una vita protetta o sei semplicemente selettivo riguardo alle tue avventure.",
  "89-70:",
  "Hai immerso le dita dei piedi in qualche stagno, per così dire. Qui c'è un mix di innocenza ed esperienza.",
  "69-50:",
  "La via di mezzo. Hai avuto la tua parte di avventure e probabilmente hai alcune storie interessanti.",
  "49-30:",
  "Non sei estraneo al lato più selvaggio della vita. Probabilmente hai un'inclinazione per l'esplorazione e per spingere i limiti.",
  "29-0:",
  "Hai vissuto—molto. Il tuo punteggio suggerisce molte esperienze e probabilmente alcune storie che potrebbero far sollevare sopracciglia.",
  "Il Test di Purezza di Rice e il Suo Utilizzo",
  "Ah, il Test di Purezza di Rice—una piccola avventura curiosa attraverso una lista di domande che è più di una semplice serie di domande. È una porta d'ingresso al riso, al legame e, a volte, a un viaggio nei ricordi. Esploriamo come questo intrigante test possa tessere magia nella tua vita, d'accordo?",
  "Forgiare Connessioni",
  "Immagina di svelare storie e segreti tra amici o anche nuove persone che incontri attraverso il Test di Purezza di Rice. È come aprire un libro sulle avventure di vita di ciascuno. Alcuni racconti potrebbero avvicinarti, suscitando un senso di parentela, mentre altri potrebbero sorprenderti, rivelando strati che non sapevi esistessero. Si tratta di trovare quei fili di esperienze comuni o scoprire la diversità nei tuoi percorsi che tessono il tessuto delle tue relazioni.",
  "La Gioia del Ridere",
  "Nel suo cuore, il Test di Purezza di Rice è un contenitore di puro intrattenimento. Ogni domanda invita al riso e alla conversazione leggera, trasformando una serata ordinaria in un tesoro di risate. Si tratta di quei momenti in cui dici, 'Hai fatto cosa?!' seguito da risate fragorose. È un'escursione giocosa nelle innumerevoli sfumature della vita, dipinta con il pennello dell'umorismo.",
  "Un Viaggio Nostalgico",
  "Ti capita mai di desiderare un assaggio del passato? Il Test di Purezza di Rice è la tua macchina del tempo, ogni domanda un portale verso ricordi a lungo nascosti negli angoli della tua mente. Si tratta di rivivere quelle prime volte, i brividi, le avventure e, a volte, i momenti di innocente marachella. Non è solo un test; è un viaggio indietro ai giorni che ti hanno plasmato.",
  "Una Bussola per la Guida",
  "Per coloro che sono in ruoli di guida—genitori, educatori, consulenti—il Test di Purezza di Rice offre una lente unica sulle visioni del mondo e le esperienze di coloro che guidate. Non si tratta di giudicare, ma di comprendere. È un punto di partenza per conversazioni che contano, aiutando a navigare attraverso le complessità della crescita, delle scelte e delle sfide.",
  "Un Deposito di Intuizioni",
  "Nel grande arazzo della società, il Test di Purezza di Rice serve come una collezione affascinante di dati, offrendo scorci nelle esperienze collettive di una generazione. Per ricercatori e analisti, è una miniera d'oro di tendenze e modelli, uno spaccato degli zeitgeist culturali che può informare studi, politiche e comprensione del comportamento umano nel tempo.",
  "Lo Specchio dell'Auto-Riflessione",
  "Infine, il Test di Purezza di Rice è un momento di introspezione. È un'opportunità per fare una pausa, riflettere e scoprire aspetti del tuo viaggio che non avevi considerato prima. Si tratta di capire dove sei stato, dove sei, e ponderare i percorsi ancora da esplorare. Non si tratta solo di sommare punteggi ma di prendere atto del mosaico della vita, apprezzando ogni pezzo che aggiunge al tutto.",
  "Quindi, il Test di Purezza del Riso è più di un semplice elenco di controllo, che sia per una risata, un momento di connessione o una profonda immersione nel sé. È una celebrazione delle esperienze, un catalizzatore per la conversazione e uno strumento di riflessione. Tuffati, e chissà quali rivelazioni e delizie ti attendono?",
  "Rapporto Statistico sui Punteggi del Test di Purezza di Rice",
  "Ci sono molte statistiche che sono state coperte da vari Test di Purezza di Rice o organizzazioni indipendenti. Abbiamo anche condotto un sondaggio sul Test di Purezza di Rice e qui c'è il rapporto statistico che abbiamo trovato:",
  "Fascia di Punteggio",
  "Numero di Rispondenti",
  "Percentuale del Totale",
  "Una Parola di Cautela",
  "Ricorda che il Test di Purezza di Rice non è uno strumento diagnostico. Non è pensato per giudicare, assegnare valori morali o categorizzare le persone in scatole ordinate. Il tuo punteggio non ti definisce; è semplicemente un'istantanea delle esperienze che hai avuto (o non hai avuto) fino a questo punto della tua vita. Il test non tiene conto del contesto, delle emozioni o della complessità dei percorsi individuali.",
  "Il Test di Purezza di Rice è una reliquia di un'era passata che si è adattata e sopravvissuta nell'era digitale. Offre un modo giocoso per riflettere sulle esperienze personali. Che tu stia guardando indietro con un sorriso, una smorfia o un senso di curiosità, ricorda che la vita riguarda il viaggio, non solo i traguardi lungo il percorso.",
  "Domande Frequenti",
  "1. Cos'è il Test di Purezza di Rice?",
  "Risposta:",
  "Il Test di Purezza di Rice è un questionario auto-valutato che misura il livello di innocenza di una persona in questioni mondane. Si concentra principalmente su esperienze romantiche, comportamenti di infrazione delle regole e varie esperienze di vita. Il test consiste di 100 domande a cui rispondi 'sì' o 'no' in base alle tue azioni.",
  "2. Perché si chiama Test di Purezza di Rice?",
  "Il test ha avuto origine alla Rice University di Houston, Texas, come modo per gli studenti di misurare la loro maturazione durante la vita universitaria. Da allora è diventato popolare online come modo per le persone di valutare e confrontare le loro esperienze.",
  "3. Chi dovrebbe fare il Test di Purezza di Rice?",
  "Il test è generalmente fatto da adolescenti e giovani adulti per confrontare le loro esperienze con quelle dei coetanei. Tuttavia, è importante affrontare il test con curiosità piuttosto che giudizio e capire che è solo per divertimento e non una misura del proprio carattere o valore.",
  "4. Il Test di Purezza di Rice è accurato?",
  "Il test non è una misura scientifica e non dovrebbe essere preso come una valutazione definitiva dell'innocenza o dell'esperienza di una persona. È più uno strumento di conversazione o un modo per riflettere sulle proprie esperienze di vita.",
  "5. Come viene calcolato il mio punteggio?",
  "Il tuo punteggio viene calcolato in base al numero di risposte 'sì' e 'no' che fornisci. Il punteggio varia da 0 a 100, con un punteggio più alto che indica meno esperienze relative alle domande poste. È un semplice conteggio senza ponderazione su alcuna domanda.",
  "6. Posso fare il test più di una volta?",
  "Sì, puoi fare il test quante volte vuoi. Alcune persone lo rifanno nel tempo per vedere come cambia il loro punteggio man mano che vivono più esperienze.",
  "7. Il mio punteggio del Test di Purezza di Rice è privato?",
  "Sì, il tuo punteggio è privato a meno che tu non scelga di condividerlo. Il test non chiede alcuna informazione personale identificabile e condividere il tuo punteggio dipende interamente da te.",
  "8. Cosa dovrei fare con il mio punteggio?",
  "Il punteggio è solo un numero per intrattenimento. È un'opportunità per riflettere su se stessi o discutere con gli amici, ma non dovrebbe essere preso troppo sul serio. Non è un giudizio sul carattere o un punto di riferimento per le esperienze.",
  "9. Esistono versioni diverse del test?",
  "Sì, ci sono diverse versioni del test adattate a diversi pubblici, alcune si concentrano di più sulle esperienze universitarie e altre sulle esperienze di vita in generale. Assicurati di fare la versione che ritieni più appropriata per te.",
  "10. Dove posso fare il Test di Purezza di Rice?",
  "Il test è disponibile su vari siti web e piattaforme. Una rapida ricerca su internet per il 'Test di Purezza di Rice' dovrebbe portarti a diverse versioni del test che puoi fare online.",
];

const bannerContentpolish: ContentArray = [
  "Test Czystości Ryżu to lista 100 pytań, która dotyczy różnych doświadczeń związanych z łamaniem zasad, aktywnościami seksualnymi i innymi dojrzałymi tematami. Została stworzona przez studentów Uniwersytetu Rice, aby zmierzyć doświadczenie życiowe osoby. Im niższy twój wynik (od 100), tym więcej doświadczeń miałeś. Ciekawy, jaki jest twój wynik czystości. Sprawdźmy: ",
  "Tak więc, czym jest Test Czystości Ryżu?",
  "Wyobraź sobie listę 100 pytań dotykających różnych doświadczeń - myśl o psotach łamiących zasady, romantycznych eskapadach i innych dorosłych przygodach. Stworzony przez studentów Uniwersytetu Rice (stąd nazwa), ten test jest jak osobista inwentaryzacja momentów „Czy kiedykolwiek…?”.",
  "Trochę spaceru po drodze pamięci",
  "Test Czystości Ryżu istnieje od jakiegoś czasu. Rozpoczął się jako zabawny sposób dla studentów Uniwersytetu Rice, aby zmierzyć, ile doświadczeń życiowych mieli poza akademickimi. Wyobraź sobie grupę studentów w latach 20. (lata 20., aby być precyzyjnym) śmiejących się z odpowiedzi, i masz dobry pomysł. Z czasem stał się dziwną, nieoficjalną tradycją wśród studentów tam i poza nimi.",
  "Jaki jest cel?",
  "Możesz zapytać: „Po co robić ten test?” Cóż, to więcej niż po prostu zaznaczanie pudełek. Dla wielu jest to lekki narzędzie do samooceny i budowania więzi. To jak trzymanie lustra przed twoimi doświadczeniami i zobaczenie, jak się one układają w wielkiej tkaninie życia. Ponadto, jest to temat do rozmowy. Dzielenie się wynikami może przełamać lody i pogłębić więzi między przyjaciółmi, wszystko w dobrej zabawie.",
  "Zanim Klikniesz Start",
  "Twój emocjonalny GPS",
  "Zanim się zanurzysz, zatrzymaj się na chwilę i sprawdź, jak się czujesz. Test obejmuje wiele tematów, które mogą wywołać silne emocje lub wspomnienia. Robienie przerw, pomijanie pytań lub decydowanie, czy to nie dla ciebie, jest całkowicie w porządku. Twoje samopoczucie jest najważniejsze.",
  "Plaszcz Anonimowości",
  "Pamiętaj, że test został zaprojektowany do anonimowego przeprowadzania. Możesz udostępnić swoje wyniki, jeśli chcesz, a nawet wtedy najlepiej jest ograniczyć udostępnianie do osób, którym ufasz. W internecie upewnij się, że korzystasz z bezpiecznego i prywatnego połączenia, aby utrzymać swoje odpowiedzi między tobą a ekranem.",
  "Sam Test",
  "Czy jesteś gotowy zacząć? Wiele witryn internetowych umożliwia użytkownikom przeprowadzenie tego testu, ale my sprawiamy, że cały proces jest prostszy i bardziej interesujący. Poprzez naszą witrynę możesz nie tylko przeprowadzić test, ale także udostępnić swoje wyniki w mediach społecznościowych swoim znajomym i zaprosić ich do przeprowadzenia własnego testu. Na podstawie udzielonych odpowiedzi otrzymasz szczegółowy raport dotyczący twojego wyniku czystości.",
  "Odkodowanie Twojego Wyniku",
  "Po ukończeniu testu i otrzymaniu wyniku, zatrzymaj się na chwilę i zastanów. Pamiętaj, że ta liczba nie jest wyczerpującym pomiarem twojego charakteru ani doświadczeń. To zabawne, introspektywne spojrzenie na to, gdzie byłeś i co zrobiłeś. Podróż każdego jest unikalna; to tylko jeden sposób na rzucenie okiem na część twojej podróży.",
  "Odpowiedni Czas na Powtórkę",
  "Czy powinieneś powtórzyć test? Nie ma ustalonego terminu na powtórzenie. Zobaczenie, jak zmienia się twój wynik w miarę jak rośniesz i zdobywasz nowe doświadczenia, może być interesujące. Niektórzy powtarzają go co roku jako dziwaczną tradycję, podczas gdy inni mogą czekać dłużej. Chodzi o to, kiedy czujesz, że może to przynieść nowe spostrzeżenia lub świeże spojrzenie na twoją podróż.",
  "Przeprowadzenie Testu Czystości Ryżu może być lekkim eksplorowaniem doświadczeń życiowych. Podchodź do tego z ciekawością, troską o swoje zdrowie emocjonalne i szanuj swoją prywatność. Bez względu na to, czy twój wynik cię zaskakuje, czy potwierdza to, co już wiesz, pozwól mu być punktem wyjścia do samopoznania, a nie ostateczną oceną. I pamiętaj, że życie jest nieskończenie bardziej skomplikowane i piękne, niż może uchwycić jakakolwiek lista kontrolna.",
  "Robienie Testu z Ostrożnością",
  "Zanurzenie się w Test Czystości Ryżu może być zabawne, ale ważne jest, aby podejść do tego z pewną ostrożnością. Oto kilka wskazówek, które mogą ci pomóc:",
  "Pamiętaj, że to tylko dla zabawy.",
  "To nie chodzi o ocenę ani sprawienie, żeby ktoś czuł się niekomfortowo. To szansa na refleksję i trochę śmiechu z dzikiej jazdy, jaką jest życie.",
  "Kontekst jest kluczem.",
  "Niektóre pytania dotyczą wrażliwych tematów. Możesz pominąć wszystko, co wydaje się zbyt osobiste lub wywołuje niezbyt dobre wspomnienia.",
  "Prywatność jest najważniejsza.",
  "Twój wynik to twoja sprawa. Możesz go udostępnić lub nie - to całkowicie od ciebie zależy. A jeśli udostępnisz, upewnij się, że dzieje się to w środowisku, w którym wszyscy się z tym zgadzają.",
  "Tło historii: Jak to wszystko się zaczęło",
  "Nasza historia zaczyna się na Uniwersytecie Rice, zlokalizowanym w sercu Houston, Teksas. Wyobraź sobie to: jest wczesne lata 20., czas flappersów i jazzu. Grupa studentów, być może szukająca odskoczni od rygorów życia akademickiego lub po prostu próbująca zawrzeć głębsze więzi, tworzy listę 100 pytań. To nie była zwykła lista; to była mieszanka niewinnych pytań i bardziej śmiałych pytań dotyczących życia i doświadczeń studentów. Mówi się, że pierwotnym zamiarem było częściowo pomóc nowo przybyłym studentom w oszacowaniu ich poziomu „niewinności” w porównaniu z bardziej doświadczonymi studentami starszych lat.",
  "Ewolucja przez lata",
  "Jak można sobie wyobrazić, wiele zmieniło się od tamtych czasów. Test Czystości Ryżu przekształcił się, został zaktualizowany i przyjął nowe formy, gdy rozprzestrzenił się poza świętymi murami Uniwersytetu Rice. Podróżował od ręcznie przekazywanych kopii na papierze do jego nowoczesnej postaci w internecie, dostępnej dla ciekawskich umysłów na całym świecie. Podczas gdy jego istota - lista doświadczeń - pozostaje nietknięta, pytania ewoluowały, aby odzwierciedlić zmieniające się normy i postawy społeczne.",
  "Odkodowanie Twoich Wyników",
  "Więc zanurzyłeś się i ukończyłeś test. Teraz patrzysz na liczbę między 0 a 100, zastanawiając się: „Co to nawet znaczy?”. Oto szybki przewodnik:",
  "100-90:",
  "Jesteś raczej ostrożny w doświadczeniach. Być może prowadziłeś schowaną od świata życie lub po prostu jesteś wybredny w swoich przygodach.",
  "89-70:",
  "Wkraczałeś w kilka stawów, jak to się mówi. Jest tu mieszanka niewinności i doświadczenia.",
  "69-50:",
  "Środek drogi. Miałeś swoją dawkę przygód i prawdopodobnie masz kilka ciekawych historii.",
  "49-30:",
  "Nie jesteś obcy dla dzikiego boku życia. Szanse są takie, że masz skłonność do eksploracji i przekraczania granic.",
  "29-0:",
  "Żyłeś - dużo. Twój wynik sugeruje wiele doświadczeń i prawdopodobnie kilka opowieści, które mogłyby zadziwić.",
  "Test Czystości Ryżu i jego Użycie",
  "Ah, Test Czystości Ryżu - ciekawa mała przygoda przez listę kontrolną, która jest czymś więcej niż tylko serią pytań. To brama do śmiechu, więzi i czasem spaceru po drodze pamięci. Sprawdźmy, jak ten intrygujący test może wprowadzić magię do twojego życia, co powiesz?",
  "Kształtowanie więzi",
  "Wyobraź sobie odkrywanie historii i sekretów wśród przyjaciół lub nawet nowych osób, których spotykasz dzięki Testowi Czystości Ryżu. To jak otwieranie księgi przygód życiowych każdego. Niektóre opowieści mogą zbliżyć cię do siebie, wywołując poczucie pokrewieństwa, podczas gdy inne mogą cię zaskoczyć, ujawniając warstwy, których nie wiedziałeś, że istnieją. Chodzi o znalezienie tych nici wspólnych doświadczeń lub odkrycie różnorodności w twoich podróżach, które splatają twoje relacje mocniej.",
  "Radość ze śmiechu",
  "W swoim sercu Test Czystości Ryżu to naczynie czystej rozrywki. Każde zaznaczone pytanie zaprasza do śmiechu i żartów, zamieniając zwykły wieczór w skarbnicę śmiechu. To te chwile, gdy mówisz: „Zrobiłeś co?!”, Za którymi następuje huczny śmiech. To zabawna wycieczka w tysiące odcieni życia, malowana pędzlem humoru.",
  "Wspomnieniowa podróż",
  "Czy kiedykolwiek pragnąłeś smaku dawnych czasów? Test Czystości Ryżu to twoja maszyna czasu, każde pytanie portal do wspomnień długo ukrytych w kątach twojego umysłu. Chodzi o ponowne przeżywanie tych pierwszych, ekscytacji, eskapad i czasem momentów niewinnej psoty. To nie tylko test; to podróż z powrotem do dni, które cię ukształtowały.",
  "Kompas dla Przewodnictwa",
  "Dla tych w rolach doradczych - rodziców, edukatorów, doradców - Test Czystości Ryżu oferuje unikalną perspektywę na światopoglądy i doświadczenia tych, których prowadzisz. Chodzi tu nie o ocenę, ale zrozumienie. To punkt wyjścia do rozmów, które się liczą, pomagając poruszać się przez zawiłości wzrostu, wyborów i wyzwań.",
  "Repozytorium Wniosków",
  "W wielkiej tkance społeczeństwa Test Czystości Ryżu służy jako fascynująca kolekcja danych, oferując spojrzenia na zbiorowe doświadczenia pokolenia. Dla badaczy i analityków to kopalnia trendów i wzorców, migawka kulturowych duchów czasów, która może informować badania, polityki i zrozumienie ludzkiego zachowania z czasem.",
  "Lustro Samorefleksji",
  "Na koniec Test Czystości Ryżu to chwila introspekcji. To szansa na zatrzymanie się, zastanowienie się i odkrycie aspektów swojej podróży, o których wcześniej nie myślałeś. Chodzi o zrozumienie, gdzie byłeś, gdzie stoisz i rozważanie dróg, które jeszcze mają być zbadane. To nie tylko o sumowaniu wyników, ale o ocenienie mozaiki życia, docenienie każdego elementu, który dodaje do całości.",
  "Tak więc Test Czystości Ryżu to coś więcej niż tylko lista kontrolna, czy to dla śmiechu, chwili połączenia, czy dogłębnego zanurzenia w sobie. To świętowanie doświadczeń, katalizator rozmowy i narzędzie do refleksji. Zanurz się, a kto wie, jakie objawienia i przyjemności cię czekają?",
  "Raport Statystyczny Wyników Testu Czystości Ryżu",
  "Istnieje wiele statystyk, które zostały opracowane przez różne Testy Czystości Ryżu lub niezależne organizacje. Przeprowadziliśmy również badanie Testu Czystości Ryżu i oto raport statystyczny, który znaleźliśmy:",
  "Zakres Wyników",
  "Liczba Odpowiedzi",
  "Procent Całości",
  "Słowo Ostrzeżenia",
  "Pamiętaj, że Test Czystości Ryżu nie jest narzędziem diagnostycznym. Nie ma na celu wydawania osądów, przypisywania wartości moralnej ani kategoryzowania ludzi w schludne małe pudełka. Twój wynik nie definiuje cię; to po prostu migawka doświadczeń, jakie miałeś (lub nie miałeś) do tego momentu w swoim życiu. Test nie uwzględnia kontekstu, emocji ani złożoności indywidualnych podróży.",
  "Test Czystości Ryżu to relikt z minionych czasów, który przystosował się i przetrwał w erze cyfrowej. Oferuje zabawny sposób na refleksję nad osobistymi doświadczeniami. Bez względu na to, czy patrzysz wstecz z uśmiechem, skrzywieniem czy poczuciem ciekawości, pamiętaj, że życie chodzi o podróż, nie tylko o kamienie milowe na drodze.",
  "Najczęstsze pytania",
  "1. Czym jest Test Czystości Ryżu?",
  "Odpowiedź:",
  "Test Czystości Ryżu to samodzielna ankieta oceniająca poziom niewinności osoby w sprawach świata. Koncentruje się głównie na doświadczeniach romantycznych, łamaniu zasad i różnych doświadczeniach życiowych. Test składa się z 100 pytań, a odpowiedź brzmi „tak” lub „nie” na podstawie twoich działań.",
  "2. Dlaczego nazywa się to Testem Czystości Ryżu?",
  "Test wywodzi się z Uniwersytetu Rice w Houston, Teksasie, jako sposób dla studentów na pomiar ich dojrzewania w trakcie życia college’owego. Od tego czasu stał się popularny w internecie jako sposób na ocenę i porównanie swoich doświadczeń.",
  "3. Kto powinien wykonać Test Czystości Ryżu?",
  "Test jest generalnie przeznaczony dla nastolatków i młodych dorosłych, aby ocenić swoje doświadczenia w porównaniu z rówieśnikami. Jednak ważne jest, aby podejść do testu z nastawieniem ciekawości, a nie oceny, i zrozumieć, że służy on tylko zabawie i nie jest miarą czyjegoś charakteru ani wartości.",
  "4. Czy Test Czystości Ryżu jest dokładny?",
  "Test nie jest miarą naukową i nie powinien być traktowany jako ostateczna ocena czyjejś niewinności czy doświadczenia. Jest to raczej narzędzie konwersacyjne lub sposób na refleksję nad własnymi doświadczeniami życiowymi.",
  "5. Jak obliczany jest mój wynik?",
  "Twój wynik jest obliczany na podstawie liczby odpowiedzi „tak” i „nie”, które udzielasz. Wynik mieści się w zakresie od 0 do 100, gdzie wyższy wynik oznacza mniej doświadczeń związanych z pytaniem zadawanym. Jest to prosta suma bez przypisywania wagi do jakiegokolwiek pytania.",
  "6. Czy mogę wykonać test więcej niż raz?",
  "Tak, możesz wykonać test tyle razy, ile chcesz. Niektórzy ludzie wykonują go ponownie z upływem czasu, aby zobaczyć, jak zmienia się ich wynik w miarę doświadczania nowych rzeczy.",
  "7. Czy wynik Testu Czystości Ryżu ma znaczenie?",
  "To zależy od twojej perspektywy. Dla niektórych może być to ciekawa refleksja nad własnymi doświadczeniami, podczas gdy dla innych może to być po prostu zabawna zabawa z przyjaciółmi. Warto jednak podkreślić, że test nie powinien być używany do oceny czyjegoś charakteru ani wartości.",
  "8. Czy mogę udostępnić swoje wyniki?",
  "Tak, możesz udostępnić swoje wyniki, jeśli chcesz. Jednak ważne jest, aby upewnić się, że robisz to w odpowiednim kontekście i z zachowaniem prywatności. Pamiętaj, że wynik testu jest osobistą informacją i należy do ciebie, czy chcesz ją udostępnić czy nie.",
  "9. Czy istnieje wersja papierowa Testu Czystości Ryżu?",
  "Tak, Test Czystości Ryżu został pierwotnie stworzony jako lista pytań na papierze przez studentów Uniwersytetu Rice. Jednak w erze cyfrowej istnieją również wersje internetowe, które są łatwo dostępne dla wszystkich.",
  "10. Czy Test Czystości Ryżu jest odpowiedni dla wszystkich?",
  "Test może być odpowiedni dla osób dorosłych lub starszych nastolatków, ale ważne jest, aby podchodzić do niego z odpowiednim podejściem. Niektóre pytania mogą dotyczyć wrażliwych tematów, więc należy zachować ostrożność i pamiętać, że test ma służyć głównie zabawie i refleksji.",
];

const bannerContentchi: ContentArray = [
  "大米纯度测试是一个包含100个问题的检查表，涵盖了与违反规则、性行为和其他成熟主题相关的各种经历。它是由莱斯大学的学生创建的，旨在衡量一个人的生活经历。你的分数（满分100分）越低，你的经历就越多。想知道你的纯度分数是多少吗？让我们来看看吧：",
  "那么，什么是大米纯度测试？",
  "想象一下一个包含100个问题的检查表，涉及各种经历——想想违反规则的滑稽行为、浪漫的奇遇和其他成年人的冒险。这个测试由莱斯大学的学生创建（因此得名），它就像是一份个人的“我曾经……？”时刻清单。",
  "小小的回忆之旅",
  "大米纯度测试已经存在了相当长的时间。它起初是莱斯大学学生衡量他们在学术之外的生活经历的一种有趣方式。想象一下，20年代（准确来说是20世纪20年代）一群学生在笑着看他们的答案，你就明白了。随着时间的推移，它成为了那里和其他地方的学生之间的一个古怪的非官方传统。",
  "有何意义？",
  "你可能会想，“为什么要参加这个测试？”嗯，它不仅仅是打勾的问题。对于许多人来说，它是一个轻松的自我反思和联结工具。就像拿着一面镜子看自己的经历，看看它们在生活的宏伟画卷中是如何堆叠的。另外，它也是一个谈资。分享结果可以打破沉默，促进朋友之间更深层次的联系，都是为了好玩。",
  "在你点击开始之前",
  "你的情绪导航",
  "在开始之前，花点时间与自己交流。测试涵盖了许多话题，其中一些可能会引发强烈的情绪或回忆。休息一下，跳过问题，或者决定这不适合你都是完全可以的。你的健康至关重要。",
  "匿名保护",
  "记住，这个测试是设计为匿名参加的。你可以选择分享你的结果，即使分享了，也最好在你信任的圈子里分享。在网上，确保你使用安全和私密的连接，保持你的答案只在你和屏幕之间。",
  "测试本身",
  "准备好了吗？许多网站让用户进行这项测试，但我们使这个过程更简单有趣。通过我们的网站，你不仅可以参加测试，还可以将结果分享到社交媒体上，邀请你的朋友进行他们自己的测试。根据你提供的答案，你将收到关于你的纯度分数的详细报告。",
  "解读你的分数",
  "完成测试并获得分数后，花点时间来反思。记住，这个数字并不是对你的性格或经历的全面衡量。这是一个有趣的内省，看看你走过了哪些路，做过了什么。每个人的旅程都是独特的；这只是窥探你旅程的一种方式。",
  "重新测试的正确时间",
  "应该重新参加测试吗？重新测试没有设定的时间表。看到你的分数随着你的成长和新经历而变化可能会很有趣。有些人可能每年都会参加一次，作为一种古怪的传统，而其他人可能会等待更长时间。这完全取决于你觉得什么时候可能会为你的旅程提供新的见解或新的视角。",
  "参加大米纯度测试可以是对你生活经历的一次轻松探索。用好奇心、关心你的情感健康和尊重你的隐私来对待它。无论你的分数是否让你感到惊讶，还是证实了你已经知道的，让它成为自我发现的起点，而不是一个明确的判断。记住，生活比任何清单都要复杂和美丽。",
  "小心参加测试",
  "参加大米纯度测试可能很有趣，但重要的是要以一些注意事项来对待它。以下是如何保持负责任和尊重的方法：",
  "记住，这只是为了好玩。",
  "这不是关于判断或让任何人感到不舒服。这是一个反思的机会，也是在这个名为生活的狂野旅程中笑一笑的机会。",
  "背景是关键。",
  "有些问题引发了敏感的话题。跳过任何让人感到太个人化或触发不太好的记忆的东西都是可以的。",
  "隐私至关重要。",
  "你的分数是你的事情。分享它，或者不分享——完全取决于你。如果你选择分享，确保它是在每个人都感觉可以的环境中。",
  "故事背后：一切的开始",
  "我们的故事始于位于德克萨斯州休斯顿市中心的莱斯大学。想象一下：年代是20世纪20年代初，是狂欢女郎和爵士乐的时代。一群学生，也许是为了从紧张的学术生活中解脱出来，或者只是想要建立更深厚的联系，起草了100个问题的清单。这不只是一个清单；它是无辜问题和关于学生个人生活和经历的更大胆的问题的混合体。据说，最初的目的部分是帮助新生对比自己的“纯洁度”与经验丰富的老年学生。",
  "随着时间的推移",
  "正如你所能想象的，自那些早期时代以来，发生了很多变化。大米纯度测试已经演变，更新，并以新的形式出现，它已经远离了莱斯大学神圣的大厅，走向了现代化的互联网，可以让全球各地的好奇心发挥作用。虽然它的本质——一系列经历的检查表——保持不变，但问题已经演变，以反映不断变化的社会规范和态度。",
  "解读你的分数",
  "所以，你已经冒险并完成了测试。现在，你正盯着0到100之间的数字，想着，“这到底是什么意思？”这里有一个快速指南：",
  "100-90：",
  "你更谨慎地经历。也许你过着被保护的生活，或者只是对你的冒险有所选择。",
  "89-70：",
  "你已经试过了一些水。这里有一些天真和经验的混合。",
  "69-50：",
  "中间地带。你经历了一些冒险，可能有一些有趣的故事。",
  "49-30：",
  "你对生活中更疯狂的一面并不陌生。很有可能，你喜欢探索和挑战界限。",
  "29-0：",
  "你已经过了很多。你的分数表明有很多经历，可能还有一些可以引起不少人关注的故事。",
  "大米纯度测试及其使用",
  "啊，大米纯度测试——一个好奇的冒险，穿过一个不仅仅是一系列问题的检查表。它是一个引发笑声、联结和有时是对记忆之旅的大门。让我们探索一下这个有趣测试如何将魔法编织到你的生活中，好吗？",
  "建立联系",
  "想象一下，在大米纯度测试中，朋友们之间或者甚至是你通过大米纯度测试认识的新人之间，揭示故事和秘密。就像打开了彼此生活冒险的一本书。有些故事可能会拉近你我之间的距离，引发一种亲近感，而其他故事可能会让你感到惊讶，揭示出你从未知道的层面。这是发现共同经历的线索或发现你的旅程中的多样性，这些经历把你们的关系紧密地联系在一起。",
  "欢笑的乐趣",
  "在其核心，大米纯度测试是纯粹娱乐的载体。每个打勾的问题都会引发笑声和轻松的玩笑，将一普通的晚上变成了一个笑声宝库。当你说，“你做了什么？！”接着是一阵大笑。这是对生活的无数色彩进行了一次有趣的游览，用幽默的刷子描绘出来。",
  "怀旧之旅",
  "你曾经发现自己渴望尝试昔日的味道吗？大米纯度测试就是你的时光机，每个问题都是你记忆中长期安放在心底的门户。这是重新体验那些第一次，那些刺激，那些奇遇，有时是无辜恶作剧的时刻。这不仅仅是一次测试；它是回到塑造你的日子的时光之旅。",
  "指导的指南",
  "对于那些处于指导角色的人——父母、教育工作者、辅导员——大米纯度测试为你提供了一个独特的视角，帮助你了解你指导的人的世界观和经历。这不是关于判断，而是理解。这是对重要对话的起点，帮助你穿越成长、选择和挑战的复杂性。",
  "见解的存储库",
  "在社会的宏伟画卷中，大米纯度测试作为数据的一个有趣收集，为研究人员和分析人员提供了趋势和模式的宝库，是文化精神的一个快照，可以为随时间变化的人类行为的研究、政策和理解提供信息。",
  "自我反省的镜子",
  "最后，大米纯度测试是一次内省。这是一个停顿、反思、发现你从未考虑过的旅程方面的机会。这不仅仅是对分数进行总结，而是对生活的马赛克进行盘点，欣赏每一块增添整体的碎片。",
  "所以，大米纯度测试不仅仅是一个检查表，无论是为了一笑、一次联系还是对自我的深入挖掘。它是对经历的庆祝，对谈话的催化剂，对反思的工具。跳进去，谁知道什么样的启示和乐趣在等着你？",
  "大米纯度测试分数统计报告",
  "有许多统计数据是由各种大米纯度测试或独立组织提供的。我们也对大米纯度测试进行了调查，这是我们找到的统计报告：",
  "分数范围",
  "回答者人数",
  "总比例",
  "注意事项",
  "请记住，大米纯度测试不是一种诊断工具。它不是用来做判断、赋予道德价值或将人们分类为整洁小盒子的。你的分数并不定义你；它只是你生活中到目前为止经历的（或未经历的）经历的一个快照。测试不考虑背景、情感或个体旅程的复杂性。",
  "大米纯度测试是一个来自过去时代的遗物，在数字时代得以适应和生存。它提供了一种对个人经历进行轻松反思的方式。无论你是笑着回顾，还是感到为难或好奇，都要记住，生活是关于旅程的，而不仅仅是旅程中的里程碑。",
  "常见问题",
  "1. 什么是大米纯度测试？",
  "答案：",
  "大米纯度测试是一个自评分调查，评估一个人在世俗事务中的纯洁程度。它主要关注浪漫经历、违反规则的行为和各种生活经历。测试由100个问题组成，你根据自己的行为回答“是”或“否”。",
  "2. 为什么叫大米纯度测试？",
  "这个测试起源于德克萨斯州休斯敦的莱斯大学，是学生们在大学生活中衡量他们的成熟度的一种方式。随后，它作为一种比较经历的方式，在网上变得流行，人们可以评估和比较他们的经历。",
  "3. 谁应该参加大米纯度测试？",
  "一般来说，这个测试是由青少年和年轻人参加的，以与同龄人的经历进行比较。然而，重要的是要以好奇心的心态来对待这个测试，而不是评判，并且要理解这只是为了好玩，而不是评价一个人的性格或价值。",
  "4. 大米纯度测试准确吗？",
  "这个测试不是一个科学的评估工具，不应该被看作是一个对一个人纯洁度或经验的确定性评估。它更像是一个交流工具或者反思你自己生活经历的一种方式。",
  "5. 我的分数是如何计算的？",
  "你的分数是根据你提供的“是”和“否”答案计算的。分数范围从0到100，分数越高，与问题相关的经历就越少。这是一个简单的总数，没有对任何问题进行加权。",
  "6. 我可以多次参加测试吗？",
  "是的，你可以随时参加测试。一些人随着时间的推移重新参加测试，看看他们的分数随着他们的生活经历的增加而如何变化。",
  "7. 我的大米纯度测试分数是私密的吗？",
  "是的，你的分数是私密的，除非你选择分享。测试不会要求任何个人身份信息，分享你的分数完全由你决定。",
  "8. 我应该怎么处理我的分数？",
  "这个分数只是一个娱乐数字。这是一个自我反思或与朋友讨论的机会，但不应该太认真。这不是性格的判断或经历的基准。",
  "9. 测试有不同的版本吗？",
  "是的，有几个版本的测试针对不同的受众，有些更注重大学经历，而其他一些则更注重一般的生活经历。确保选择对你来说最合适的版本。",
  "10. 我在哪里可以参加大米纯度测试？",
  "测试可以在各种网站和平台上进行。快速搜索“大米纯度测试”应该会引导你找到几个可以在线参加测试的版本。",
];

const bannerCOntentturkish: ContentArray = [
  "Rice Purity Test, kural ihlalleri, cinsel aktiviteler ve diğer olgun temalarla ilgili çeşitli deneyimleri kapsayan 100 soruluk bir kontrol listesidir. Rice Üniversitesi öğrencileri tarafından bir kişinin yaşam deneyimini ölçmek için oluşturulmuştur. Puanınız ne kadar düşükse (100 üzerinden), yaşadığınız deneyimler o kadar fazladır. Temizlik puanınızı öğrenmek ilginç olabilir. Hadi kontrol edelim:",
  "Peki, Rice Purity Testi nedir?",
  "100 soruluk bir kontrol listesini düşünün; kural ihlalleri, romantik maceralar ve diğer yetişkin maceraları içeren deneyimlerin hepsini ele alır. Rice Üniversitesi öğrencileri tarafından oluşturulmuş (bu yüzden adı) bu test, 'Hiç...?' anlarını kişisel bir envanter gibi ele alır.",
  "Biraz Nostaljiye Yolculuk",
  "Rice Purity Testi oldukça uzun bir süredir var. Başlangıçta Rice Üniversitesi öğrencileri için akademik olmayan hayatta ne kadar deneyim sahibi olduklarını ölçmek için eğlenceli bir yol olarak başladı. Bir grup öğrenciyi 20'lerde (1920'lerde, tam olarak) cevapları üzerinde gülerek hayal edin ve doğru fikre sahip olursunuz. Zamanla, orada ve ötesindeki öğrenciler arasında tuhaf, resmi olmayan bir gelenek haline geldi.",
  "Nokta Nedir?",
  "Bu testi neden almalıyım diye düşünebilirsiniz? Cevap, sadece kutuları işaretlemekten daha fazlasıdır. Birçok kişi için, bu hafif bir öz-yansıtma ve bağlanma aracıdır. Deneyimlerinize bir ayna tutmak ve bunların yaşamın büyük dokusunda nasıl biriktiğini görmek gibidir. Üstelik, sohbet başlatıcısıdır. Sonuçları paylaşmak buzları kırabilir ve arkadaşlar arasında derin bağlantılar kurulmasına yardımcı olabilir, hepsi eğlenceli bir şekilde.",
  "Başlamadan Önce",
  "Duygusal GPS'iniz",
  "Dalmadan önce, kendinizle bir kontrol yapmak için bir an ayırın. Test birçok konuyu kapsar, bazıları güçlü duyguları veya anıları tetikleyebilir. Ara vermek, soruları atlamak veya bunun sizin için olmadığına karar vermek tamamen normaldir. İyiliğiniz en üst önceliğe sahiptir.",
  "Anonimlik Hükmü",
  "Unutmayın, test anonim olarak alınacak şekilde tasarlanmıştır. Sonuçlarınızı paylaşabilirsiniz ve hatta o zaman bile, paylaşma en güvendiğiniz çevrelerde yapılmalıdır. Çevrimiçi olarak, cevaplarınızı sadece sizin ve ekran arasında tutmak için güvenli ve özel bir bağlantı kullandığınızdan emin olun.",
  "Test Kendisi",
  "Hazır mısınız? Birçok web sitesi kullanıcıların bu testi yapmasına izin verir, ancak süreci daha basit ve ilginç hale getiriyoruz. Web sitemiz aracılığıyla sadece testi almaz, aynı zamanda sonuçlarınızı sosyal medyada arkadaşlarınızla paylaşabilir ve onları kendi testlerini yapmaya davet edebilirsiniz. Verdiğiniz cevaplara dayanarak, temizlik puanınız hakkında detaylı bir rapor alacaksınız.",
  "Puanınızı Çözme",
  "Testi tamamladığınızda ve puanınızı aldığınızda, bir an durun ve düşünün. Unutmayın, bu sayı karakterinizin veya deneyimlerinizin kapsamlı bir ölçüsü değil. Sadece nereden geldiğiniz ve ne yaptığınız hakkında eğlenceli, içe dönük bir bakış. Herkesin yolculuğu benzersizdir; bu sadece sizin yolculuğunuzun bir kısmını görmenin bir yoludur.",
  "Yeniden Test İçin Doğru Zaman",
  "Testi tekrar etmeli misiniz? Bir tekrar için belirlenmiş bir zaman çizelgesi yoktur. Puanınızın nasıl değiştiğini görmek, büyüdüğünüzü ve yeni deneyimler yaşadığınızı görmek ilginç olabilir. Bazıları yıllık olarak alabilirken, diğerleri daha uzun süre bekleyebilir. Yeni bir bakış açısı veya yeni bakış açıları sunabileceğini hissettiğinizde her şey hakkındadır.",
  "Rice Purity Test, yaşam deneyimlerinizin hafif bir keşfi olabilir. Merakla yaklaşın, duygusal sağlığınıza dikkat edin ve gizliliğinizi saygıyla karşılayın. Puanınız sizi şaşırtırsa veya zaten bildiğiniz şeyi doğrulamışsa, bu, öz-keşif için bir başlangıç noktası olmasına izin verin, kesin bir yargı olmasın. Ve unutmayın, hayat, herhangi bir kontrol listesinin yakalayabileceğinden çok daha karmaşık ve güzeldir.",
  "Testi Dikkatle Almak",
  "Rice Purity Testine dalarken eğlenceli olabilir, ancak bunu bir dikkatle yaklaşmak önemlidir. Sorumlu ve saygılı tutmanın yolları şunlardır:",
  "Unutmayın, sadece eğlence içindir.",
  "Bu, kimseyi yargılamak veya rahatsız hissettirmekle ilgili değildir. Kendinizle biraz yüzleşme ve hayatın vahşi yolculuğuna küçük bir bakış açısı sunar.",
  "Bağlam önemlidir.",
  "Bazı sorular hassas konuları gündeme getirir. Kişisel olduğunu veya hoş olmayan bir anı tetiklediğini hissettiğiniz herhangi bir şeyi atlamak tamamen iyidir.",
  "Gizlilik en önemlisidir.",
  "Puanınız sizin işinizdir. Paylaşabilir veya paylaşmayabilirsiniz - bu tamamen size bağlıdır. Ve eğer paylaşırsanız, herkesin bununla ilgili olduğundan emin olun.",
  "Arka Plan: Nasıl Başladı",
  "Hikayemiz Houston, Teksas'ın kalbinde bulunan Rice Üniversitesi'nde başlar. Resmedin: erken 1920'lerin yılı, bir flapper ve caz zamanı. Bir grup öğrenci, akademik hayatın zorluklarından bir mola aramak veya sadece daha derin bağlar kurmak için, 100 soruluk bir liste oluşturur. Bu sadece herkesin kişisel yaşamları ve deneyimleri hakkında masum sorular ve daha cesur soruların bir karışımıydı. Orijinal niyetin, yeni birinci sınıfların ne kadar 'masumiyetlerine' deneyimli üst sınıflara karşı ölçülebileceğine kısmen yardımcı olmak olduğu söylenir.",
  "Yıllar İçindeki Evrim",
  "Düşünebileceğiniz gibi, o günlerden bu yana birçok şey değişti. Rice Purity Testi dönüştü, güncellendi ve Rice Üniversitesi'nin kutsal salonlarından ötesine yayıldığı için yeni formlar aldı. Elinden ele geçen kağıt kopyalarından internet çağındaki modern haline, dünyanın dört bir yanındaki meraklı akıllar için erişilebilir hale geldi. Özünün - deneyimlerin bir kontrol listesi - aynı kaldı, ancak sorular, değişen toplumsal normları ve tutumları yansıtmak için evrildi.",
  "Puanınızı Çözme",
  "Yani, daldınız ve testi tamamladınız. Şimdi, 0 ile 100 arasında bir sayıya bakıyorsunuz, 'Bu ne anlama geliyor?' diye merak ediyorsunuz. İşte kısa bir rehber:",
  "100-90:",
  "Deneyimlerin daha dikkatli tarafındasınız. Belki de sığınak bir hayat yaşadınız veya maceralarınızı seçici bir şekilde seçtiniz",
  "89-70:",
  "Birkaç gölete ayaklarınızı soktunuz, diyebiliriz. Burada bir masumiyet ve deneyim karışımı var.",
  "69-50:",
  "Orta yol. Maceralarınızı yaşadınız ve muhtemelen ilginç hikayelere sahipsiniz.",
  "49-30:",
  "Hayatın daha vahşi tarafına yabancı değilsiniz. Muhtemelen keşfetme ve sınırları zorlama eğiliminiz var.",
  "29-0:",
  "Çok yaşadınız. Puanınız birçok deneyimi ve muhtemelen kaşları kaldırabilecek bazı hikayeleri işaret ediyor.",
  "Rice Purity Testi & Kullanımı",
  "Ah, Rice Purity Testi - sadece bir dizi sorudan ibaret bir kontrol listesi olmayan meraklı bir macera. Bu ilginç testin nasıl sihir dokuyabileceğini keşfedin, ne dersiniz?",
  "Bağlantılar Kurma",
  "Arkadaşlar arasında veya Rice Purity Testi aracılığıyla tanıştığınız yeni insanlar arasında hikayeleri ve sırları çözmenizi hayal edin. Birbirinizin yaşam maceralarının bir kitabını açmak gibidir. Bazı hikayeler sizi yakınlaştırabilir, ortak deneyimlerin ipliklerini bulma, belki de sizi şaşırtabilir, varlığını bilmediğiniz katmanları ortaya çıkarabilir. Yolculuklarınızın çeşitliliğini keşfetme veya ortak deneyimlerinize dokunma, ilişkilerinizin dokusunu daha sıkı örer.",
  "Güldürmenin Keyfi",
  "Rice Purity Testi, saf eğlencenin bir aracıdır. Her işaretlenen soru, kahkahaya ve hafif mizahi tartışmalara davetiye çıkarır, sıradan bir akşamı gülme hazineleri deposuna dönüştürür. 'Sen ne yaptın?!' diyerek başlayan ve coşkulu kahkahalarla devam eden anlar hakkındadır. Hayatın çok renkli tonlarına oynanan bir oyun, mizah fırçasıyla boyanmıştır.",
  "Nostaljik Bir Yolculuk",
  "Bir zamanlar geçmişin tadını mı özlüyorsunuz? Rice Purity Testi, zihninizin köşelerinde uzun zamandır yuvalanmış anıların her biri için bir zaman makinesidir. Bu ilklerin, heyecanların, kaçışların ve bazen masum yaramazlıkların yeniden yaşanmasını içerir. Bu sadece bir test değil; sizi şekillendiren günlerin geri dönüşüne bir yolculuktur.",
  "Rehberlik İçin Bir Pusula",
  "Rehberlik rollerinde olanlar için - ebeveynler, eğitimciler, danışmanlar - Rice Purity Test, rehberliğinizdeki insanların dünya görüşlerine ve deneyimlerine benzersiz bir bakış açısı sunar. Bu yargılama değil, anlama hakkında. Önemli konuşmalara başlama noktasıdır, büyüme, seçimler ve zorlukların karmaşıklıklarından geçmek için yardımcı olur.",
  "Bir Bilgi Deposu",
  "Toplumun büyük dokusunda, Rice Purity Testi bir neslin kolektif deneyimlerine dair ilginç bir veri koleksiyonu olarak hizmet eder. Araştırmacılar ve analistler için trendlerin ve desenlerin bir altını, zaman içinde insan davranışlarının anlayışlarını, politikaları ve anlayışlarını bilgilendiren, bir kültürel ruh halinin anlık görüntüsüdür.",
  "Kendi Kendinin Aynası",
  "Son olarak, Rice Purity Testi bir iç gözlem anıdır. Durup, düşünme ve daha önce düşünmediğiniz yolculuğunuzun yönlerini keşfetme şansıdır. Nereden geldiğinizi, nerede durduğunuzu anlama ve keşfedilmemiş yolları düşünme hakkında. Sadece puanları saymakla kalmaz, hayatın mozaikinin stokunu alır, bütüne katkıda bulunan her parçayı takdir edersiniz.",
  "Yani, Rice Purity Testi sadece bir kontrol listesi değil, eğlence, bağlantı anı veya kendi kendine bir dalış için bir araçtır. Deneyimlerin kutlaması, sohbetin katalizörü ve düşünce için bir araçtır. Dalın ve ne tür keşiflerin ve zevklerin sizi beklediğini kim bilebilir?",
  "Rice Purity Testi Puan İstatistik Raporu",
  "Çeşitli Rice Purity Test veya bağımsız kuruluşlar tarafından kapsanan birçok istatistik vardır. Rice Purity Testi'ni de araştırdık ve bulduğumuz istatistiksel rapor şu şekildedir:",
  "Puan Aralığı",
  "Katılımcı Sayısı",
  "Toplam Yüzde",
  "Dikkat Edilmesi Gereken Bir Söz",
  "Rice Purity Testi bir tanı aracı değildir. Yargılamak, ahlaki değer atamak veya insanları düzenli küçük kutulara sıralamak için değil. Puanınız sizi tanımlamaz; bu, hayatınızın bu noktasına kadar (veya olmadığına dair) deneyimlerin bir anlık görüntüsüdür. Test, bağlamı, duyguları veya bireysel yolculukların karmaşıklığını hesaba katmaz.",
  "Rice Purity Testi, dijital çağda hayatta kalan ve adapte olan eski bir kalıntıdır. Kişisel deneyimler üzerinde düşünmek için eğlenceli bir yol sunar. Geriye bakarken bir gülümseme, bir çekme veya bir merak duygusu ile, hayatın sadece yolculuk olduğunu hatırlayın.",
  "SSS",
  "1. Rice Purity Testi nedir?",
  "Cevap:",
  "Rice Purity Testi, bir kişinin dünyevi meselelerdeki masumiyet seviyesini değerlendiren kendinden derecelendirilen bir ankettir. Başlıca olarak romantik deneyimler, kural ihlalleri ve çeşitli yaşam deneyimlerine odaklanır. Test 100 sorudan oluşur ve eylemlerinize bağlı olarak 'evet' veya 'hayır' yanıtları verirsiniz.",
  "2. Neden Rice Purity Testi olarak adlandırılıyor?",
  "Test, Houston, Teksas'taki Rice Üniversitesi'nde öğrencilerin kolej hayatlarında olgunlaşmalarını ölçmek için bir yol olarak ortaya çıktı. O zamandan beri çevrimiçi olarak popüler hale gelerek insanların deneyimlerini değerlendirmeleri ve karşılaştırmaları için bir yol haline geldi.",
  "3. Rice Purity Testi'ni kim almalı?",
  "Test genellikle gençler ve genç yetişkinler tarafından deneyimlerini akranlarıyla karşılaştırmak için alınır. Ancak, testi bir merak zihniyetiyle bir yargıdan ziyade eğlence amacıyla ele almak ve kişiliğinizi veya değerinizi ölçmek için bir ölçü olmadığını anlamak önemlidir.",
  "4. Rice Purity Testi ne kadar doğru?",
  "Test bilimsel bir ölçü değildir ve masumiyet veya deneyim konusunda kesin bir değerlendirme olarak alınmamalıdır. Daha çok bir konuşma aracı veya kendi yaşam deneyimleriniz üzerine düşünme yoludur.",
  "5. Puanım nasıl hesaplanır?",
  "Puanınız verdiğiniz 'evet' ve 'hayır' yanıtlarına dayanarak hesaplanır. Puanınız 0 ile 100 arasında değişir ve daha yüksek bir puan, sorulan sorularla ilgili deneyimlerin daha az olduğunu gösterir. Ağırlıklı bir şekilde bir soru üzerinde ağırlıklandırma olmaksızın, doğrudan bir sayım yapılır.",
  "6. Rice Purity Testi puanım ne anlama geliyor?",
  "Puanınız, deneyimlerinizin bir göstergesidir ve ne kadar 'masum' veya deneyimli olduğunuz hakkında bir tahmindir. Ancak, bu sadece bir ölçüdür ve kişiliğinizi veya değerinizi belirlemez. Yalnızca testi aldığınız ve sonuçlarınızı değerlendirdiğiniz kişisel bir anlam ifade edebilir.",
  "7. Rice Purity Testi sonuçları anonim midir?",
  "Evet, Rice Purity Testi genellikle anonim olarak alınır ve sonuçlarınızı paylaşmak tamamen size bağlıdır. Ancak, çevrimiçi platformlarda paylaşıyorsanız, gizlilik ayarlarınıza dikkat etmek önemlidir.",
  "8. Puanımı ne yapmalıyım?",
  "Puan sadece eğlence için bir sayıdır. Kendi kendine düşünme veya arkadaşlarla tartışma fırsatı sunar, ancak çok ciddiye alınmamalıdır. Karakterin bir yargısı veya deneyimler için bir ölçü değildir.",
  "9. Testin farklı versiyonları var mı?",
  "Evet, farklı kitlelere yönelik birkaç test versiyonu bulunmaktadır, bazıları daha çok üniversite deneyimlerine odaklanırken diğerleri genel yaşam deneyimlerine odaklanır. Sizin için en uygun olanı seçtiğinizden emin olun.",
  "10. Rice Purity Testi'ni nereden alabilirim?",
  "Test, çeşitli web sitelerinde ve platformlarda mevcuttur. 'Rice Purity Test' için hızlı bir internet araması sizi çevrimiçi olarak alabileceğiniz birkaç test versiyonuna yönlendirmelidir.",
];

const bannerContentkor: ContentArray = [
  "라이스 순수도 테스트는 규칙을 어기거나 성적 활동 및 기타 성숙한 주제와 관련된 다양한 경험을 다루는 100개의 질문 목록입니다. 이것은 라이스 대학교의 학생들이 사람의 삶의 경험을 측정하기 위해 만들었습니다. 점수가 낮을수록(100점 만점), 더 많은 경험을 한 것입니다. 궁금한가요? 귀하의 순수도 점수가 무엇인지 알아볼까요.",
  "그래서 라이스 순수도 테스트란 무엇입니까?",
  "다양한 경험에 대한 100가지 질문을 담은 체크리스트를 상상해보십시오. 규칙을 어기는 행위, 로맨틱한 모험 및 다른 성인의 경험을 생각해보십시오. (이름에서 유래하여) 이 테스트는 라이스 대학교 학생들(따라서 이름에서 유래한 것)에 의해 만들어졌으며 이 테스트는 '나는 언제...?'라는 개인적인 주제의 목록입니다.",
  "기억의 작은 산책",
  "라이스 순수도 테스트는 상당한 시간 동안 존재해 왔습니다. 이것은 라이스 대학교 학생들이 학문적인 것들 이외의 삶의 경험을 얼마나 많이 했는지를 측정하는 재미있는 방법으로 시작되었습니다. '20년대(정확히는 1920년대)에 학생들이 정답에 대해 미소 지으며 대화하고 있다고 상상해보십시오. 시간이 지나면서 이것은 거기 있는 학생들과 그 이상의 사람들 사이에서 괴상한 비공식적인 전통이 되었습니다.",
  "목적이 무엇입니까?",
  "이 테스트를 왜 실시해야 할까요? 잘 선택된 상자에 체크하는 것 이상입니다. 많은 사람들에게는 이것은 가벼운 자기 반성과 융합을 위한 도구입니다. 이것은 당신의 경험을 거울에 들어보는 것과 같습니다. 그것들이 어떻게 쌓여서 삶의 큰 도약에서 어떻게 쌓이는지 보는 것입니다. 게다가 이것은 대화 시작 요인입니다. 결과를 공유하면 얼음을 깨고 친구들 사이에 깊은 연결을 유도 할 수 있습니다. 모든 것이 재미있게 잘됩니다.",
  "시작하기 전에",
  "당신의 감정적 GPS",
  "시작하기 전에 스스로 체크인하는 시간을 가집니다. 테스트는 강한 감정이나 기억을 유발할 수 있는 많은 주제를 다룹니다. 쉬는 시간을 가져도 좋고, 질문을 건너 뛰거나, 이게 나에게 어울리지 않는지를 결정하는 것은 완전히 괜찮습니다. 귀하의 안녕이 최우선 과제입니다.",
  "익명의 망토",
  "테스트는 익명으로 실시되도록 설계되었습니다. 결과를 공유할 수 있으며 그것을하는 경우에도 공유는 신뢰하는 원형 내부에 최상으로 유지되어야합니다. 온라인에서 답변을 제출 할 때 안전하고 개인 정보 보호가 보장되는 연결을 사용하십시오.",
  "테스트 자체",
  "시작할 준비가 되었습니까? 많은 웹 사이트가 사용자가이 테스트를 수행 할 수 있도록합니다. 그러나 우리는 프로세스를 더 간단하고 흥미롭게 만듭니다. 당사 웹 사이트를 통해 테스트를 수행 할뿐만 아니라 결과를 소셜 미디어에 공유하고 친구들에게 자신의 테스트를 수행하도록 초대 할 수도 있습니다. 제공하는 답변에 기초하여 순수도 점수에 대한 자세한 보고서를받게됩니다.",
  "당신의 점수 해석",
  "테스트를 완료하고 점수를 받았다면 잠시 동안 생각해보십시오. 기억하세요. 이 숫자는 당신의 성격이나 경험의 완전한 측정이 아닙니다. 그것은 당신이 어디를 걸어 왔는지와 무엇을 했는지에 대한 유쾌한, 자기 반성적인 시선입니다. 모든 여행은 유니크하며, 이것은 여러분의 일부를 엿볼 수있는 한 가지 방법일 뿐입니다.",
  "재테이크를위한 적절한 시기",
  "테스트를 다시 살펴 보아야 할까요? 재테이크를위한 특정 시간표는 없습니다. 새로운 경험을 얻으면서 점수가 어떻게 변하는지 보는 것이 흥미로울 수 있습니다. 일부 사람들은 매년 이상한 전통으로 그것을하며 다른 사람들은 더 오래 기다릴 수 있습니다. 새로운 통찰력이나 여행에 대한 새로운 시각을 제공 할 때까지입니다.",
  "라이스 순수도 테스트를 수행하는 것은 삶의 경험을 유쾌하게 탐색 할 수 있습니다. 호기심을 가지고 접근하고 감정적 건강을 돌보고 개인 정보를 존중하십시오. 당신의 점수가 당신을 놀라게하거나 이미 알고 있던 것을 확인하더라도 자체 발견의 시작점으로 삼으십시오. 점검하십시오. 생각보다도 체크리스트가 캡처 할 수있는 것보다 삶은 무한히 더 복잡하고 아름답습니다.",
  "주의해서 테스트를 수행하기",
  "라이스 순수도 테스트를 시작하는 것은 재미있을 수 있지만 조심스럽게 접근하는 것이 중요합니다. 책임감 있고 예의바르게 유지하는 방법은 다음과 같습니다.",
  "잊지 마세요, 그냥 재미있는 거예요.",
  "이것은 판단이나 누군가가 불편하게 느끼도록하는 것이 아닙니다. 당신의 삶의 모험에 대한 반사이자 즐거움의 기회입니다.",
  "문맥이 중요합니다.",
  "일부 질문은 민감한 주제를 제기합니다. 너무 개인적이거나 좋지 않은 기억을 유발하는 것은 건너 뛰는 것이 괜찮습니다.",
  "개인 정보 보호가 중요합니다.",
  "당신의 점수는 당신의 사업입니다. 그것을 공유하거나 하지 않을 수 있습니다. 공유하려면 모두가 편안하다는 환경에서 유지하는 것이 좋습니다.",
  "배경 이야기: 모든 것이 시작된 곳",
  "우리 이야기는 텍사스 주 휴스턴에 위치한 라이스 대학교에서 시작됩니다. 그림을 그려보십시오: 시간은 조던과 재즈 시대입니다. 일부 학생들은 학문적인 삶의 엄격함에서 벗어나거나 깊은 연결을 형성하려는 것이 아니라 그룹을 형성합니다. 100개의 질문 목록을 작성했습니다. 이것은 무엇보다도 더 성숙한 학생들과 비교하여 그들의 순수성의 수준을 측정하는 방법이었습니다.",
  "시대를 통해 진화",
  "상상할 수 있듯이 그 이래 많은 변화가 있었습니다. 라이스 순수도 테스트는 교체되었으며 새로운 형태로 존재하는 동안 라이스 대학교의 거룩한 복도를 벗어나 전 세계의 호기심 있는 마음에 열려있습니다. 그 본질 - 경험의 체크리스트 -은 그대로 유지되었지만 질문은 사회적 관습과 태도의 변화를 반영하도록 변화되었습니다.",
  "점수 해석하기",
  "그래서 당신은 테스트를 완료하고 점수를 받았습니다. 이제 0에서 100 사이의 숫자를 바라보면서 '이게 무엇을 의미합니까?' 라는 생각을 할 것입니다. 여기 간단한 가이드입니다.",
  "100-90:",
  "당신은 경험을 조심스럽게 하는 편입니다. 아마도 당신은 안전한 삶을 살았거나 단지 모험을 선택적으로 즐겼을 수 있습니다.",
  "89-70:",
  "말썽은 좀 피우지만 몇 개의 웅덩이에 발을 담그는 편입니다. 여기에는 순수성과 경험의 혼합이 있습니다.",
  "69-50:",
  "중간 지점. 여러분은 모험을 충분히 즐겼고 아마도 몇 가지 흥미로운 이야기를 가지고 있습니다.",
  "49-30:",
  "삶의 야생 쪽에 낯선 사람은 아닙니다. 당신은 탐험과 경계를 넓히는 것을 좋아하는 경향이 있습니다.",
  "29-0:",
  "당신은 많이 살았습니다. 당신의 점수는 많은 경험을 나타내며 아마도 눈썹을 찌르는 이야기가 있을 것입니다.",
  "라이스 순수도 테스트 & 사용 방법",
  "아, 라이스 순수도 테스트 - 질문의 시리즈 이상인 체크리스트를 통한 호기심의 여행입니다. 이것은 웃음, 융합 및 때로는 기억 속으로의 산책을 통한 매력적인 테스트입니다. 이것이 어떻게 이 신기한 테스트가 당신의 삶에 마법을 불어넣을 수 있는지 탐색해 봅시다.",
  "연결 형성하기",
  "친구들 사이에서 이야기와 비밀을 풀어낼 수 있습니까? 또는 라이스 순수도 테스트를 통해 새로운 사람들을 만날 수 있습니까? 서로의 삶의 모험을 열어보는 책을 열고 또는 각자의 이야기를 끄집어내는 것입니다. 어떤 이야기는 당신을 가까이하고, 유대감을 느끼게 할 수 있으며, 다른 이야기는 당신을 놀라게 할 수 있으며, 당신이 존재하는 계층을 드러낼 수 있습니다. 이것은 서로의 여행에서 공통적인 경험의 실로를 찾거나 여행의 다양성을 발견하는 것입니다.",
  "웃음의 기쁨",
  "그것은 본질적으로 순수한 에브리먼트의 운송수단입니다. 각 질문이 웃음과 경쾌한 수다를 초래하여 보통의 저녁을 놀라운 웃음의 보물 상자로 바꿉니다. 그것은 '당신이 뭐라고 했습니까?!'라고 말한 순간입니다. 그 후에 이어지는 아주 큰 웃음입니다. 그것은 유머의 붓으로 그려진 인생의 무수한 색조로 인한 게임입니다.",
  "향수의 항해",
  "당신이 과거의 맛을 보고 싶다면? 라이스 순수도 테스트는 당신의 시간 여행기입니다. 각 질문마다 당신 마음 속의 오랫동안 잠들어있는 기억의 창문입니다. 이것은 그 첫 경험들, 스릴, 모험, 때로는 순진한 장난의 순간을 다시 살아보는 것입니다. 이것은 그냥 테스트가 아니라 당신을 형성한 날들로의 여정입니다.",
  "가이드로의 나침반",
  "가이드 역할을 하는 사람들 - 부모, 교육자, 상담가 등 - 라이스 순수도 테스트는 당신이 안내하는 사람들의 세계관과 경험에 대한 독특한 렌즈를 제공합니다. 판단이 아니라 이해에 관한 것입니다. 이것은 중요한 대화를 시작하는 지점입니다. 성장, 선택 및 도전의 복잡성을 넘어서는 데 도움이 되는 것입니다.",
  "통찰력의 저장소",
  "사회의 거대한 테이프스트리 속에서 라이스 순수도 테스트는 세대의 집단적인 경험을 살펴볼 수 있는 매력적인 데이터 수집입니다. 연구원과 분석가들을위한 귀중한 도전과 패턴의 보고서입니다. 시간이 지남에 따른 인간 행동의 이해를 정책 및 이해에 도움이되는 문화적 풍조의 스냅 샷입니다.",
  "자기 반성의 거울",
  "마지막으로, 라이스 순수도 테스트는 내적 고백입니다. 멈추고, 반성하고, 고려하지 않았던 여정의 측면을 발견할 기회입니다. 당신이 어디에 있었는지, 어디에 서있는지 이해하고, 탐험해야 할 길을 곰곰이 생각하는 것입니다. 이것은 단지 점수를 계산하는 것이 아니라 삶의 모자이크를 재고하는 것입니다. 전체를 이루는 각 부분을 고려하는 것입니다.",
  "그래서 라이스 순수도 테스트는 단순히 체크리스트가 아닙니다. 웃음, 연결 또는 자아의 심도에 대한 것입니다. 경험을 축하하고, 대화를 촉진하며, 반영에 대한 도구입니다. 다이빙하여 무슨 깨달음과 기쁨이 기다리고 있는지 알 수 없습니다.",
  "라이스 순수도 테스트 점수 통계 보고서",
  "다양한 라이스 순수도 테스트 또는 독립 기관에서 다룬 많은 통계가 있습니다. 우리는 라이스 순수도 테스트를 조사했으며 여기 우리가 발견한 통계 보고서가 있습니다:",
  "점수 범위",
  "응답자 수",
  "총 백분율",
  "주의할 점",
  "라이스 순수도 테스트는 진단 도구가 아닙니다. 판단을 내릴 목적이나 도덕적 가치를 부여하거나 사람들을 깔끔한 작은 상자로 분류 할 목적이 아닙니다. 당신의 점수가 당신을 정의하지 않습니다. 당신의 삶의 경험 (또는 경험하지 않은)의 이점에 대한 단지 하나의 촬영입니다. 이 테스트는 맥락, 감정 또는 개별적인 여정의 복잡성을 고려하지 않습니다.",
  "라이스 순수도 테스트는 시대를 초월한 유물로서 디지털 시대에서 적응하고 살아남았습니다. 개인적인 경험에 대한 반성의 방법으로 재미있게 제공됩니다. 당신이 웃으면서, 안쪽에서 소름이 돋고, 호기심에 가득 찬지 여부를 고려하면서, 삶은 단지 길목이 아니라 여정에 대한 것입니다.",
  "FAQ",
  "1. 라이스 순수도 테스트란 무엇입니까?",
  "답변:",
  "라이스 순수도 테스트는 세계적인 문제에 대한 순수성 수준을 평가하는 자가 평가 방식입니다. 주로 로맨틱한 경험, 규칙 위반 행동 및 다양한 생활 경험에 중점을 둡니다. 테스트는 100개의 질문으로 구성되며 당신의 행동에 따라 '예' 또는 '아니오'로 대답합니다.",
  "2. 왜 라이스 순수도 테스트라고 하나요?",
  "테스트는 텍사스주 휴스턴의 라이스 대학교에서 대학 생활 중에 성장을 측정하기 위한 학생들의 방법으로 시작되었습니다. 그 후 인터넷에서 사람들이 자신의 경험을 평가하고 비교하기 위한 방법으로 인기를 얻었습니다.",
  "3. 누가 라이스 순수도 테스트를 수행해야합니까?",
  "이 테스트는 대개 십대와 청소년이 동료와의 경험을 평가하기 위해 수행됩니다. 그러나 호기심의 마음가짐으로 테스트에 접근하는 것이 중요하며, 캐릭터나 가치를 측정하는 것이 아니라 재미로 취급되며 단지 즐거움을 위한 것이라는 것을 이해해야 합니다.",
  "4. 라이스 순수도 테스트는 정확합니까?",
  "테스트는 과학적 측정 도구가 아니며 순수성 또는 경험의 확정적인 평가로 간주되어서는 안됩니다. 대화의 도구이거나 자신의 삶의 경험에 대한 반성의 방법입니다.",
  "5. 내 점수는 어떻게 계산되나요?",
  "당신의 점수는 당신이 제공하는 '예'와 '아니오' 답변의 수에 따라 계산됩니다. 점수는 0에서 100까지이며 높은 점수는 테스트에서 물어보는 질문과 관련된 경험이 적음을 나타냅니다. 어떤 질문에도 가중치가 부여되지 않은 직접적인 합계입니다.",
  "6. 테스트를 여러 번 볼 수 있습니까?",
  "예, 여러 번 테스트를 수행할 수 있습니다. 일부 사람들은 삶의 경험이 더 많아지면서 점수가 어떻게 변하는지 확인하기 위해 시간이 지남에 따라 테스트를 재실시하기도 합니다.",
  "7. 나의 라이스 순수도 테스트 점수는 개인 정보로 보호되나요?",
  "예, 점수는 개인 정보입니다. 테스트는 개인 식별 정보를 요구하지 않으며 점수를 공유할지 여부는 전적으로 당신에게 달려 있습니다.",
  "8. 내 점수를 어떻게 해야합니까?",
  "점수는 단순한 엔터테인먼트를 위한 숫자입니다. 자아 반성이나 친구와의 토론의 기회이지만 너무 진지하게 생각해서는 안 됩니다. 이것은 캐릭터의 판단이나 경험의 기준이 아닙니다.",
  "9. 테스트의 다른 버전이 있습니까?",
  "네, 다양한 대상을위한 여러 버전의 테스트가 있습니다. 대학 경험에 중점을 둔 버전과 삶의 일반적인 경험에 중점을 둔 버전이 있습니다. 가장 적합한 것으로 생각하는 버전을 선택하십시오.",
  "10. 어디에서 라이스 순수도 테스트를 볼 수 있습니까?",
  "테스트는 여러 웹 사이트와 플랫폼에서 사용할 수 있습니다. '라이스 순수도 테스트'라는 검색어를 사용하여 인터넷을 빠르게 검색하면 온라인에서 테스트를 수행할 수 있는 여러 버전을 찾을 수 있습니다.",
];

const decodingArrEng: ContentArray = [
  "Exceptionally Pure (90-100)",
  "Notably Pure (89-70)",
  "Moderately Pure (69-50)",
  "Significantly Diminished (49-30)",
  "Significantly Diminished (29-0)",
];
const decodingArrFrench: ContentArray = [
  "Exceptionnellement pur (90-100)",
  "Notamment pur (89-70)",
  "Modérément pur (69-50)",
  "Significativement diminué (49-30)",
  "Significativement diminué (29-0)",
];
const decodingArrGerman: ContentArray = [
  "Außergewöhnlich rein (90-100)",
  "Besonders rein (89-70)",
  "Mäßig rein (69-50)",
  "Erheblich verringert (49-30)",
  "Erheblich verringert (29-0)",
];
const decodingArrItalian: ContentArray = [
  "Eccezionalmente Puro (90-100)",
  "Notabilmente Puro (89-70)",
  "Moderatamente Puro (69-50)",
  "Significativamente Diminuito (49-30)",
  "Significativamente Diminuito (29-0)",
];
const decodingArrPolish: ContentArray = [
  "Wyjątkowo czysty (90–100)",
  "Wyjątkowo czysty (89–70)",
  "Umiarkowanie czysty (69–50)",
  "Znacznie zmniejszony (49–30)",
  "Znacznie zmniejszony (29–0)",
];
const decodingArrKorean: ContentArray = [
  "매우 순수함(90-100)",
  "눈에 띄게 순수함(89-70)",
  "보통 순수함(69-50)",
  "크게 감소함(49-30)",
  "획기적으로 감소함(29-0)",
];
const decodingArrTurkish: ContentArray = [
  "Olağanüstü Derecede Saf (90-100)",
  "Oldukça Saf (89-70)",
  "Orta Derecede Saf (69-50)",
  "Önemli Ölçüde Azaltılmış (49-30)",
  "Önemli Derecede Azaltılmış (29-0)",
];
const decodingArrChinies: ContentArray = [
  "异常纯净 (90-100)",
  "显着纯净 (89-70)",
  "中等纯净 (69-50)",
  "显着减少 (49-30)",
  "显着减少 (29-0)",
];

const metaDataEnglish: ContentArray =[
"Rice Purity Test (Know Your Purity Score)",
"Want to check your purity score? The Rice Purity Test is a self-graded survey originally started at Rice University. It includes 100 questions covering various topics related to romantic experiences, rule-breaking behaviors, and other potentially risqué activities."
]

const metaDataItalian: ContentArray =[
"The Rice Purity Test Ita (conoscere il proprio punteggio di purezza)",
"Il Test di Purezza di Rice è un questionario di 100 domande che valuta il livello di innocenza di una persona in vari aspetti della vita, come esperienze romantiche e comportamenti ribelli. Creato alla Rice University, è ora popolare online per riflettere e confrontare esperienze personali."
]

const Purity = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [isbannerContent, setIsbannerContent] =
    useState<ContentArray>(bannerContenteng);
  const [isListData, setIsListData] = useState<ContentArray>(englishListArray);
  const [startBtnlang, setStartbtnlang] = useState("Start Purity Test");
  const [hidebtn, sethideBtn] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    sethideBtn(true);
  };
  const handleClose = () => setOpen(false);
  const [isStart, setIsStart] = useState(false);
  const [isName, setisname] = useState("");
  const [indexCount, setindexCount] = useState(0);
  const [result, setResult] = useState(0);
  const [row, setRow] = useState(0);
  const [holder, setHolder] = useState(englishHolder);
  const [calculate, setCalculate] = useState(englishCal);
  const [btnY, setbtnY] = useState<ContentArray>(englishbtn);
  const [reStartBtn, setreStartbtn] = useState(englishRestart);
  const [scoreCard, setscoreCard] = useState(englishYourscore);
  const [tablehead, setTablehead] = useState<ContentArray>(englishHead);
  const [scoreLevel, setscoreLevel] = useState<ContentArray>(EnglishLevels);
  const [metaDeta,setMetaData]=useState<ContentArray>(metaDataEnglish)
  const [share, setShare] = useState(englishShare);
  const [isDecodingArr, setIsdecodingArr] =
    useState<ContentArray>(decodingArrEng);

  // const [searchParams, setSearchParams] = useSearchParams();
  // const language = searchParams.get("language");
  
  // const handleLanguageChange = (event: any) => {
  //   setSearchParams({ language: event.target.value });
  // };

  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   const currentLanguage = searchParams.get("language") || "english";
  //   const newUrl = currentUrl.replace( 
  //     `/?language=${currentLanguage}`,
  //     `/${currentLanguage}`
  //   );
  //   window.history.replaceState({}, document.title, newUrl);
  // }, [searchParams]);

  // useEffect(() => {
  //   if (!searchParams.get("language")) {
  //     setSearchParams({ language: "english" });
  //   }
  //   handleLanguage();
  // }, [searchParams, setSearchParams]);


  const handleLanguage = (e:any) => {
    if(e.target.value==="italian"){
      navigate("/italian")
    }

    switch (e.target.value) {
      case "english":
        setIsbannerContent(bannerContenteng);
        setStartbtnlang("Start Purity Test");
        setIsListData(englishListArray);
        setHolder(englishHolder);
        setCalculate(englishCal);
        setbtnY(englishbtn);
        setreStartbtn(englishRestart);
        setscoreCard(englishYourscore);
        setscoreLevel(EnglishLevels);
        setTablehead(englishHead);
        setShare(englishShare);
        setIsdecodingArr(decodingArrEng);
        setMetaData(metaDataEnglish)
        break;
      case "french":
        setIsbannerContent(bannerContentfr);
        setStartbtnlang("Démarrer le test de pureté");
        setIsListData(frenchListArray);
        setHolder(frenchHolder);
        setCalculate(frenchCal);
        setbtnY(frenchbtn);
        setreStartbtn(frenchRestart);
        setscoreCard(frenchYourscore);
        setscoreLevel(FrenchLevels);
        setTablehead(frenchHead);
        setShare(frenchShare);
        setIsdecodingArr(decodingArrFrench);
        setMetaData(metaDataEnglish)
        break;
      case "german":
        setIsbannerContent(bannerContentger);
        setStartbtnlang("Starten Sie den Reinheitstest");
        setIsListData(germanListArray);
        setHolder(germanHolder);
        setCalculate(germanCal);
        setbtnY(germanbtn);
        setreStartbtn(germanRestart);
        setscoreCard(germanYourscore);
        setscoreLevel(GermanLevels);
        setTablehead(germanHead);
        setShare(germanShare);
        setIsdecodingArr(decodingArrGerman);
        setMetaData(metaDataEnglish)
        break;
      case "italian":
        setIsbannerContent(bannerCOntentIta);
        setStartbtnlang("Avvia il test di purezza");
        setIsListData(italianListArray);
        setHolder(italinHolder);
        setCalculate(italianCal);
        setbtnY(italianbtn);
        setreStartbtn(italianRestart);
        setscoreCard(italianYourscore);
        setscoreLevel(ItalianLevels);
        setTablehead(italianHead);
        setShare(italianShare);
        setIsdecodingArr(decodingArrItalian);
        setMetaData(metaDataItalian)
        break;
      case "polish":
        setIsbannerContent(bannerContentpolish);
        setStartbtnlang("Rozpocznij test czystości");
        setIsListData(polishListArray);
        setHolder(polishHolder);
        setCalculate(polishCal);
        setbtnY(polishbtn);
        setreStartbtn(polishRestart);
        setscoreCard(polishYourscore);
        setscoreLevel(PolishLevels);
        setTablehead(polishHead);
        setShare(polishShare);
        setIsdecodingArr(decodingArrPolish);
        setMetaData(metaDataEnglish)
        break;
      case "korean":
        setIsbannerContent(bannerContentkor);
        setStartbtnlang("순도 테스트 시작");
        setIsListData(koreanListArray);
        setHolder(koreanHolder);
        setCalculate(koreanCal);
        setbtnY(koreanbtn);
        setreStartbtn(koreanRestart);
        setscoreCard(koreanYourscore);
        setscoreLevel(KoreanLevels);
        setTablehead(koreanHead);
        setShare(koreanShare);
        setIsdecodingArr(decodingArrKorean);
        setMetaData(metaDataEnglish)
        break;
      case "portuguese":
        setIsbannerContent(portugueseArray);
        setStartbtnlang("Iniciar teste de pureza");
        setIsListData(englishListArray);
        setHolder(portuguseHolder);
        setCalculate(portuguseCal);
        setbtnY(portugusebtn);
        setreStartbtn(portuguseRestart);
        setscoreCard(portuguseYourscore);
        setscoreLevel(EnglishLevels);
        setTablehead(englishHead);
        setShare(portugueseShare);
        setIsdecodingArr(decodingArrEng);
        setMetaData(metaDataEnglish)
        break;
      case "turkish":
        setIsbannerContent(bannerCOntentturkish);
        setStartbtnlang("Saflık Testini Başlat");
        setIsListData(turkishListArray);
        setHolder(turkishHolder);
        setCalculate(turkishCal);
        setbtnY(turkishbtn);
        setreStartbtn(turkishRestart);
        setscoreCard(turkishYourscore);
        setscoreLevel(TurkishLevels);
        setTablehead(turkishHead);
        setShare(turkishShare);
        setIsdecodingArr(decodingArrTurkish);
        setMetaData(metaDataEnglish)
        break;
      case "filipino":
        setIsbannerContent(filipinoArray);
        setStartbtnlang("Simulan ang Purity Test");
        setIsListData(englishListArray);
        setHolder(filipinoHolder);
        setCalculate(filipinoCal);
        setbtnY(filipinobtn);
        setreStartbtn(filipinoRestart);
        setscoreCard(filipinoYourscore);
        setscoreLevel(EnglishLevels);
        setTablehead(englishHead);
        setShare(filipinoShare);
        setMetaData(metaDataEnglish)
        break;
      case "chinies":
        setIsbannerContent(bannerContentchi);
        setStartbtnlang("开始纯度测试");
        setIsListData(chinesListArray);
        setHolder(chiniesHolder);
        setCalculate(chiniesCal);
        setbtnY(chiniesbtn);
        setreStartbtn(chiniesRestart);
        setscoreCard(chiniesYourscore);
        setscoreLevel(ChineseLevels);
        setTablehead(chineseHead);
        setShare(chineseShare);
        setIsdecodingArr(decodingArrChinies);
        setMetaData(metaDataEnglish)
        break;
      default:
        setIsbannerContent(bannerContenteng);
        setStartbtnlang("Start Purity Test");
        setIsListData(englishListArray);
        setHolder(englishHolder);
        setCalculate(englishCal);
        setbtnY(englishbtn);
        setreStartbtn(englishRestart);
        setscoreCard(englishYourscore);
        setscoreLevel(EnglishLevels);
        setTablehead(englishHead);
        setShare(englishShare);
        setIsdecodingArr(decodingArrEng);
        setMetaData(metaDataEnglish)
        break;
    }
  };

  const handlestart = (e: any) => {
    e.preventDefault();
    setIsStart(true);
    // window.scrollTo({
    //   top: 400,
    //   behavior: 'smooth' // Smooth scrolling animation
    // });
  };

  function MyVerticallyCenteredModal(props: ModalProps) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center result d-flex justify-content-center">
            <div>
              {result >= 51 ? (
                <>
                  <div className="text-center ">
                    <h6 className="score-H">{scoreCard}</h6>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src="assets/heart.png"
                        className="img-fluid heartimg"
                      />
                      <span
                        className="scoreCount"
                        style={{
                          position: "absolute",
                          top: "47%",
                          left: "42%",
                          color: "#fff",
                        }}
                      >
                        {result}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {result == 50 ? (
                <>
                  <div className="text-center ">
                    <h6 className="score-H">{scoreCard}</h6>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src="assets/bat.png"
                        className="img-fluid heartimg"
                      />
                      <span
                        className="scoreCount"
                        style={{
                          position: "absolute",
                          top: "34%",
                          left: "42%",
                          color: "#000",
                        }}
                      >
                        {result}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {result < 50 ? (
                <>
                  <div className="text-center ">
                    <h6 className="score-H">{scoreCard}</h6>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src="assets/devil.png"
                        className="img-fluid heartimg"
                      />
                      <span
                        className="scoreCount"
                        style={{
                          position: "absolute",
                          top: "34%",
                          left: "43%",
                          color: "#fff",
                        }}
                      >
                        {result}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <table>
                <tr>
                  <th>{tablehead[0]}</th>
                  <th>{tablehead[1]}</th>
                </tr>
                <tr className={result == 100 ? "highlight" : ""}>
                  <td>{scorePoint[0]}</td>
                  <td>{scoreLevel[0]}</td>
                </tr>
                <tr className={result >= 95 && result < 100 ? "highlight" : ""}>
                  <td>{scorePoint[1]}</td>
                  <td>{scoreLevel[1]}</td>
                </tr>
                <tr className={result >= 90 && result < 95 ? "highlight" : ""}>
                  <td>{scorePoint[2]}</td>
                  <td>{scoreLevel[2]}</td>
                </tr>
                <tr className={result >= 70 && result < 90 ? "highlight" : ""}>
                  <td>{scorePoint[3]}</td>
                  <td>{scoreLevel[3]}</td>
                </tr>
                <tr className={result >= 50 && result < 70 ? "highlight" : ""}>
                  <td>{scorePoint[4]}</td>
                  <td>{scoreLevel[4]}</td>
                </tr>
                <tr className={result >= 20 && result < 50 ? "highlight" : ""}>
                  <td>{scorePoint[5]}</td>
                  <td>{scoreLevel[5]}</td>
                </tr>
                <tr className={result >= 10 && result < 20 ? "highlight" : ""}>
                  <td>{scorePoint[6]}</td>
                  <td>{scoreLevel[6]}</td>
                </tr>
                <tr className={result > 0 && result < 10 ? "highlight" : ""}>
                  <td>{scorePoint[7]}</td>
                  <td>{scoreLevel[7]}</td>
                </tr>
                <tr className={result == 0 ? "highlight" : ""}>
                  <td>{scorePoint[8]}</td>
                  <td>{scoreLevel[8]}</td>
                </tr>
              </table>
              <div
                className="text-center pt-5"
                style={{ backgroundColor: "transparent" }}
              >
                <Button className="restartbtn" onClick={restartfunc}>
                  {reStartBtn}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const handleShareToWhatsApp = () => {
    const message = encodeURIComponent(
      "You want to try how pure you are then its time now to click on the link to try it: https://puritytest.vercel.app/"
    );
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let whatsappUrl = "";
    if (isMobile) {
      // For mobile devices, open WhatsApp using the app URL
      whatsappUrl = `whatsapp://send?text=${message}`;
    } else {
      // For desktop devices, open WhatsApp web URL
      whatsappUrl = `https://web.whatsapp.com/send?text=${message}`;
    }
    // Open WhatsApp URL in a new tab/window
    window.open(whatsappUrl, "_blank");
  };

  //Share to facebook
  const handleShareToFacebook = () => {
    const additionalText =
      "You want to try how pure you are then its time now to click on the link to try it.";
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      "https://puritytest.vercel.app/"
    )}&quote=${encodeURIComponent(additionalText)}`;
    window.open(url, "_blank");
  };

  //Share to twitter
  const handleShareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=You want to try how pure you are then its time now to click on the link to try it: https://puritytest.vercel.app/`;
    window.open(url, "_blank");
  };

  const handleYes = (e: any) => {
    e.preventDefault();
    if (indexCount >= 0 && e.target.value === "Yes" && indexCount < 100) {
      setindexCount(indexCount + 1);
      setRow(row + 1);
    } else {
      alert("Click on Calculate Button");
    }
  };

  const backFunc = (e: any) => {
    e.preventDefault();
    if (indexCount >= 0 && indexCount < 100) {
      setindexCount(indexCount - 1);
      setResult(result - 1);
      setRow(row - 1);
    }
  };

  const handleNo = (e: any) => {
    e.preventDefault();
    if (indexCount >= 0 && e.target.value === "No" && indexCount < 100) {
      setindexCount(indexCount + 1);
      setResult(result + 1);
      setRow(row + 1);
    } else {
      alert("Click on Calculate Button");
    }
  };

  const restartfunc = () => {
    setResult(0);
    window.location.reload();
    handleClose();
  };

  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Show the header when user starts scrolling
      if (window.scrollY > 0) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const navHome = () => {
    navigate("/");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{metaDeta[0]}</title>
        <meta
          name="description"
          content={metaDeta[1]}
        />
      </Helmet>

      <section className="backgroundimage">
        <Container>
          <div className="row justify-content-center">
            <div
              className=" col-xl-12 col-lg-12"
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="bannertext">
                {showHeader && (
                  <h1
                    className="banner-H sticky"
                    style={{ cursor: "pointer" }}
                    onClick={navHome}
                  >
                    The Rice Purity Test
                  </h1>
                )}
                <div className="text-center">
                  <img
                    className="img-fluid"
                    style={{ width: "150px", height: "auto" }}
                    alt="logo"
                    src="/assets/logo.png"
                  />
                </div>
                <h1 className="banner-H" style={{ fontWeight: 600 }}>
                  The Rice Purity Test
                </h1>
                <p className="text-center">{isbannerContent[0]}</p>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  {!isStart ? (
                    <input
                      placeholder={holder}
                      className="inputbox"
                      onChange={(e) => setisname(e.target.value)}
                    />
                  ) : (
                    <span className="userName mb-0">Hello {isName}</span>
                  )}
                  <select
                    name="lang"
                    // value={language || "english"}
                    id="lang"
                    onChange={handleLanguage}
                  >
                    <option value="english" className="p-2">
                      English
                    </option>
                    <option value="french" className="p-2">
                      French
                    </option>
                    <option value="german" className="p-2">
                      German
                    </option>
                    <option value="italian" className="p-2">
                      Italian
                    </option>
                    <option value="polish" className="p-2">
                      Polish
                    </option>
                    <option value="korean" className="p-2">
                      Korean
                    </option>
                    <option value="turkish" className="p-2">
                      Turkish
                    </option>
                    <option value="chinies" className="p-2">
                      Chinies
                    </option>
                  </select>
                </div>
                {!isStart ? (
                  <div className="d-flex justify-content-center align-items-center col-sm-12 mt-3">
                    <button className="startbtn" onClick={handlestart}>
                      {startBtnlang}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {isStart ? (
              <>
                <div
                  style={{
                    width: "60%",
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                  }}
                  className="mt-4"
                >
                  <ProgressBar now={row} />
                  <span
                    style={{
                      position: "absolute",
                      top: "-4px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      whiteSpace: "nowrap",
                    }}
                  >{`${indexCount}/100`}</span>
                </div>
                {indexCount < 100 ? (
                  <div style={{ width: "100%" }} className="mt-4 whitebox">
                    <div className=" d-flex justify-content-center align-items-center">
                      <div>
                        <p className="bottomBox">{isListData[indexCount]}</p>
                        <div className="text-center pb-4">
                          <button
                            className="yesbtn"
                            value="Yes"
                            onClick={handleYes}
                          >
                            {btnY[0]}
                          </button>
                          <button
                            className="nobtn"
                            value="No"
                            onClick={handleNo}
                          >
                            {btnY[1]}
                          </button>
                        </div>
                      </div>
                    </div>
                    {indexCount === 0 ? (
                      ""
                    ) : (
                      <button className="back" onClick={backFunc}>
                        <MdKeyboardDoubleArrowLeft />
                        {btnY[2]}
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{ width: "60%", margin: "auto", textAlign: "center" }}
                  className="pt-5"
                >
                  {indexCount == 100 && !hidebtn ? (
                    <Button
                      onClick={() => setModalShow(true)}
                      className="calculatebtn"
                    >
                      {calculate}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="text-center pt-4 pb-4 mt-3 mb-3">
              <p className="mb-0">{share}</p>
              {/* <WhatsappShareButton url='' title=''><img className='img-fluid m-2 socialmedia' src='assets/whatsapp (1).png' style={{cursor:"pointer"}}/></WhatsappShareButton> */}
              <a onClick={handleShareToWhatsApp}>
                <img
                  className="img-fluid m-2 socialmedia"
                  src="assets/whatsapp (1).png"
                  style={{ cursor: "pointer" }}
                />
              </a>
              <a onClick={handleShareToFacebook}>
                <img
                  className="img-fluid m-2 socialmedia"
                  src="assets/facebook (4).png"
                  style={{ cursor: "pointer" }}
                />
              </a>
              <a onClick={handleShareToTwitter}>
                <img
                  className="img-fluid m-2 socialmedia"
                  src="assets/twitter.png"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
            <div className="text-center">
              <h2 style={{ fontWeight: 600 }}>{isbannerContent[1]}</h2>
              <p>{isbannerContent[2]}</p>
              <h2 style={{ fontWeight: 600 }}>{isbannerContent[3]}</h2>
              <p>{isbannerContent[4]}</p>
              <h2 style={{ fontWeight: 600 }}>{isbannerContent[5]}</h2>
              <p>{isbannerContent[6]}</p>
            </div>
            <div className="p-4">
              <div
                className="row p-4 boxshadow"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[8]}</h3>
                    <p>{isbannerContent[9]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[10]}</h3>
                    <p>{isbannerContent[11]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[12]}</h3>
                    <p>{isbannerContent[13]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[14]}</h3>
                    <p>{isbannerContent[15]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[16]}</h3>
                    <p>{isbannerContent[17]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p
                className="text-center pt-4 pb-4"
                style={{ fontSize: "1.1rem", color: "black" }}
              >
                {isbannerContent[18]}
              </p>
            </div>
            <div className="p-4">
              <div
                className="row p-4 boxshadow"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[19]}</h2>
                  <p>{isbannerContent[20]}</p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[21]}</h3>
                    <p>{isbannerContent[22]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[23]}</h3>
                    <p>{isbannerContent[24]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[25]}</h3>
                    <p>{isbannerContent[26]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-4">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-3">
                <div>
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[27]}</h2>
                  <p>{isbannerContent[28]}</p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 pt-3">
                <div>
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[29]}</h2>
                  <p>{isbannerContent[30]}</p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div
                className="row p-4 boxshadow"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[31]}</h2>
                  <p>{isbannerContent[32]}</p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isDecodingArr[0]}</h3>
                    <p>{isbannerContent[34]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isDecodingArr[1]}</h3>
                    <p>{isbannerContent[36]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isDecodingArr[2]}</h3>
                    <p>{isbannerContent[38]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isDecodingArr[3]}</h3>
                    <p>{isbannerContent[40]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isDecodingArr[4]}</h3>
                    <p>{isbannerContent[42]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div
                className="row p-4 boxshadow"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[43]}</h2>
                  <p>{isbannerContent[44]}</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[45]}</h3>
                    <p>{isbannerContent[46]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[47]}</h3>
                    <p>{isbannerContent[48]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[49]}</h3>
                    <p>{isbannerContent[50]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[51]}</h3>
                    <p>{isbannerContent[52]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[53]}</h3>
                    <p>{isbannerContent[54]}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <h3 style={{ fontWeight: 600 }}>{isbannerContent[55]}</h3>
                    <p>{isbannerContent[56]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[58]}</h2>
                  <p>{isbannerContent[59]}</p>
                  <table>
                    <thead>
                      <tr>
                        <th>{isbannerContent[60]}</th>
                        <th>{isbannerContent[61]}</th>
                        <th>{isbannerContent[62]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0-25</td>
                        <td>1500</td>
                        <td>15%</td>
                      </tr>
                      <tr>
                        <td>26-50</td>
                        <td>2200</td>
                        <td>22%</td>
                      </tr>
                      <tr>
                        <td>51-75</td>
                        <td>2800</td>
                        <td>28%</td>
                      </tr>
                      <tr>
                        <td>76-100</td>
                        <td>3500</td>
                        <td>35%</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>10000</td>
                        <td>100%</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2 className="pt-4" style={{ fontWeight: 600 }}>
                    {isbannerContent[63]}
                  </h2>
                  <p>{isbannerContent[64]}</p>
                  <p>{isbannerContent[65]}</p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <h2 style={{ fontWeight: 600 }}>{isbannerContent[66]}</h2>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <h4>{isbannerContent[67]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[69]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <h4>{isbannerContent[70]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[71]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <h4>{isbannerContent[72]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[73]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <h4>{isbannerContent[74]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[75]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <h4>{isbannerContent[76]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[77]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <h4>{isbannerContent[78]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[79]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <h4>{isbannerContent[80]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[81]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <h4>{isbannerContent[82]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[83]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <h4>{isbannerContent[84]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[85]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        <h4>{isbannerContent[86]}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {isbannerContent[68]}
                          </span>
                          {isbannerContent[87]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <img
              className="img-fluid devilwing p-0"
              src="assets/devilwing.png"
            />
            <img
              className="img-fluid angelwind p-0"
              src="assets/angelwing.png"
            />
          </div>
          <MyVerticallyCenteredModal
            show={modalShow}
            // onHide={() => setModalShow(false)}
          />
        </Container>
      </section>
    </>
  );
};

export default Purity;
