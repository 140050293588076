import React from 'react'
import { useNavigate } from 'react-router-dom';


const Footer = () => {

    const navigate=useNavigate()

    const handleTC=()=>{
        navigate('/terms-conditions')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const hanldePrivacypolicy=()=>{
        navigate('/privacy-policy')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const handleCookies=()=>{
        navigate('/cookies-policy')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const handleDisclaimer=()=>{
        navigate('/disclaimer')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const handleFairuse=()=>{
        navigate('/fair-use-policy')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const handleDataPolicy=()=>{
        navigate('/data-collection-policy')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
 
    return (
        <>
            <section>
                <div className='row m-0 text-center'>
                    <div>
                        <h2 className='pt-3 pb-3' style={{fontWeight:600}}>Disclaimer</h2>
                        <p className='pb-3'>This website is not the Official website of Rice university or in any way related to it, This website is an independent entity and is not affiliated with anyone.</p>
                    </div>
                    <div>
                        <ul className='ULlist'>
                            <li className='mx-2 list' onClick={handleTC}>Terms and Conditions</li>
                            <li className='mx-2 list' onClick={hanldePrivacypolicy}>Privacy Policy</li>
                            <li className='mx-2 list' onClick={handleCookies}>Cookies Policy</li>
                            <li className='mx-2 list' onClick={handleDisclaimer}>Disclaimer</li>
                            <li className='mx-2 list' onClick={handleFairuse}>Fair Use Policy</li>
                            <li className='mx-2 list' onClick={handleDataPolicy}>Data Collection Policy</li>
                        </ul>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <p>© 2024 <strong>The Rice Purity Test</strong> . All Rights Reserved.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer
