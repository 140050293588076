import React from "react";
import Purity from "../PurityMain/Purity";
import Footer from "../PurityMain/Footer";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title> Rice Purity Test (Know Your Purity Score) </title>
        <meta
          name="description"
          content=" Want to check your purity score? The Rice Purity Test is a self-graded
survey originally started at Rice University. It includes 100 questions covering various topics
related to romantic experiences, rule-breaking behaviors, and other potentially risqué
activities."
        />
      </Helmet>
      <Purity />
      <Footer />
    </>
  );
};

export default Home;
