import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';


const FairUsePolicy = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
         <Helmet>
        <title> Fair Use Policy </title>
        <meta name="description" content="This Fair Use Policy is designed to clarify our use of certain terms and
intellectual property, particularly the term 'Rice,' which is associated with Rice University.
Our goal is to inform you of our practices and guide you on how to use our content within the
bounds of fair use law." />
      </Helmet>
            <section className='pt-4 pb-4'>
                <Container>
                <div>
                    <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Our Fair Use Policy</span>
                </div>
                <div>
                    <h1>Our Fair Use Policy</h1>
                    <p>Welcome to the Rice Purity Test website. This Fair Use Policy is designed to clarify our use of certain terms and intellectual property, particularly the term "Rice," which is associated with Rice University. Our goal is to inform you of our practices and guide you on how to use our content within the bounds of fair use law.</p>
                    <h2>Understanding Fair Use</h2>
                    <p>Fair use is a legal doctrine that permits limited use of copyrighted material without requiring permission from the rights holders. It includes considerations such as the purpose and character of the use, the nature of the copyrighted work, the amount and substantiality of the portion used, and the effect of the use on the potential market for or value of the copyrighted work.</p>
                    <h2>Use of the Term "Rice"</h2>
                    <li className='pb-2'><strong>Contextual Use:</strong>We use the term "Rice" in relation to the Rice Purity Test for descriptive and informational purposes. This usage is intended to reference the historical context of the test, which is popularly associated with Rice University traditions.</li>
                    <li className='pb-2'><strong>Compliance with Fair Use:</strong>We carefully use the term to ensure it does not infringe on Rice University's trademarks or create any market confusion. Our use is non-commercial and intended purely for educational and informational purposes.</li>
                    <h2>Guidelines for Users</h2>
                    <p>We encourage our users to also adhere to fair use principles when using content from our site:</p>
                    <li className='pb-2'><strong>Non-Commercial Use:</strong>Ensure that any use of our content is for non-commercial, educational, or personal purposes.</li>
                    <li className='pb-2'><strong>Attribution:</strong>Always provide proper attribution when using our content, especially when sharing information related to the Rice Purity Test.</li>
                    <li className='pb-2'><strong>Respect for Copyright:</strong>Do not use substantial portions of any content that could affect the copyright holder's rights or replace the market for the original material.</li>
                    <h2>Reporting Infringement</h2>
                    <p>If you believe that your intellectual property has been infringed upon, or if you suspect any misuse of our content, please contact us at info@ricepuritytest.club. We take these claims seriously and are committed to addressing them promptly.</p>
                    <h2>Updates and Modifications</h2>
                    <p>We reserve the right to update or modify this Fair Use Policy at any time. Changes will be posted on this webpage, and we encourage users to review our policy periodically to stay informed of their rights and responsibilities.</p>
                    <h2>Educational Resources</h2>
                    <p>To help our users understand their rights and responsibilities under copyright law, we provide links to resources on intellectual property rights and fair use.</p>
                    <h2>Feedback and Contact</h2>
                    <p>Your feedback is important to us. If you have any questions, comments, or concerns about our Fair Use Policy, please do not hesitate to <a href='https://ricepuritytest.club/contact-us'>contact us</a>. We are here to help you effectively understand and navigate the rules surrounding fair use.</p>
                    <h2>Conclusion</h2>
                    <p>By using our website, you acknowledge that you have read, understood, and agreed to follow our Fair Use Policy. This policy ensures that both our rights and those of others, particularly in relation to intellectual property, are respected and upheld.</p>
                </div>

                </Container>
            </section>
            <Footer/>  
        </>
    )
}

export default FairUsePolicy
