import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';


const CookiesPolicy = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
        <Helmet>
        <title> Cookies Policy</title>
        <meta name="description" content=" We use cookies to enhance your browsing experience by personalizing
        content, analyzing our traffic, and providing social media features. This detailed Cookie
        Policy outlines the types of cookies we use, their purpose, and how you can manage them.
        " />
      </Helmet>
        <section className='pt-4 pb-4'>
            <Container>
            <div>
                <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Cookie Policy</span>
            </div>
            <h1>Cookie Policy</h1>
            <p>Welcome to the Rice Purity Test website. We use cookies to enhance your browsing experience by personalizing content, analyzing our traffic, and providing social media features. This detailed Cookie Policy outlines the types of cookies we use, their purpose, and how you can manage them.</p>
            <h2>Understanding Cookies</h2>
            <p>Cookies are small text files stored on your device (computer, smartphone, tablet) by websites you visit. They contain information that is used to improve your experience on the website by remembering your preferences and previous actions.</p>
            <h2>Types of Cookies We Use</h2>
            <li className='pb-2'><strong>Essential Cookies:</strong>These are necessary for our website to function correctly. They enable basic operations such as page navigation, access to secure areas of the website, and service requests. Because they are essential for using the site, these cookies do not require consent and cannot be switched off in our systems.</li>
            <li className='pb-2'><strong>Analytical Cookies:</strong>These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This data helps us improve the site's functionality by enabling us to monitor which pages are the most and least popular and see how visitors move around the site.</li>
            <li className='pb-2'><strong>Functionality Cookies:</strong>These cookies allow our website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features. They may also be used to provide services you have asked for, like watching a video or commenting on a blog.</li>
            <li className='pb-2'><strong>Advertising Cookies:</strong>These cookies are used to deliver adverts that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of the advertising campaigns. They are usually placed by advertising networks with the website operator's permission.</li>
            <h2>Your Choices Regarding Cookies</h2>
            <p>You have the right to decide whether to accept or reject cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
            <li className='pb-2'><strong>Managing Cookies:</strong>For detailed instructions on how to block or delete cookies, you can consult the help menu of your browser. Keep in mind that if you block all cookies, you may not be able to access certain features on our website.</li>
            <li className='pb-2'><strong>Effects of Disabling Cookies:</strong>If you choose to remove or reject cookies, this could affect the availability and functionality of our services. For example, rejecting essential cookies may prevent you from accessing certain parts of our website.</li>
            <h2>Third-Party Cookies</h2>
            <p>In addition to our own cookies, we may also use various third-party cookies to report service usage statistics, deliver advertisements on and through the service, and so on. Third-party companies like analytics companies and ad networks generally use cookies to collect user information anonymously. They may use that information to build a profile of your activities on our website and other websites that you've visited.</p>
            <h2>Updates to Our Cookie Policy</h2>
            <p>We may update this Cookie Policy to reflect changes to our cookie practices. If we make any significant changes, we will notify you by posting a notice on our website with a new effective date. Please periodically review this page for the latest information on our <a href='https://ricepuritytest.club/privacy-policy'>privacy practices</a>.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about our use of cookies or this Cookie Policy, please <a href='https://ricepuritytest.club/contact-us'>contact us</a> using the details provided on our website. Your feedback is important as we continue to improve our privacy practices and enhance your user experience.</p>
            <h2>Conclusion</h2>
            <p>Our commitment to your privacy is paramount, and understanding cookies is a part of safeguarding your data. By fully informing you about the types and purposes of cookies we use, we aim to enhance your browsing experience while respecting your privacy preferences.</p>
            </Container>
        </section>
        <Footer/>
        </>
    )
}

export default CookiesPolicy
