import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';


const DataCollectionPolicy = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
          <Helmet>
        <title> Collection Policy</title>
        <meta name="description" content=" This Data Collection Policy outlines our commitment to protecting your
privacy by detailing how we collect, use, and secure the personal information you provide
and the data we gather from your use of our services.
        " />
      </Helmet>
            <section className='pt-4 pb-4'>
                <Container>
                    <div>
                        <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Data Collection Policy</span>
                    </div>
                    <div>
                        <h1>Data Collection Policy</h1>
                        <p>Welcome to the Rice Purity Test. This Data Collection Policy outlines our commitment to protecting your privacy by detailing how we collect, use, and secure the personal information you provide and the data we gather from your use of our services.</p>
                        <h2>Types of Data Collected</h2>
                        <p><strong>Personal Information:</strong>When you interact with our website—be it through signing up for our newsletter, responding to surveys, or participating in forums—we may collect personal identifiers such as your name, email address, and contact details.</p>
                        <p><strong>Usage Data:</strong>Our systems automatically record data about your interaction with our website. This includes your IP address, browser type, device information, pages visited, and time spent on each page. We use cookies and similar technologies to facilitate this collection, aiming to enhance your user experience and personalize our services.</p>
                        <h2>Purpose of Data Collection</h2>
                        <p>The data we collect serves multiple purposes:</p>
                        <li className='pb-2'><strong>Service Provision:</strong>To operate and maintain the functionality of our website, ensuring a seamless user experience.</li>
                        <li className='pb-2'><strong>Personalization:</strong>To tailor content and offerings based on your preferences and previous interactions with our site.</li>
                        <li className='pb-2'><strong>Communication:</strong>To contact you with service updates, newsletters, and promotional offers if you have opted-in.</li>
                        <li className='pb-2'><strong>Analytics and Improvement:</strong>To analyze user behavior and preferences to optimize our website and develop new features.</li>
                        <h2>Data Sharing and Disclosure</h2>
                        <p>We respect your privacy and only share your data with third parties under strict conditions:</p>
                        <li className='pb-2'><strong>Service Providers:</strong>We engage third-party companies to assist in website management and data analysis. These providers are bound by confidentiality agreements to ensure the security of your data.</li>
                        <li className='pb-2'><strong>Legal Compliance:</strong>We may disclose your information if required by law or to protect our rights and safety or that of others.</li>
                        <li className='pb-2'><strong>Business Transfers:</strong>In the event of a merger or acquisition, your information may be transferred as part of the business assets.</li>
                        <h2>Data Security Measures</h2>
                        <p>We employ industry-standard security measures to protect your data from unauthorized access, alteration, and misuse. These include encryption, firewalls, and secure server facilities. Our security policies are regularly reviewed and updated to adapt to new threats.</p>
                        <h2>Your Rights and Choices</h2>
                        <p>You have significant control over your data:</p>
                        <li className='pb-2'><strong>Access and Update:</strong>You can view and update your personal information at any time through your user account.</li>
                        <li className='pb-2'><strong>Deletion:</strong>You can request the deletion of your data, subject to legal and operational retention requirements.</li>
                        <li className='pb-2'><strong>Opt-Out:</strong>You may opt out of marketing communications by using the unsubscribe link in our emails or contacting us directly.</li>
                        <h2>Children's Privacy</h2>
                        <p>Our services are not intended for children under 13. We do not knowingly collect data from this demographic. If we learn that we have inadvertently gathered personal information from a child under 13, we will delete that information promptly.</p>
                        <h2>Policy Updates</h2>
                        <p>This policy may be updated periodically to reflect changes in our practices or regulatory changes. We will notify you of significant changes through our website and update the policy's effective date accordingly.</p>
                        <h2>Contact Us</h2>
                        <p>If you have any questions about this policy or our data practices, please contact us at info@ricepuritytest.club. We are committed to maintaining open lines of communication regarding <a href='https://ricepuritytest.club/privacy-policy'>privacy</a> with our users.</p>
                    </div>
                </Container>
            </section>
            <Footer/>
        </>
    )
}

export default DataCollectionPolicy
