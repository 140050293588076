import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';


const Disclaimer = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
           <Helmet>
        <title> Disclaimer</title>
        <meta name="description" content="  Before you engage with any content or services, including the Rice Purity Test, you must understand and agree to this disclaimer. Our goal is to ensure that every user
is fully informed about the nature of our services and the limits of our responsibility
        " />
      </Helmet>
            <section className='pt-4 pb-4'>
                <Container>
                <div>
                    <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Disclaimer</span>
                </div>
                <div>
                    <h1>Disclaimer</h1>
                    <p>Welcome to the Rice Purity Test website. Before you engage with any content or services, including the Rice Purity Test, you must understand and agree to this disclaimer. Our goal is to ensure that every user is fully informed about the nature of our services and the limits of our responsibility.</p>
                    <h2>Purpose of the Rice Purity Test</h2>
                    <p>The Rice Purity Test offered on our website is designed strictly for entertainment and informational purposes. It is not a diagnostic tool and should not be used as such. The test is meant to provide a fun, engaging way to reflect on past experiences but is not intended to yield scientifically accurate assessments of your personality or behavior.</p>
                    <h2>Independence of Operation</h2>
                    <p>This website, rice-purity-test.com, is an independently operated online platform. It is not affiliated with Rice University or any other official organization traditionally associated with the Rice Purity Test. This independence means that our interpretations, content, and management of the test are developed internally and do not reflect any external official standards or endorsements.</p>
                    <h2>Voluntary Participation</h2>
                    <p>Participation in the Rice Purity Test is completely voluntary and at your discretion. By choosing to take the test, you acknowledge and accept personal responsibility for your engagement with the content and any interpretations or conclusions you draw from your results.</p>
                    <h2>Accuracy and Limitations</h2>
                    <p>While we strive for accuracy, the nature of the Rice Purity Test means that results can vary widely based on individual interpretation, input, and external factors such as cultural context or personal understanding of the questions. We cannot guarantee that the test results are definitive or free from errors.</p>
                    <h2>No Endorsement of External Entities</h2>
                    <p>The mention of any organizations, institutions, or entities on our website is purely for contextual and descriptive purposes. Such references should not be interpreted as an endorsement, sponsorship, or affiliation with our site.</p>
                    <h2>Privacy and Security Commitments</h2>
                    <p>We prioritize the privacy and security of our users. Any personal information collected through our website is handled in strict accordance with our Privacy Policy. We take substantial measures to protect this data from unauthorized access or misuse.</p>
                    <h2>Age Considerations</h2>
                    <p>You must be of legal age to consent to the terms of this disclaimer and to participate in the Rice Purity Test. If you are under the age of legal consent in your jurisdiction, please refrain from using our website.</p>
                    <h2>No Professional Advice</h2>
                    <p>The content provided on rice-purity-test.com, including the Rice Purity Test, blog posts, and other materials, is for informational purposes only. It is not a substitute for professional advice, and we strongly encourage users to consult qualified professionals for personal, legal, or health concerns.</p>
                    <h2>Legal Implications</h2>
                    <p>The Rice Purity Test and any related content are not to be used as evidence or in legal, medical, or any official capacity. Any reliance on the material provided here is strictly at your own risk.</p>
                    <h2>Updates to the Disclaimer</h2>
                    <p>We may update this disclaimer to reflect changes in our practices or legal requirements. We recommend that users regularly review this document to stay informed about their responsibilities and rights. Any significant changes will be clearly communicated on our website.</p>
                    <h2>Contact Information</h2>
                    <p>If you have any questions or concerns about this disclaimer or any other aspect of our services, please contact us directly at info@ricepuritytest.club. We are committed to providing a responsive and helpful service to address your inquiries.</p>
                    <h2>Conclusion</h2>
                    <p>By using our website and accessing the Rice Purity Test, you acknowledge that you have read and understood this disclaimer and agree to its <a href='https://ricepuritytest.club/terms-conditions'>terms</a>. If you do not agree with any part of this disclaimer, please do not use our website.</p>
                </div>
                </Container>
            </section>
            <Footer/>  
        </>
    )
}

export default Disclaimer
