import React from 'react'
import Footer from '../PurityMain/Footer'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import "./Footer.css"
import { Helmet } from 'react-helmet';


const PrivacyPolicy = () => {
    const navigate=useNavigate()
    const navHome=()=>{
        navigate('/')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <>
           <Helmet>
        <title>  Privacy Policy </title>
        <meta name="description" content=" Your privacy is of paramount importance to us. This Privacy Policy
provides detailed information on how your personal information is collected, used, and
protected when you interact with our website. By using our services, you consent to the
practices described in this document." />
      </Helmet>
        <section className='pt-4 pb-4'>
            <Container>
            <div>
                <span style={{cursor:"pointer"}} onClick={navHome}>Home<RiArrowRightDoubleFill/></span><span>Privacy Policy</span>
            </div>
            <div>
                <h1>Privacy Policy</h1>
                <p>Welcome to the Rice Purity Test website. Your privacy is of paramount importance to us. This Privacy Policy provides detailed information on how your personal information is collected, used, and protected when you interact with our website. By using our services, you consent to the practices described in this document.</p>
                <h2>Detailed Information Collection</h2>
                <p>When you visit the Rice Purity Test website, we collect a variety of information to enhance our services and your experience:</p>
                <li className='pb-2'><strong>Personal Identifiable Information (PII):</strong>This includes your name, email address, phone number, and other details that you voluntarily submit when signing up for newsletters, responding to surveys, or participating in promotions.</li>
                <li className='pb-2'><strong>Usage Information:</strong>We automatically collect data regarding your interactions with our website, such as your IP address, browser type, operating system, the pages you visit, and the times you access our site. This information is gathered through cookies and other similar tracking technologies to help us understand how our website is being used and to personalize your experience.</li>
                <h2>Purpose of Data Collection</h2>
                <p>The information we collect serves several purposes:</p>
                <li className='pb-2'><strong>Service Provision:</strong>To administer our website and provide the services you request.</li>
                <li className='pb-2'><strong>Personalization:</strong>To tailor content and resources according to your preferences.</li>
                <li className='pb-2'><strong>Communication:</strong>To respond to your inquiries, send you important service updates, and reach out if we have offers or information we think you might find interesting.</li>
                <li className='pb-2'><strong>Improvement and Analysis:</strong>To analyze trends and user behavior to improve the functionality and content of our website.</li>
                <li className='pb-2'><strong>Security:</strong>To prevent fraud, troubleshoot problems, and improve security.</li>
                <h2>Data Sharing and Disclosure</h2>
                <p>We value your privacy and do not sell your personal information to third parties. However, we share information with selected partners and third parties in the following scenarios:</p>
                <li className='pb-2'><strong>Service Providers:</strong>We engage other companies to perform services on our behalf, such as web hosting, data analysis, marketing assistance, and customer service. These service providers are bound by confidentiality agreements and are allowed to use your personal information only as necessary to perform their services.</li>
                <li className='pb-2'><strong>Legal Requirements:</strong>We may disclose your information if required by law, such as to comply with a subpoena or similar legal process when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
                <li className='pb-2'><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of that transaction.</li>
                <h2>Data Security</h2>
                <p>We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These include internal reviews of our data collection, storage, processing practices, and security measures, as well as physical security measures to guard against unauthorized access to systems where we store personal data.</p>
                <h2>Your Rights and Choices</h2>
                <p>You have significant control over your personal information:</p>
                <li className='pb-2'><strong>Access and Correction:</strong>You can review, correct, update, or delete inaccuracies to the information about you that we retain at any time by logging into your account and making the necessary changes.</li>
                <li className='pb-2'><strong>Opt-out:</strong>You may opt out of receiving promotional communications from us by using the unsubscribe link in each email or by contacting us directly.</li>
                <li className='pb-2'><strong>Cookies:</strong>If you wish to disable cookies, you can do so through your browser options. Be aware that disabling cookies may affect how our website functions for you.</li>
                <h2>Children's Privacy</h2>
                <p>We do not knowingly collect or solicit information from anyone under the age of 13. If you are under 13, please do not attempt to register for the services or send any personal information about yourself to us.</p>
                <h2>Changes to This Privacy Policy</h2>
                <p>We may update this policy from time to time to reflect changes to our information practices. We will notify you of any significant changes by posting the new policy on our website and updating the effective date at the top of this page.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions, concerns, or comments about our Privacy Policy or your data, please contact us using this email [<a href='mailto:info@ricepuritytest.club'>info@ricepuritytest.club</a>]. We are committed to resolving any issues concerning your privacy.</p>
                <h2>Conclusion</h2>
                <p>This revised privacy policy is designed to provide you with a clearer understanding of our data handling practices. We encourage you to read this policy carefully and <a href='https://ricepuritytest.club/contact-us'>contact us</a> with any questions or concerns about your personal data and privacy rights.</p>
            </div>
            </Container>
          <Footer/>
        </section>  
        </>
    )
}

export default PrivacyPolicy
